import { FC } from 'react';

export const ModuleDescriptionSkeleton: FC = () => {
  return (
    <div className="h-[18rem] mt-[2.4rem] animate-pulse border-b border-b-alto">
      <div className="flex items-center justify-between">
        <div className="md:flex hidden items-center">
          <div className="bg-catskillWhite rounded-[.8rem] h-[3rem] md:w-[15rem] lg:w-[23.2rem] xl:w-[33.2rem] mr-8"></div>
          <div className="bg-catskillWhite rounded-[.8rem] h-[3rem] md:w-[5rem] lg:w-[9.8rem] "></div>
        </div>
        <div className="bg-catskillWhite rounded-[.8rem] w-full h-[3rem] md:w-[19.6rem] "></div>
      </div>
      <div className="mt-[1.6rem] bg-catskillWhite rounded-[.8rem] h-[2rem] w-full"></div>
      <div className="mt-[.8rem] bg-catskillWhite rounded-[.8rem] h-[2rem] w-full"></div>
      <div className="mt-[.8rem] bg-catskillWhite rounded-[.8rem] h-[2rem] w-full"></div>
      <div className="mt-[.8rem] bg-catskillWhite rounded-[.8rem] h-[2rem] w-full"></div>
    </div>
  );
};

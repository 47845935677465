import { useLazyQuery } from '../../hooks/useQuery';
import { $ } from '../../zeus';
import { GET_SECTION_MATERIALS_FILE } from './constants';

type QuerySectionMaterialsFileVariables = {
  programModuleId?: string;
  sectionId?: string;
};

export const useQuerySectionMaterialsFile = () => {
  const { fetch, isLoading } = useLazyQuery(
    {
      getSectionMaterialsFile: [
        {
          programModuleId: $`programModuleId`,
          sectionId: $`sectionId`,
        },
        true,
      ],
    },
    { operationName: GET_SECTION_MATERIALS_FILE },
  );

  return {
    fetchMaterialsFile: (variables: QuerySectionMaterialsFileVariables) => fetch(variables),
    sectionMaterialsFileLoading: isLoading,
  };
};

import { ChallengeName } from 'amazon-cognito-identity-js';
import { DISABLE_AUTH } from '../../env';
import { ParticipantType } from '../../graphql/query/getMe';
import { AmplifyUserSession } from '../../libs/cognito.types';
import createReducer, { makeAction } from '../../utilities/createReducer';
import { AuthActions, AuthStateContextType, AuthStatus } from './types';

export type ActionLoading = {
  type: AuthActions.Loading;
};
export type ActionSetSession = {
  type: AuthActions.SetSession;
  payload?: AmplifyUserSession;
};

export type ActionSetChallenge = {
  type: AuthActions.SetChallenge;
  payload?: ChallengeName;
};

export type ActionSetUserData = {
  type: AuthActions.SetUserData;
  payload?: ParticipantType;
};

export type ActionLogout = {
  type: AuthActions.Logout;
};

export type Action =
  | ActionLoading
  | ActionSetSession
  | ActionLogout
  | ActionSetChallenge
  | ActionSetUserData;

export const initState: AuthStateContextType = {
  username: '',
  userData: {},
  isLoggedIn: DISABLE_AUTH ? true : false,
  authStatus: DISABLE_AUTH ? AuthStatus.SignedIn : AuthStatus.Init,
};

const reducer = createReducer<AuthStateContextType, Action, AuthActions>(initState, {
  [AuthActions.Loading]: () => ({ isLoading: true, authStatus: AuthStatus.Loading }),
  [AuthActions.Logout]: () => ({
    isLoading: false,
    sessionInfo: {},
    isLoggedIn: false,
    authStatus: AuthStatus.SignedOut,
  }),
  [AuthActions.SetChallenge]: (status) => ({
    sessionInfo: {},
    authStatus: AuthStatus.HasChallenge,
    isLoading: false,
    challenge: status,
  }),
  [AuthActions.SetUserData]: (userData: ParticipantType) => ({
    userData,
    username: `${userData?.firstName || ''} ${userData?.lastName || ''}`.trim() || userData.email,
  }),
  [AuthActions.SetSession]: (sessionInfo: AmplifyUserSession) => {
    return {
      sessionInfo,
      challenge: undefined,
      isLoggedIn: true,
      isLoading: false,
      authStatus: AuthStatus.SignedIn,
    };
  },
  //logout
  [AuthActions.Logout]: () => ({
    sessionInfo: undefined,
    challenge: undefined,
    isLoggedIn: false,
    isLoading: false,
    username: '',
    userData: {},
    authStatus: AuthStatus.SignedOut,
  }),
});

export function authContextReducer(state: AuthStateContextType, action: Action) {
  return reducer(state, action);
}

export const dispatchActions = {
  loading: makeAction<ActionLoading>(AuthActions.Loading),
  setSession: makeAction<ActionSetSession>(AuthActions.SetSession),
  setMe: makeAction<ActionSetUserData>(AuthActions.SetUserData),
  logout: makeAction<ActionLogout>(AuthActions.Logout),
  setUserData: makeAction<ActionSetUserData>(AuthActions.SetUserData),
  setChallenge: makeAction<ActionSetChallenge>(AuthActions.SetChallenge),
};

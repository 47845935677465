import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { ReactComponent as ChooseGroup } from '../../../assets/choose-group.svg';
import { ReactComponent as CreatePassword } from '../../../assets/create-password.svg';
import { ReactComponent as ThankYou } from '../../../assets/thank-you-join.svg';
import { ReactComponent as IllustrationJoin } from '../../../assets/IllustrationJoin.svg';
import { ReactComponent as Time } from '../../../assets/time.svg';
import { ReactComponent as Date } from '../../../assets/date.svg';
import { ReactComponent as Checkmark } from '../../../assets/checkmark.svg';
import { ReactComponent as Warning } from '../../../assets/warning.svg';
import Confetti from '../Confetti';
import clsx from 'clsx';
import Step2 from './Step2';
import Step3 from './Step3';
import { format, parseISO, parseJSON } from 'date-fns';
import { toast } from 'react-toastify';
import { ToastProps } from 'react-toastify/dist/types';

type MetaDataType = {
  url: string;
  startedAt: string;
};

type programGroupType = {
  id: string;
  startedAt: string;
};

type ProgramModuleSectionType = {
  type: string;
  title: string;
  description: string;
  metadata: MetaDataType;
};

type Cohort = {
  programGroup: programGroupType;
  section: ProgramModuleSectionType;
};

type SuccessProps = {
  step: string;
  changeSearchParam: (key: string, value?: string) => void;
  cohorts?: Cohort[];
  chosenCohort: string;
  setChosenCohort: Dispatch<SetStateAction<string>>;
  calendar: string;
  setCalendar: Dispatch<SetStateAction<string>>;
  solera: string;
};
interface MsgProps {
  closeToast?: () => void; // Optional because react-toastify might not provide it in some cases
  toastProps?: ToastProps;
}
const Msg: React.FC<MsgProps> = ({ closeToast, toastProps }) => (
  <div className="warningToastContainer flex items-start gap-[1rem]">
    <div className="w-[40px] h-[40px]">
      <Warning />
    </div>
    <div className="flex flex-col gap-[1rem]">
      <p className="font-ambit text-deepPurple font-bold text-1618">
        When choosing a group, remember
      </p>
      <p className="font-ambit text-deepPurple font-normal text-1421">
        The time for the intro call will be the same for all future live sessions in the program.
      </p>
    </div>
  </div>
);

const makeGoogleDateTime = (date: Date) => {
  return format(date, 'yyyyMMdd___HHmmss').replace('___', 'T');
};

const makeGoogleCalendarEvent = (data?: Cohort) => {
  if (!data) return '';
  const { description, title } = data.section;
  const { startedAt, url: zoom } = data?.section.metadata;
  if (!startedAt) return '';

  const end = parseJSON(startedAt);
  end.setHours(end.getHours() + 1);
  const url = new URL('https://calendar.google.com/calendar/r/eventedit');
  url.searchParams.append('text', title);
  url.searchParams.append('details', description || '');
  url.searchParams.append('location', zoom || 'zoom');
  url.searchParams.append(
    'dates',
    `${makeGoogleDateTime(parseJSON(startedAt))}/${makeGoogleDateTime(end)}`,
  );
  return url.toString();
};

const makeWeekDay = (dateString: string) => {
  const formattedValue =
    dateString.endsWith('Z') || dateString.includes('+') ? dateString : dateString + 'Z';

  const date = parseISO(formattedValue);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const timeZoneAbbreviation = new Intl.DateTimeFormat('en-US', {
    timeZone: timeZone,
    timeZoneName: 'short',
  })
    .format(date)
    .split(' ')[1];

  const formattedDate = Intl.DateTimeFormat('en-US', {
    timeZone: timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(date);

  const weekDay = Intl.DateTimeFormat('en-US', {
    timeZone: timeZone,
    weekday: 'short',
  }).format(date);

  const time = Intl.DateTimeFormat('en-US', {
    timeZone: timeZone,
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }).format(date);

  return {
    formattedDate,
    weekDay,
    time,
    timeZoneAbbreviation,
  };
};

const Success: FC<SuccessProps> = ({
  step,
  cohorts,
  changeSearchParam,
  chosenCohort,
  setChosenCohort,
  calendar,
  setCalendar,
  solera,
}) => {
  const [title, setTitle] = useState('1');
  const [text, setText] = useState('');
  const [click, setClick] = useState(1);
  const choseHandler = (
    cohort: Cohort,
    weekDay: string,
    formattedDate: string,
    time: string,
    formattedDateGroup: string,
    weekDayGroup: string,
    timeZoneAbbreviation: string,
  ) => {
    if (1 >= click) {
      toast(<Msg />, {
        autoClose: 3000,
      });
    }
    setClick(click + 1);
    setChosenCohort(cohort?.programGroup?.id || '');
    setCalendar(makeGoogleCalendarEvent(cohort));
    localStorage.setItem('formattedDate', formattedDate);
    localStorage.setItem('weekDay', weekDay);
    localStorage.setItem('time', time);
    localStorage.setItem('formattedDateGroup', formattedDateGroup);
    localStorage.setItem('weekDayGroup', weekDayGroup);
    localStorage.setItem('timeZoneAbbreviation', timeZoneAbbreviation);
  };

  const Cohorts = () => {
    return (
      <>
        {cohorts?.map((cohort, i) => {
          const { formattedDate, weekDay, time, timeZoneAbbreviation } = makeWeekDay(
            cohort.section.metadata.startedAt,
          );
          const { formattedDate: formattedDateGroup, weekDay: weekDayGroup } = makeWeekDay(
            cohort.programGroup.startedAt,
          );
          return (
            <div
              className="card flex flex-col sm:flex-row gap-[2rem] w-full bg-lightBlue py-[16px] px-[20px] items-start sm:items-center rounded-[12px] cursor-pointer hover:bg-primaryLightBlue"
              key={cohort?.programGroup.id}
              onClick={() =>
                choseHandler(
                  cohort,
                  formattedDate,
                  weekDay,
                  time,
                  formattedDateGroup,
                  weekDayGroup,
                  timeZoneAbbreviation,
                )
              }
            >
              <div className="flex flex-row-reverse	sm:flex-row	items-center justify-between w-full sm:w-auto font-ambit font-bold text-deepPurple text-1416 sm:text-1618 gap-[2rem] mr-auto sm:mr-[6rem] lg:mr-[17rem]">
                {chosenCohort === cohort?.programGroup?.id ? (
                  <Checkmark />
                ) : (
                  <div className="w-[1.8rem] h-[1.8rem]"></div>
                )}
                Group&nbsp;{++i}
              </div>
              <div className="font-ambit font-bold text-deepPurple text-1416 sm:text-1214 sm:ml-[20px]">
                <div className="font-ambit">PROGRAM STARTS</div>
                <div className="flex items-center font-normal text-1618 sm:text-1315 mt-[6px]">
                  <div className="flex items-center mr-[20px]">
                    <Date className="mr-[6px]" />
                    <div className="font-ambit">
                      {formattedDateGroup}, {weekDayGroup}
                    </div>
                  </div>
                </div>
              </div>
              <div className="font-ambit font-bold text-deepPurple text-1416 sm:text-1214 sm:mr-[20px]">
                <div className="font-ambit">INTRO CALL</div>
                <div className="flex items-center font-normal text-1618 sm:text-1315 mt-[6px]">
                  <div className="flex items-center mr-[20px]">
                    <Date className="mr-[6px]" />
                    <div className="font-ambit">
                      {formattedDate},&nbsp;{weekDay}
                    </div>
                  </div>
                  <Time className="ml-[-2px]" />
                  <div className="font-ambit ml-1 uppercase">
                    {time}&nbsp;({timeZoneAbbreviation})
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <button
          className={clsx(
            'bg-deepPurple900 text-1618 rounded-[2rem] my-[2rem] py-[1rem] w-[32rem]',
            {
              'text-secondaryGray100': '' === chosenCohort,
              'text-white': '' !== chosenCohort,
              'bg-secondaryGray': '' === chosenCohort,
              'hover:bg-independence': '' !== chosenCohort,
            },
          )}
          disabled={'' === chosenCohort}
          onClick={() => changeSearchParam('step', '2')}
        >
          Next
        </button>
      </>
    );
  };

  useEffect(() => {
    switch (step) {
      case '2':
        setTitle('Create a password');
        setText('Must contain at least 8 characters, 1 symbol or number and 1 uppercase letter.');
        break;
      case '3':
        setTitle('Thank you for joining Ciba Health!');
        setText(
          'Save the date to not miss your introduction call. In the meantime, you can explore the dashboard. See you soon!',
        );
        break;
      default:
        setTitle('Choose a group');
        setText(
          'Choose the program start date that suits you best. The time indicates when the introduction call will happen.',
        );
    }
  }, [step]);

  return (
    <div className="flex flex-col m-h-full justify-between items-center pt-[3rem]">
      {/* Header */}
      {'3' === step ? <IllustrationJoin /> : <></>}
      <div className="font-ambit font-bold text-2023 text-center text-deepPurple">{title}</div>
      <div className="font-ambit font-semibold text-1624 text-deepPurple text-center text-balance opacity-50 mt-[1rem] max-w-[73rem]">
        {text}
      </div>
      {/* /Header */}

      <div className="flex flex-col gap-[16px] my-[2rem] w-full items-center">
        {'1' === step ? (
          <Cohorts />
        ) : '2' === step ? (
          <Step2
            changeSearchParam={changeSearchParam}
            solera={solera}
            chosenCohort={chosenCohort}
          />
        ) : (
          <Step3 changeSearchParam={changeSearchParam} calendar={calendar} />
        )}
      </div>

      {/* Footer */}
      <div className="flex flex-col sm:flex-row self-start sm:self-center gap-[4rem]">
        <div className="flex sm:flex-col sm:items-center gap-[1rem]">
          <ChooseGroup />
          <div className="flex flex-col sm:items-center">
            <div className="font-ambit font-semibold text-1618 text-deepPurple">Choose a group</div>
            <div className="font-ambit font-semibold text-1416 text-deepPurple opacity-50">
              Select a date and time slot.
            </div>
          </div>
        </div>
        <div className="flex sm:flex-col sm:items-center gap-[1rem]">
          <CreatePassword className={step >= '2' ? '' : 'disabled'} />
          <div className="flex flex-col sm:items-center">
            <div className="font-ambit font-semibold text-1618 text-deepPurple">
              Create a password
            </div>
            <div className="font-ambit font-semibold text-1416 text-deepPurple opacity-50">
              Password for your account.
            </div>
          </div>
        </div>
        <div className="flex sm:flex-col sm:items-center gap-[1rem]">
          <ThankYou className={step >= '3' ? '' : 'disabled full'} />
          <div className="flex flex-col sm:items-center">
            <div className="font-ambit font-semibold text-1618 text-deepPurple">
              Thank you for joining!
            </div>
            <div className="font-ambit font-semibold text-1416 text-deepPurple opacity-50">
              Improve your health with us.
            </div>
          </div>
        </div>
      </div>
      {'1' === step ? <Confetti /> : <></>}
      {/* /Footer */}
    </div>
  );
};
export default Success;

import { FC } from 'react';

export const CurriculumSectionSkeleton: FC = () => {
  return (
    <div className="h-[18rem] mt-[2.4rem] animate-pulse">
      <div className="flex items-center justify-between">
        <div className="flex items-center w-full">
          <div className="h-[4.8rem] w-[4.8rem] bg-catskillWhite rounded-[.8rem] mr-8"></div>
          <div className="bg-catskillWhite rounded-[.8rem] w-full h-[2.4rem] md:w-[39rem] "></div>
        </div>
      </div>
      <div className="mt-[1.6rem] bg-catskillWhite rounded-[.8rem] h-[2rem] w-full"></div>
      <div className="mt-[.8rem] bg-catskillWhite rounded-[.8rem] h-[2rem] w-full"></div>
      <div className="flex justify-end mt-[1.1rem]">
        <div className="bg-catskillWhite rounded-[.8rem] w-[21.1rem] h-[4.7rem]"></div>
      </div>
    </div>
  );
};

import { FC } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

type ButtonProps = {
  onClick?: () => void;
  href?: string;
  disabled?: boolean;
  to?: string;
  type?: 'submit';
  filename?: string;
  bold?: boolean;
  outlined?: boolean;
  width?: string;
  height?: string;
  icon?: JSX.Element;
  align?: 'justify-left' | 'justify-right' | 'justify-center';
};

export const Button: FC<ButtonProps> = ({
  children,
  onClick,
  width,
  height,
  to,
  href,
  type,
  disabled,
  filename,
  outlined,
  bold,
  align = 'justify-center',
  icon,
}) => {
  const mainProps = {
    onClick,
    style: {
      width,
      height,
      borderRadius: '2.4rem',
      border: outlined ? '1px solid #16123F' : 'none',
      background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 1e-05) 100%), #16123F',
      backgroundBlendMode: 'overlay, normal',
      boxShadow:
        '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
    },
    className: clsx('rounded-[2.4rem] flex items-center', align, {
      'font-bold': bold,
      'bg-main text-white': !outlined,
      'bg-white text-main hover:text-white': outlined,
      'w-full': !width,
      'opacity-50': disabled,
      'cursor-pointer hover:bg-independence': !disabled,
      'h-[4.2rem]': !height,
    }),
  };
  if (href) {
    return (
      <a href={href} download={filename} target="_blank" {...mainProps}>
        {icon}
        <div className="text-1324">{children}</div>
      </a>
    );
  }
  if (to) {
    return (
      <Link to={to} {...mainProps}>
        {icon}
        <div className="text-1324">{children}</div>
      </Link>
    );
  }

  return (
    <button {...mainProps} disabled={disabled} type={type}>
      {icon}
      <div className="text-1324">{children}</div>
    </button>
  );
};

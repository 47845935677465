import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Error from './Error/Error';
import { SoleraLayout } from '../../components/SoleraLayout/SoleraLayout';
import * as H from 'history';
import './styles.scss';
import Success from './Succeess/Success';
import { API_BASE_URL } from '../../env';

const makeChangeSearchParam = (history: H.History) => (key: string, value?: string) => {
  const searchParams = new URLSearchParams(location.search);
  if (value) {
    searchParams.set(key, value);
  } else {
    searchParams.delete(key);
  }

  history.replace({
    search: searchParams.toString(),
  });
};

/*const cohorts = [
  {
    programGroup: {
      id: '487fb318-0acd-4461-8986-6d0f2b977b41',
      name: 'HWM long 2024-09-09',
      startedAt: '2024-04-22T23:00:00',
    },
    section: {
      type: 'coaching_call',
      title: 'Intro Meeting to review program',
      description:
        'I’ll meet you to discuss your goals, motivation and describe main principles of the program. See you there!',
      metadata: {
        startedAt: '2024-04-22T23:00:00+00:00',
        url: '',
      },
    },
  },
  {
    programGroup: {
      id: 'feb8a3b6-ceab-4cdd-9553-5e804d98b546',
      name: 'NDPP long 2024-09-09',
      startedAt: '2024-09-09T00:00:00+00:00',
    },
    section: {
      type: 'coaching_call',
      title: 'Intro Meeting to review program',
      description:
        'I’ll meet you to discuss your goals, motivation and describe main principles of the program. See you there!',
      metadata: {
        startedAt: '2024-09-09T00:00:00+00:00',
        url: '',
      },
    },
  },
  {
    programGroup: {
      id: '88ee169e-2a9c-401e-a59f-0c39a02a9df2',
      name: 'IBC long 2024-09-09',
      startedAt: '2024-09-09T00:00:00+00:00',
    },
    section: {
      type: 'coaching_call',
      title: 'Intro Meeting to review program',
      description:
        'I’ll meet you to discuss your goals, motivation and describe main principles of the program. See you there!',
      metadata: {
        startedAt: '2024-09-09T00:00:00+00:00',
        url: 'http://localhost',
      },
    },
  },
  {
    programGroup: {
      id: '1777fd22-ae0c-446e-8376-6884e3f49a46',
      name: '2024-04-25 IBC ',
      startedAt: '2024-04-25T00:00:00+00:00',
    },
    section: {
      type: 'coaching_call',
      title: 'Intro Meeting to review program',
      description:
        'I’ll meet you to discuss your goals, motivation and describe main principles of the program. See you there!',
      metadata: {
        startedAt: '2024-04-25T00:00:00+00:00',
        url: '',
      },
    },
  },
  {
    programGroup: {
      id: 'fec7ce55-076f-43e8-8e7f-4a4aab7ec3d7',
      name: '[IBC] QACohort 2025-01-31',
      startedAt: '2025-01-31T00:00:00+00:00',
    },
    section: {
      type: 'coaching_call',
      title: 'Intro Meeting to review program',
      description:
        'I’ll meet you to discuss your goals, motivation and describe main principles of the program. See you there!',
      metadata: {
        startedAt: '2025-01-31T00:00:00',
        url: '',
      },
    },
  },
];*/

interface LandParticipantInput {
  lookUpKey: string;
}
const InviteSolera: FC = () => {
  const history = useHistory();
  const location = useLocation();

  async function performLandParticipantOperation(input: LandParticipantInput) {
    try {
      const url = `${API_BASE_URL}` + 'graphql';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `mutation LAND_PARTICIPANT($lookUpKey: String!) {
            landParticipant(lookUpKey: $lookUpKey) {
                 ... on CohortsData {
                    cohorts {
                        programGroup {
                          id
                          name
                          startedAt
                        }
                        section {
                          type
                          title
                          description
                          metadata {
                          ... on CoachingCallType {
                              startedAt
                              url
                            }
                          }
                        }
                    }
                }
                ... on ParticipantDneError {
                    message
                }
                ... on ParticipantDuplicatedError {
                    message
                }
                ... on NoCompanyData {
                    message
                }
                ... on RegistrationFailed {
                    message
                }
            }
          }
        `,
          variables: input,
        }),
      }).then((response) => response.json());
      if (response.data.landParticipant.message) {
        //Participant was already signed up. -> setPassword
        setLoading(false);
        setSuccess(false);
        setError(response.data.landParticipant.message);
      } else {
        setLoading(false);
        setSuccess(true);
        setCohorts(response.data.landParticipant.cohorts);
      }
      return response;
    } catch (error) {
      setLoading(false);
      setSuccess(false);
      console.error('Error performing land participant operation:', error);
      return { error: 'Failed to perform land participant operation', details: error };
    }
  }

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [step, stepStep] = useState('1');
  const [success, setSuccess] = useState(true);
  const [cohorts, setCohorts] = useState([]);
  const [chosenCohort, setChosenCohort] = useState('');
  const [solera, setSolera] = useState('');
  const [calendar, setCalendar] = useState('');
  const changeSearchParam = makeChangeSearchParam(history);

  const allParams: Record<string, string> = {};
  const queryParams = new URLSearchParams(location.search);

  queryParams.forEach((value, key) => {
    const keyLower = key.toLowerCase();
    // Replace spaces with '+' if necessary. Be cautious with this approach.
    const correctedValue = value.replace(/ /g, '+');
    allParams[keyLower] = correctedValue;
  });

  const lookupKey = allParams['lookupkey'];

  useEffect(() => {
    performLandParticipantOperation({ lookUpKey: lookupKey });
  }, []);

  useEffect(() => {
    setSolera(lookupKey);
    if (undefined === allParams['step']) {
      changeSearchParam('step', '1');
    }
    stepStep(allParams['step']);
  }, [location.search]);

  return (
    <SoleraLayout>
      {loading ? (
        <div className="loader"></div>
      ) : success ? (
        <Success
          step={step}
          cohorts={cohorts}
          changeSearchParam={changeSearchParam}
          chosenCohort={chosenCohort}
          setChosenCohort={setChosenCohort}
          calendar={calendar}
          setCalendar={setCalendar}
          solera={solera}
        />
      ) : (
        <Error error={error} />
      )}
    </SoleraLayout>
  );
};

export default InviteSolera;

import clsx from 'clsx';
import { FC, useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { emailRegExp } from '../../utilities/validationRegexp';
import { Spinner } from '../Spinner/Spinner';
import { InputField } from './../../components/InputField/InputField';
import { routesPath } from '../../constants/routes';

export type LoginFormProps = {
  onSubmit: (data: FieldValues) => void;
  isLoading: boolean;
  error?: string;
};

export const LoginForm: FC<LoginFormProps> = ({ onSubmit, isLoading, error }) => {
  const { t } = useTranslation();
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm();

  useLayoutEffect(() => {
    document.title = 'Log In Ciba Participant Portal';
  }, []);

  const [serverError, setServerError] = useState<string | undefined>(error);

  const emailValue = watch('email');

  const email = register('email', {
    onBlur() {
      setValue('email', emailValue?.trim());
    },
    validate: (value) =>
      emailRegExp.test(value) || (t('Please enter valid Email address') as string),
  });
  const password = register('password');
  const disabled = isLoading || !isDirty;

  // useEffect(() => {
  //   setServerError(error);
  // }, [error]);

  function getSharedCookie(name: string) {
    const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
    const targetCookie = cookies.find((cookie) => cookie.startsWith(name + '='));
    if (targetCookie) {
      const encodedData = targetCookie.split('=')[1];
      const decodedData = atob(encodedData); // Decode data from Base64
      return JSON.parse(decodedData);
    }
    return null;
  }

  useEffect(() => {
    try {
      const storedEmail = localStorage.getItem('username');
      if (storedEmail) {
        // If an email was found, set it as the default value for the email input field
        setValue('email', storedEmail, { shouldDirty: true });
      }
    } catch (error) {}

    const queryParams = new URLSearchParams(window.location.search);
    const redirected = queryParams.get('redirect'); // Adjust based on your actual GET parameter
    const login = getSharedCookie('login');
    const pass = getSharedCookie('pass');
    if (login) {
      setValue('email', login, { shouldValidate: false });
      setValue('password', pass, { shouldValidate: false });
      handleSubmit(onSubmit)(); // Programmatically submit the form
    }
  }, []);

  return (
    <div className="">
      <h1 className="text-main font-heptaSlab text-2030 font-bold mb-[6rem] text-center">Log in</h1>
      <form noValidate onSubmit={handleSubmit(onSubmit)} className="">
        <InputField
          classNames={{
            container: 'mb-[1.5rem]',
            input: 'rounded-[3.2rem] text-1324 p-[1.2rem] placeholder:text-lavenderGray',
          }}
          placeholder={t('Email') as string}
          type="email"
          disabled={isLoading}
          error={errors.email?.message as string}
          {...email}
          onChange={(e) => {
            email.onChange(e);
            setServerError(undefined);
          }}
        />
        <InputField
          classNames={{
            container: 'mb-[1.5rem]',
            input: 'rounded-[3.2rem] text-1324 p-[1.2rem] placeholder:text-lavenderGray',
          }}
          disabled={isLoading}
          placeholder={t('password') as string}
          type="password"
          error={errors.password?.message as string}
          {...password}
          onChange={(e) => {
            password.onChange(e);
            setServerError(undefined);
          }}
        />
        <div className="text-center mb-[1.5rem]">
          <Link
            to={routesPath.resetPassword}
            className="underline font-ambit text-independence font-bold text-1423"
          >
            Forgot Password?
          </Link>
        </div>

        <button
          type="submit"
          disabled={disabled}
          className={clsx(
            'bg-main text-white w-full h-[4.8rem] rounded-[2.4rem] text-1313 font-ambit mb-[1rem] uppercase tracking-[3px]',
            {
              'opacity-50': disabled,
            },
          )}
        >
          {isLoading ? <Spinner /> : t('log-in')}
        </button>
        {serverError && <div className="text-error">{serverError}</div>}
      </form>
    </div>
  );
};

import { createContext, FC, Reducer, useContext, useEffect, useMemo, useReducer } from 'react';
import { Cognito } from '../../libs/cognito';

import { AuthActionsContextType, AuthStateContextType } from './types';
import { Action, dispatchActions, initState, authContextReducer } from './reducer';
import { DISABLE_AUTH } from '../../env';
import { saveSession, removeSession } from '../../utilities/saveSession';
import { queryClient } from '../../graphql/client';
import { AmplifyUserSession } from '../../libs/cognito.types';
import { fakeSession } from '../../libs/cognitoFakeSession';

const AuthStateContext = createContext<AuthStateContextType | undefined>(undefined);
const AuthActionsContext = createContext<AuthActionsContextType | undefined>(undefined);

AuthStateContext.displayName = 'AuthStateContext';

export const AuthProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<AuthStateContextType, Action>>(
    authContextReducer,
    initState,
  );
  const cognito = Cognito.getInstance();

  useEffect(() => {
    if (localStorage.getItem('idToken')) {
      getSessionInfo();
    } else {
      dispatch(dispatchActions.logout());
    }
  }, []);

  const getSessionInfo = async () => {
    try {
      dispatch(dispatchActions.loading());

      let sessionInfo: AmplifyUserSession;

      if (DISABLE_AUTH) {
        sessionInfo = fakeSession;
      } else if (DISABLE_AUTH) {
        // set up fake session if auth disabled
        sessionInfo = fakeSession;
      } else {
        // Fetch session if not expired and not disabled
        const session = await getSession();
        saveSession(session);
        sessionInfo = session;
      }
      dispatch(dispatchActions.setSession(sessionInfo));
    } catch (err) {
      signOut();
    }
  };

  const signInWithEmail = async (username: string, password: string) => {
    try {
      dispatch(dispatchActions.loading());
      const result = await cognito.signInWithEmail(username, password);

      if (result.challengeName) {
        await dispatch(dispatchActions.setChallenge(result.challengeName));
        return result;
      }
      const session = await cognito.getSession();

      saveSession(session);
      dispatch(dispatchActions.setSession(session));

      return result;
    } catch (err) {
      dispatch(dispatchActions.logout());
      throw err;
    }
  };

  function signOut() {
    cognito.signOut();
    queryClient.removeQueries();
    removeSession();
    dispatch(dispatchActions.logout());
  }

  const completeNewPassword = async (password: string) => {
    dispatch(dispatchActions.loading());

    try {
      const session = await cognito.completeNewPassword(password);
      saveSession(session);
      dispatch(dispatchActions.setSession(session));

      return session;
    } catch (err) {
      dispatch(dispatchActions.logout());
      throw err;
    }
  };

  const setMe = (me: any) => {
    dispatch(dispatchActions.setMe(me));
  };

  const verifyCode = (username: string, code: string) => cognito.verifyOTPCode(code);

  const getSession = () => cognito.getSession();

  const sendForgotPasswordCode = (username: string) =>
    cognito.sendForgotPasswordCode(username.toLowerCase());

  const forgotPassword = (username: string, code: string, password: string) =>
    cognito.forgotPassword(username.toLowerCase(), code, password);

  const changePassword = (oldPassword: string, newPassword: string) =>
    cognito.changePassword(oldPassword, newPassword);

  const actions = useMemo(
    () => ({
      signInWithEmail,
      verifyCode,
      getSession,
      sendForgotPasswordCode,
      setMe,
      completeNewPassword,
      forgotPassword,
      changePassword,
      signOut,
      getSessionInfo,
    }),
    [],
  );
  return (
    <AuthStateContext.Provider value={state}>
      <AuthActionsContext.Provider value={actions}>{children}</AuthActionsContext.Provider>
    </AuthStateContext.Provider>
  );
};

export function useAuthState() {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  return context;
}

export function useAuthActions() {
  const context = useContext(AuthActionsContext);
  if (context === undefined) {
    throw new Error('useAuthActions must be used within a AuthProvider');
  }
  return context;
}

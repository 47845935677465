import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Collapse } from '../Collapse/Collapse';
import { useTranslation } from 'react-i18next';
import { ChatParticipantsItem } from '../ChatParticipantsItem/ChatParticipantsItem';
import { ProviderDataType } from '../../graphql/query/getCareTeam';
import { User } from '@twilio/conversations';
import { ChatParticipantsListSkeleton } from './ChatParticipantsList.skeleton';
import { useWaitForData } from '../../utilities/useWaitForData';

type ChatParticipantsListProps = {
  isLoading?: boolean;
  careTeam: (ProviderDataType & { chatUser: Promise<User> | undefined; user?: User })[];
};

export const ChatParticipantsList: FC<ChatParticipantsListProps> = ({
  careTeam,
  isLoading: loading,
}) => {
  const isLoading = useWaitForData([careTeam.length], loading);
  const [users, setUsers] = useState(careTeam);
  useEffect(() => {
    const usersPromises = careTeam.map((item) =>
      item?.chatUser?.then((u) => ({ ...item, user: u })),
    );
    Promise.all(usersPromises).then((u) => {
      setUsers(u as any);
    });
  }, [careTeam]);
  const { t } = useTranslation();

  if (isLoading) {
    return <ChatParticipantsListSkeleton />;
  }
  return (
    <div data-testid="ChatParticipantsList" className={clsx('')}>
      <Collapse
        isOpen
        header={
          <div className="flex items-center cursor-pointer py-8">
            <div className="text-1822 text-outerSpace mr-4">{t('Care Team')}</div>
            <div className="text-1214 text-manatee">
              {careTeam?.length > 0 ? `(${careTeam?.length})` : ''}
            </div>
          </div>
        }
      >
        <div className="min-h-[32rem] overflow-y-auto">
          {!isLoading && !users?.length && (
            <div className="text-manatee">Care Team participants are not found</div>
          )}
          {users?.map((u) => (
            <ChatParticipantsItem
              key={u.id}
              username={u.fullName}
              role="Provider"
              online={!!u.user?.isOnline}
            />
          ))}
          {/* // <ChatParticipantsItem username="John Liberman" role="Provider" online /> */}
        </div>
        <div className="h-px w-full bg-main opacity-10"></div>
      </Collapse>
    </div>
  );
};

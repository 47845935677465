import React, { FC, useEffect, useState } from 'react';
import { InputField } from '../../../components/InputField/InputField';
import clsx from 'clsx';
import { FieldValues, useForm } from 'react-hook-form';
import { validateNewPassword } from '../../../utilities/validateNewPassword';
import EyeCrossed from '../../../assets/eye-crossed.svg';
import Eye from '../../../assets/eye.svg';
import { styled } from 'react-query/types/devtools/utils';
import { API_BASE_URL } from '../../../env';

interface SendPassword {
  newPassword: string;
  cohortId: string;
}

type Step2Props = {
  changeSearchParam: (key: string, value?: string) => void;
  solera: string;
  chosenCohort: string;
};
const Step2: FC<Step2Props> = ({ changeSearchParam, solera, chosenCohort }) => {
  const [pass, setPass] = useState('dd');
  const [type1, setType1] = useState('password');
  const [type2, setType2] = useState('password');
  const [serverError, setServerError] = useState<string | undefined>(undefined);
  async function sendPassword(input: SendPassword) {
    try {
      const url = `${API_BASE_URL}` + 'graphql';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer solera ${solera}`,
        },
        body: JSON.stringify({
          query: `mutation SetNewPassword($newPassword: String!, $cohortId: String!) {
              setNewPassword(
                  newPassword: $newPassword
                  cohortId: $cohortId
              ){
                  email
              }
          }
        `,
          variables: input,
        }),
      }).then((response) => response.json());
      if (response.data.errors) {
        changeSearchParam('step', '3');
      }
      localStorage.setItem('username', response.data.setNewPassword.email);
      changeSearchParam('step', '3');
      return response;
    } catch (error) {
      // Handle or log the error as needed
      console.error('Error performing land participant operation:', error);
      return { error: 'Failed to perform land participant operation', details: error };
    }
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm();

  const password = register('password', {
    validate: validateNewPassword,
  });
  const confirmPassword = register('confirmPassword', {
    validate: (value) => value === passwordValue || 'Passwords do not match',
  });
  const handleShowPass = () => {
    if ('password' === type1) {
      setType1('text');
    } else {
      setType1('password');
    }
  };
  const handleShowPass2 = () => {
    if ('password' === type2) {
      setType2('text');
    } else {
      setType2('password');
    }
  };
  const passwordValue = watch('password');

  const handleSubmitForm = ({ password }: FieldValues) => {
    sendPassword({ newPassword: password, cohortId: chosenCohort });
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitForm)} className="w-full flex flex-col items-center">
        <div className="relative  w-full sm:w-[32rem]">
          <InputField
            label={'New Password'}
            classNames={{
              label: 'text-ambit text-secondaryGray700 opacity-100 normal-case',
              container: 'mb-[1.5rem]',
              input: 'rounded-lg text-1324 p-[1.2rem] placeholder:text-lavenderGray',
            }}
            type={type1}
            {...password}
            error={errors.password?.message as string}
            onChange={(e) => {
              password.onChange(e);
              setServerError(undefined);
            }}
          />
          <img
            src={'password' === type1 ? EyeCrossed : Eye}
            alt=""
            className="absolute right-[2rem] top-[4.2rem] cursor-pointer opacity-40"
            onClick={handleShowPass}
          />
        </div>
        <div className="relative  w-full sm:w-[32rem]">
          <InputField
            label={'Confirm Password'}
            classNames={{
              label: 'text-ambit text-secondaryGray700 opacity-100 normal-case',
              container: 'mb-[1.5rem]',
              input: 'rounded-lg text-1324 p-[1.2rem] placeholder:text-lavenderGray',
            }}
            type={type2}
            error={errors.confirmPassword?.message as string}
            {...confirmPassword}
            onChange={(e) => {
              confirmPassword.onChange(e);
              setServerError(undefined);
            }}
          />
          <img
            src={'password' === type2 ? EyeCrossed : Eye}
            alt=""
            className="absolute right-[2rem] top-[4.2rem] cursor-pointer opacity-40"
            onClick={handleShowPass2}
          />
        </div>
        {serverError && <div className="text-error">{serverError}</div>}
        <button
          type={'submit'}
          className={clsx(
            'bg-deepPurple900 text-1618 rounded-[2rem] mt-[2rem] mb-[5rem] py-[1rem] w-full sm:w-[32rem]',
            {
              'text-secondaryGray100': '' === pass,
              'text-white': '' !== pass,
              'bg-secondaryGray': '' === pass,
              'hover:bg-independence': '' !== pass,
            },
            {
              'opacity-50': !isDirty,
            },
          )}
          disabled={!isDirty}
          /*onClick={() => changeSearchParam('step', '3')}*/
        >
          Next
        </button>
      </form>
    </>
  );
};
export default Step2;

import { useQuery } from '../../hooks/useQuery';
import { GET_COMPANIES } from './constants';
import { $ } from '../../zeus';

type QueryCompaniesvariables = {
  search?: string;
  sort?: string;
};

export const useQueryCompanies = (variables?: QueryCompaniesvariables) => {
  const { isFetching, data } = useQuery(
    [GET_COMPANIES],
    {
      getCompanies: [
        {
          search: $`search`,
          sort: $`sort`,
        },
        {
          __typename: true,
          id: true,
          name: true,
        },
      ],
    },
    {
      operationName: GET_COMPANIES,
      variables,
    },
    {},
  );

  return { isLoading: isFetching, companies: data?.getCompanies || [] };
};

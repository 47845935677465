import { FC } from 'react';
import clsx from 'clsx';
import './HealthPillar.scss';
import Exercise from './images/exercise.svg';
import HealthyEating from './images/healthy-eating.svg';
import Relax from './images/relax.svg';
import GoodSleep from './images/good-sleep.svg';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'antd';

type HealthPillarProps = {
  isLoading?: boolean;
};

const slides = [
  {
    title: 'health-pillar--exercise--title',
    description: 'health-pillar--exercise--description',
    image: Exercise,
  },
  {
    title: 'health-pillar--healthy-eating--title',
    description: 'health-pillar--healthy-eating--description',
    image: HealthyEating,
  },
  {
    title: 'health-pillar--relax--title',
    description: 'health-pillar--relax--description',
    image: Relax,
  },
  {
    title: 'health-pillar--good-sleep--title',
    description: 'health-pillar--good-sleep--description',
    image: GoodSleep,
  },
];

export const HealthPillar: FC<HealthPillarProps> = () => {
  const { t } = useTranslation();
  return (
    <div
      id="HealthPillar"
      data-testid="HealthPillar"
      className={clsx('w-full px-[2rem] lg:w-[28.7rem] mt-[1rem] xl:mt[0rem]')}
    >
      <Carousel autoplaySpeed={7000} autoplay>
        {slides.map(({ title, description, image }) => (
          <div
            key={title}
            className="h-[36rem] w-full flex flex-col items-center justify-between  relative border rounded-[.8rem]"
          >
            <div className="uppercase text-1624 font-medium my-[2.4rem]">{t(title)}</div>
            <div className="image pb-[10rem]">
              <img src={image} alt={title} />
            </div>
            <div className="HealthPillar-gradient w-full absolute bottom-0 justify-end">
              <div className="text-1624 text-white text-center mt-[2.4rem] font-bold px-[4.5rem] font-montserrat">
                {t(description)}
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

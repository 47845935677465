import { QueryClient } from 'react-query';
import { Cognito } from '../libs/cognito';
import { saveSession } from '../utilities/saveSession';

const cognito = Cognito.getInstance();

export const isNotAuthError = (data: any) =>
  data?.response?.errors?.[0].message === 'User is not authenticated';

function findCognitoRecords(): string[] {
  // Check for CognitoIdentityServiceProvider in localstorage
  const cognitoRecords: string[] = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.startsWith('CognitoIdentityServiceProvider')) {
      cognitoRecords.push(key);
    }
  }
  return cognitoRecords;
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry(failureCount, result) {
        const currentTime = Math.floor(Date.now() / 1000);
        const expirationTime = parseInt(window.localStorage.getItem('expired') || '0', 10);
        const cognitoRecords = findCognitoRecords();

        if (cognitoRecords.length === 0) {
          return false;
        }

        if ((isNotAuthError(result) && failureCount === 0) || expirationTime < currentTime) {
          cognito.refreshSession().then((session) => {
            saveSession(session);
            queryClient.invalidateQueries();
          });
          return failureCount < 2;
        }

        if (cognitoRecords.length > 0) {
          return false;
        }

        return failureCount < 2;
      },
      retryDelay(failureCount, result) {
        if (isNotAuthError(result)) {
          return 1000;
        }
        return Math.min(failureCount > 1 ? 2 ** failureCount * 1000 : 1000, 30 * 1000);
      },
    },
  },
});

import { useQuery } from '../../hooks/useQuery';
import { GET_TIME_ZONE } from './constants';
import { GraphQLTypes, InputType, Selector } from '../../zeus';

const TimeZonesType = Selector('TimeZonesType')({
  label: true,
});

export type TimeZonesDatatype = InputType<GraphQLTypes['TimeZonesType'], typeof TimeZonesType>;
export const useQueryTimezones = () => {
  const { isFetching, data } = useQuery(
    [GET_TIME_ZONE],
    { getTimeZones: TimeZonesType },
    {
      operationName: GET_TIME_ZONE,
    },
  );
  const TimeZones = (data?.getTimeZones || {}) as TimeZonesDatatype;
  return { timeZonesLoading: isFetching, timeZones: TimeZones };
  // return TimeZones;
};

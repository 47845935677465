import { FC } from 'react';
import { createPortal } from 'react-dom';
import { ModalProps } from './types';

export const Modal: FC<ModalProps> = ({ children, onCancel, classNames = '' }) => {
  return createPortal(
    <div className="SBackground flex justify-center items-center fixed w-full h-full top-0 left-0 z-[901] box-border">
      <div
        className="SOverlay bg-black bg-opacity-[0.7] absolute w-full h-full top-0 left-0 z-[5]"
        onClick={onCancel}
      />
      <div className="SModal shadow-[0rem_1.2rem_2.6rem_rgba(16,30,115,0.06)] bg-white rounded-[.8rem] min-h-[16.5rem] p-[2.4rem] z-10">
        {children}
      </div>
    </div>,
    document.body,
  );
};

import { useEffect, useState } from 'react';

export const useWaitForData = (deps: any[], isLoading?: boolean, times = 5) => {
  const [isDataReady, setDataReady] = useState(false);
  const [counter, setCounter] = useState(times);

  useEffect(() => {
    const int = setInterval(() => {
      setCounter((prev) => prev + 1);
      if (deps.every((dep) => !!dep) || counter === times) {
        setDataReady(true);
        clearInterval(int);
      }
    }, 1000);

    return () => clearInterval(int);
  }, [...deps]);

  return isLoading || !isDataReady;
};

import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as Video } from '../../assets/video.svg';
import { Button } from '../Button/Button';
import { useTranslation } from 'react-i18next';
import { TransformModuleDataResult } from '../../types';
import { VideoSkeleton } from './VideoSection.skeleton';
import { useWaitForData } from '../../utilities/useWaitForData';
import { VideoModal } from './VideoModal';

type Data = TransformModuleDataResult['video'][0];

type WebinarSectionProps = {
  isSmallMobile?: boolean;
  isLoading?: boolean;
  data?: Data;
  onSendData: (value: string) => void;
};

export const VideoSection: FC<WebinarSectionProps> = ({
  isSmallMobile,
  data,
  isLoading: loading,
  onSendData,
}) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [videoProgress, setVideoProgress] = useState<number>(0);

  const [watchedVideo, setWatchedVideo] = useState(false);

  const isLoading = useWaitForData([data?.title, data?.data], loading);

  const openLink = () => {
    setIsModalOpen(true);
  };
  const confirmVideo = () => {
    setIsModalOpen(false);
  };
  const handleTimeUpdate = (event: React.SyntheticEvent<HTMLVideoElement>) => {
    const video = event.currentTarget;
    const progress = (video.currentTime / video.duration) * 100;

    // Track when the user reaches 25% of the video
    if (progress >= 25 && videoProgress < 25) {
      // User reached 25% of the video'
      setWatchedVideo(true);
      onSendData('true');
    }

    setVideoProgress(progress);
  };

  if (!data && !isLoading) return null;

  if (isLoading) return <VideoSkeleton />;

  return (
    <div
      data-testid="VIdeoSection"
      className={clsx(
        'xl:h-fit md:min-h-[18rem] flex flex-col justify-between pt-[2rem] border-b border-b-alto',
      )}
    >
      <div className="">
        <div className="flex flex-col md:flex-row justify-between md:items-center mb-[1rem]">
          <div className="flex items-center">
            <Video />
            <div className="ml-2 text-outerSpace text-1822">{data?.title}</div>
          </div>
        </div>
        <p className="text-manatee font-montserrat text-1522">{data?.description}</p>
      </div>
      <div className="flex flex-wrap justify-center self-end">
        <div className="max-w-[31rem] my-[2.4rem]">
          <Button disabled={false} onClick={openLink} width={isSmallMobile ? undefined : '15rem'}>
            {t('watch')}
          </Button>
          {isModalOpen && (
            <VideoModal
              onClose={() => setIsModalOpen(false)}
              onConfirm={confirmVideo}
              watchedVideo={watchedVideo}
            >
              <div className="w-[32rem] h-[35rem] sm:h-[64rem] sm:w-[64rem]">
                <div className="relative overflow-hidden w-full h-full">
                  <video
                    className="w-[32rem] h-[35rem] sm:h-[64rem] sm:w-[64rem] object-cover"
                    controls
                    autoPlay
                    onTimeUpdate={handleTimeUpdate}
                  >
                    <source src={data?.data?.signedUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </VideoModal>
          )}
        </div>
      </div>
    </div>
  );
};

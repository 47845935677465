import { FC } from 'react';
import clsx from 'clsx';
import { Button } from '../Button/Button';
import { ReactComponent as CommitToChangeIcon } from '../../assets/commit-to-change.svg';
import { useTranslation } from 'react-i18next';
import { TransformModuleDataResult } from '../../types';
import { PersonalSuccessSectionSkeleton } from './PersonalSuccessSection.skeleton';
import { useWaitForData } from '../../utilities/useWaitForData';

type PersonalSuccessSectionProps = {
  isSmallMobile?: boolean;
  isLoading?: boolean;
  onOpen: (formId: string) => void;
  data?: TransformModuleDataResult['personalSuccess'][0];
  hasCohortStarted?: boolean;
};

export const PersonalSuccessSection: FC<PersonalSuccessSectionProps> = ({
  isSmallMobile,
  data,
  isLoading: loading,
  onOpen,
  hasCohortStarted,
}) => {
  const { t } = useTranslation();

  const isLoading = useWaitForData([data?.title, data?.data], loading);

  if (!data && !isLoading) return null;

  if (isLoading) return <PersonalSuccessSectionSkeleton />;
  return (
    <div
      data-testid="CommitToChangeSection"
      className={clsx(
        'xl:h-fit md:h-[18-rem] flex flex-col justify-between pt-[2rem] border-b border-b-alto',
      )}
    >
      <div className="">
        <div className="flex justify-between items-center mb-[1rem]">
          <div className="flex items-center">
            <CommitToChangeIcon />
            <div className="ml-2 text-outerSpace text-1822">{data?.title}</div>
          </div>
        </div>
        <p className="text-manatee font-montserrat text-1522">{data?.description}</p>
      </div>
      <div className="flex justify-center self-end">
        <div className="max-w-[31rem] my-[2.4rem]">
          <Button
            onClick={() => onOpen(data?.data?.formId || '')}
            width={isSmallMobile ? '15rem' : '15rem'}
            disabled={hasCohortStarted}
          >
            {t('start')}
          </Button>
        </div>
      </div>
    </div>
  );
};

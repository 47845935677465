import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

type SignUpFinishProps = {
  email?: string;
};

export const SignUpFinish: FC<SignUpFinishProps> = ({ email }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`flex h-screen flex-col mx-[2rem] lg:ml-[10rem] justify-between max-w-[52.8rem]`}
    >
      <div className="mt-[5rem] lg:mt-[45%]">
        <section className="mb-2">
          <h1 className="text-main text-2430 font-bold font-poppins mb-4">
            {t('join-page-finish-page-title')}
          </h1>
          <p className="text-1630 mb-16">
            <Trans i18nKey="join-page-finish-page-description" values={{ email }}>
              We will contact you via <strong>{email}</strong>, once your details have been
              verified.
              <br /> The verification process may take few working days.
              <br />
              Please check your email box for further updates.
            </Trans>
          </p>
          <p className="text-1630">
            <Trans i18nKey="having-trouble-contact-us" values={{}}>
              Having trouble?
              <a href="https://cibahealth.com/contact-us" className="text-linkBlue underline">
                Contact us
              </a>
            </Trans>
          </p>
        </section>
      </div>
    </div>
  );
};

import React, { FC, useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { Plate } from '../Plate/Plate';
import { useTranslation } from 'react-i18next';
import { Title } from '../HeaderTitle/HeaderTitle';
import { ReactComponent as ChevronIcon } from '../../assets/chevron.svg';
import { ReactComponent as PrintIcon } from '../../assets/print.svg';
import { Document, Page, pdfjs } from 'react-pdf';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import debounce from 'lodash/debounce';
import { Spinner } from '../Spinner/Spinner';
import { Button } from '../Button/Button';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

type PersonalSuccessProps = {
  onClose: () => void;
  onOpen: () => void;
  username: string;
  data: object;
};

const resizeObserverOptions = {};
const maxWidth = 768;

export const CurriculumPDFSection: FC<PersonalSuccessProps> = ({
  onClose,
  onOpen,
  username,
  data,
}) => {
  const { t } = useTranslation();
  // const [file, setFile] = useState<PDFFile>(null);
  const [numPages, setNumPages] = useState<number>();
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const filePDFtokenLink = data.signedUrl;

  // Used for updatinh pdf container width on screen resize:
  const debouncedOnResize = useCallback(
    debounce((entries: ResizeObserverEntry[]) => {
      const [entry] = entries;
      if (entry) {
        setContainerWidth(entry.contentRect.width);
      }
    }, 100),
    [setContainerWidth],
  );
  const onResize = useCallback<ResizeObserverCallback>(
    (entries) => {
      debouncedOnResize(entries);
    },
    [debouncedOnResize],
  );
  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowRight':
          nextPage();
          break;
        case 'ArrowLeft':
          previousPage();
          break;
        default:
          break;
      }
    };
    // when the component mounts, attach the event listener
    document.addEventListener('keydown', handleKeyDown);
    // when the component unmounts, detach the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentPage]);

  function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
    setNumPages(nextNumPages);
    setIsLoading(false);
  }

  const handlePageLoadStart = () => {
    setIsLoading(true);
  };

  const handlePageLoadSuccess = () => {
    setIsLoading(false);
  };

  const handlePageError = () => {
    setIsError(true);
    setIsLoading(false);
  };

  const nextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, numPages || 1));
  };

  const previousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePrint = () => {
    window.open(filePDFtokenLink, '_blank');
  };

  return (
    <div data-testid="PersonalSuccess" className={clsx('')}>
      {username && <Title>{t('hello-username', { username })}</Title>}

      <Plate>
        <div className="flex items-center text-1822 pl-[3rem] pt-[3rem] pb-[2rem] my-6">
          <button onClick={onClose} className=" items-center flex mr-12">
            <ChevronIcon className="mr-4 w-3" />
            <div className="text-1820">{t('Back')}</div>
          </button>

          {filePDFtokenLink && !isLoading && (
            <button
              className="ml-auto flex items-center p-3 bg-gray-100 rounded shadow hover:bg-independence text-1820 hover:text-white"
              onClick={handlePrint}
            >
              <PrintIcon className="mr-4 w-10" />
              <span className="mr-4">Print</span>
            </button>
          )}
        </div>
        <>
          <div className="w-full p-0 mx-auto text-center">
            <div className="mb-6 min-h-12">
              {isLoading && (
                <div className="w-32 pt-24 pb-[9rem] mx-auto">
                  <Spinner opposite />
                </div>
              )}
              <div ref={setContainerRef} hidden={isError}>
                <Document
                  file={filePDFtokenLink}
                  onLoadSuccess={onDocumentLoadSuccess}
                  renderMode="canvas"
                  onError={handlePageError}
                  onLoadError={handlePageError}
                >
                  {/* Full documet with scrolling pages view: */}
                  {/* {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                      className={'shadow-md my-3 max-w-3xl mx-auto'}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                      onLoadSuccess={handlePageLoadSuccess}
                      onLoadStart={handlePageLoadStart}
                    />
                  ))} */}

                  {/* Page by page view: */}
                  <Page
                    key={currentPage}
                    pageNumber={currentPage}
                    width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                    className={'shadow-md my-3 max-w-3xl mx-auto'}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    onLoadSuccess={handlePageLoadSuccess}
                    onLoadStart={handlePageLoadStart}
                  />
                </Document>
              </div>
            </div>

            {isError && (
              <>
                {/* <iframe
                  src={filePDFtokenLink}
                  style={{ width: '100%', height: '600px' }}
                  className="h-screen max-w-[800px]"
                  onLoad={handlePageLoadSuccess}
                ></iframe> */}

                <div className="px-4 pb-[9rem] font-semibold">
                  <p>
                    🤔 Looks like document not supported by your browser, please update it and try
                    again or use
                    <a
                      href={filePDFtokenLink}
                      target="blank"
                      className="inline-block text-linkBlue underline mx-2"
                    >
                      direct link
                    </a>
                    to view document
                  </p>
                </div>
              </>
            )}

            {!isLoading && numPages && (
              <div className="flex mb-9 mt-12 items-center px-4 max-w-3xl mx-auto">
                <Button onClick={previousPage} disabled={currentPage === 1}>
                  <span>Previous page</span>
                </Button>

                <div className="flex px-3 p-0 text-nowrap">
                  <span className="">
                    {currentPage} / {numPages}
                  </span>
                </div>

                <Button onClick={nextPage} disabled={currentPage === numPages}>
                  <span>Next page</span>
                </Button>
              </div>
            )}
          </div>
        </>
      </Plate>
    </div>
  );
};

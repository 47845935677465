import { useMutation } from '../../hooks/useMutation';
import { RESEND_CONFIRMATION_LINK } from './constants';
import { $ } from '../../zeus';
import { GQLArgs } from '../../types';

type MutationResponse = Record<'resendConfirmationLink', boolean>;

export const useMutationResendConfirmationLink = (args?: GQLArgs<MutationResponse>) => {
  return useMutation(
    [RESEND_CONFIRMATION_LINK],
    {
      resendConfirmationLink: [
        {
          email: $`email`,
        },
        true,
      ],
    },
    {
      operationName: RESEND_CONFIRMATION_LINK,
    },
    args,
  );
};

export const ChatMessagesSkeleton = () => {
  return (
    <div className="bg-catskillWhite h-full w-full mr-[2.7rem] rounded-[.8rem] p-[2.4rem]">
      <div className=" h-full w-full flex flex-col animate-pulse">
        <div className="w-full flex justify-end mb-[2.4rem]">
          <div className="bg-lavenderGray w-[15.3rem] h-[6.6rem] rounded-[.8rem]"></div>
        </div>
        <div className="w-full flex mb-[2.4rem]">
          <div className="bg-lavenderGray w-full h-[2rem] rounded-[.8rem]"></div>
        </div>
        <div className="w-full flex mb-[2.4rem]">
          <div className="bg-lavenderGray w-[4rem] h-[4rem] rounded-full mr-[1.6rem]"></div>
          <div className="bg-lavenderGray w-[44.9rem] h-[11rem] rounded-[.8rem] "></div>
        </div>
        <div className="w-full flex mb-[2.4rem]">
          <div className="bg-lavenderGray w-[4rem] h-[4rem] rounded-full mr-[1.6rem]"></div>
          <div className="bg-lavenderGray w-[44.9rem] h-[15rem] rounded-[.8rem] "></div>
        </div>
        <div className="w-full flex mb-[2.4rem]">
          <div className="bg-lavenderGray w-full h-[2rem] rounded-[.8rem]"></div>
        </div>
        <div className="w-full flex justify-end mb-[2.4rem]">
          <div className="bg-lavenderGray w-[20rem] h-[6.6rem] rounded-[.8rem]"></div>
        </div>
        <div className="w-full flex justify-end mb-[2.4rem]">
          <div className="bg-lavenderGray w-[15.3rem] h-[6.6rem] rounded-[.8rem]"></div>
        </div>
      </div>
    </div>
  );
};

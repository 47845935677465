export const getResponseError = (error: any) => {
  if (error?.response?.errors?.length) {
    return error.response.errors[0]?.message;
  }
  if (error?.networkError?.result?.errors?.length) {
    return error.networkError.result.errors[0].message;
  }
  if (error?.message) {
    return error.message;
  }
  return 'Oops, something went wrong';
};

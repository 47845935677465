import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { Button } from '../Button/Button';
import { Modal } from '../Modal/Modal';

type WeightHistoryModalType = {
  onClose: () => void;
  cancelButtonName?: string;
  headerIcon?: JSX.Element;
  isHistoryLoading: boolean;
};

export const WeightHistoryModal: FC<WeightHistoryModalType> = ({
  onClose,
  children,
  cancelButtonName = 'Close',
  isHistoryLoading,
}) => {
  const { t } = useTranslation();
  return (
    <Modal onCancel={onClose}>
      <div className="flex flex-col justify-between sm:w-[42rem] sm:max-h-[30rem]">
        <div className="flex flex-col justify-between">
          <div className="flex items-start justify-between">
            <span className="text-1624 text-main font-lato font-semibold">Weight History</span>
            <div onClick={onClose} className="close cursor-pointer">
              <CloseIcon />
            </div>
          </div>
          <div className="text-[1.5rem] w-full flex flex-col justify-center items-center mt-8">
            {children}
          </div>
        </div>
        <div>
          <div className="bg-galery h-px mb-10"></div>

          <div className="flex justify-end">
            <div className="w-40">
              <Button outlined onClick={onClose}>
                {t(cancelButtonName)}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { Plate } from '../Plate/Plate';
import { useTranslation } from 'react-i18next';
import { Title } from '../HeaderTitle/HeaderTitle';
import { ReactComponent as ChevronIcon } from '../../assets/chevron.svg';

type PersonalSuccessProps = {
  onClose: () => void;
  onOpen: () => void;
  username: string;
  data: object;
};

export const CurriculumHtmlSection: FC<PersonalSuccessProps> = ({
  onClose,
  onOpen,
  username,
  data,
}) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const file = data.signedUrl;
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false); // Set loading state to false when PDF is loaded
  };

  return (
    <div data-testid="PersonalSuccess" className={clsx('')}>
      {username && <Title>{t('hello-username', { username })}</Title>}
      <Plate>
        <div className="flex items-center text-1822 pl-[3rem] pt-[3rem] pb-[2rem]">
          <button onClick={onClose} className=" items-center flex mr-12">
            <ChevronIcon className="mr-4 w-3" />
            <div className="text-manatee text-1820">{t('Back')}</div>
          </button>
        </div>
        {loading && (
          <div className="w-full text-center">
            <p className="mx-auto">Loading document...</p>
          </div>
        )}

        <iframe src={file} width="100%" height="600px" className="h-screen" onLoad={handleLoad}>
          {/* ifame's fallback content: */}
          <div className="pl-[3rem] pt-[3rem] pb-[2rem]">
            <p>
              This document not supported. Please
              <a
                href={file}
                target="blank"
                className="inline-block pt-[1rem] text-linkBlue underline"
              >
                open
              </a>{' '}
              to view it.
            </p>
          </div>
        </iframe>
      </Plate>
    </div>
  );
};

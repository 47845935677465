export const routesPath = Object.freeze({
  join: '/join',
  login: '/login',
  resetPassword: '/reset-password',
  dashboard: '/dashboard',
  groupChat: '/group-chat',
  device: '/device',
  deviceForm: '/device-form',
  add_device: '/add_device',
  invite_solera: '/invite_solera',
  invite_soleran: '/invite_soleran',
  start_program: '/start_program',
});

import clsx from 'clsx';
import { FC } from 'react';

type MenuItemArgs = {
  icon: JSX.Element;
  title?: string;
  disabled?: boolean;
  active?: boolean;
  setCurrentTab: () => void;
};

export const MenuItem: FC<MenuItemArgs> = ({ icon, title, setCurrentTab, active, disabled }) => {
  return (
    <div
      onClick={() => !disabled && setCurrentTab()}
      className={clsx('flex p-[1.9rem] mb-[.8rem] rounded-xl w-full h-[5.6rem] font-nunito ', {
        'bg-independence': active,
        'cursor-pointer': !disabled,
        'hover:bg-white': !active && !disabled,
      })}
    >
      <div
        className={clsx('flex items-center transition-all ', {
          'opacity-40': disabled,
        })}
      >
        <div className="icon mr-[1.9rem]">{icon}</div>
        {!!title && (
          <div
            className={clsx('text-1419 font-semibold', {
              'text-main': !active,
              'text-white': active,
            })}
          >
            {title}
          </div>
        )}
      </div>
    </div>
  );
};

import { FC } from 'react';
import clsx from 'clsx';
import { humanTime } from '../../utilities/dateTime';
import { wrapByLink } from '../../utilities/wrapByLink';

type ChatMyMessageProps = {
  text: string;
  datetime: Date;
};

export const ChatMyMessage: FC<ChatMyMessageProps> = ({ text, datetime }) => {
  return (
    <div
      data-testid="ChatMyMessage"
      className={clsx(
        'bg-slateBlue w-fit p-[1rem] min-w-[10rem] max-w-[80%]  rounded-[1.6rem] rounded-tr-[0rem] items-end self-end flex flex-col my-[1.2rem]',
      )}
    >
      <div className="text-white text-1622 font-semibold mb-2 font-ambit px-[.6rem] break-word">
        {wrapByLink(
          text,
          'text-schoolBus hover:underline text-1622 font-semibold mb-2 font-ambit px-[.2rem] break-all',
        )}
      </div>
      <div className="text-white font-semibold text-1214 font-ambit">{humanTime(datetime)}</div>
    </div>
  );
};

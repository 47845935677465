import { FC } from 'react';
import clsx from 'clsx';
import { ReactComponent as ChevronIcon } from '../../assets/chevron.svg';
import { useWaitForData } from '../../utilities/useWaitForData';

type WeekSwitchProps = {
  moduleIndex: number;
  isLoading?: boolean;
  title: string;
  onNext: () => void;
  onPrev: () => void;
  modulesQTY: number;
};

export const WeekSwitch: FC<WeekSwitchProps> = ({
  title,
  moduleIndex,
  isLoading: loading,
  onNext,
  onPrev,
  modulesQTY,
}) => {
  const showProgram = localStorage.getItem('showProgram');
  const isLoading = useWaitForData([title], loading);
  let nextEnabled = moduleIndex < modulesQTY - 1;
  if ('true' === showProgram) {
    nextEnabled = false;
  }
  const prevEnabled = moduleIndex > 0;

  const goNext = () => {
    if (nextEnabled && !('true' === showProgram)) {
      onNext();
    }
  };
  const goPrev = () => {
    if (prevEnabled) {
      onPrev();
    }
  };
  return (
    <div className="flex justify-between pt-4 h-[5.4rem]">
      <div
        onClick={goPrev}
        className={clsx('w-[2.4rem] h-[2.4rem] flex justify-center items-center', {
          'opacity-0': !prevEnabled,
          'cursor-pointer': prevEnabled,
        })}
      >
        <ChevronIcon />
      </div>
      {isLoading && (
        <div className="animate-pulse bg-catskillWhite rounded-[.8rem] w-[10rem] h-[2rem]"></div>
      )}
      {!isLoading && (
        <div className="text-1822">
          {title}
          {/* Week <span className="font-bold text-1822 text-success">2</span>/50 */}
        </div>
      )}
      <div
        onClick={goNext}
        className={clsx('w-[2.4rem] h-[2.4rem] flex justify-center items-center', {
          'opacity-0': !nextEnabled,
          'cursor-pointer': nextEnabled,
        })}
      >
        <ChevronIcon className="rotate-180" />
      </div>
    </div>
  );
};

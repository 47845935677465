import { ChallengeName } from 'amazon-cognito-identity-js';
import { ParticipantType } from '../../graphql/query/getMe';
import { AmplifyUser, AmplifyUserSession } from '../../libs/cognito.types';

export enum AuthStatus {
  Init = 'Init',
  Loading = 'Loading',
  HasChallenge = 'HasChallenge',
  SignedIn = 'SignedIn',
  SignedOut = 'SignedOut',
}

export enum AuthActions {
  Loading = 'Loading',
  SetSession = 'SetSession',
  SetUserData = 'SetUserData',
  SetChallenge = 'SetChallenge',
  Logout = 'Logout',
}

export type AuthStateContextType = {
  sessionInfo?: AmplifyUserSession;
  userData: Partial<ParticipantType>;
  isLoggedIn?: boolean;
  isLoading?: boolean;
  username: string;
  challenge?: ChallengeName;
  attrInfo?: any;
  authStatus?: AuthStatus;
};

export type AuthActionsContextType = {
  signInWithEmail: (email: string, password: string) => Promise<AmplifyUser>;
  signOut: () => void;
  setMe: (me: ParticipantType) => void;
  completeNewPassword: (password: string) => Promise<AmplifyUserSession>;
  verifyCode: (username: string, code: string) => Promise<any>;
  getSession: () => Promise<AmplifyUserSession>;
  sendForgotPasswordCode: (username: string) => Promise<any>;
  forgotPassword: (username: string, code: string, password: string) => Promise<any>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<any>;
};

/* eslint-disable no-console */
import clsx from 'clsx';
import { FC, useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SignUpStateContextType } from '../../page/SignUp/context';
import { Option } from '../../types';
import { emailRegExp } from '../../utilities/validationRegexp';
import { Autocomplete } from '../Autocomplete/Autocomplete';
import { Button } from '../Button/Button';
import { InputField } from '../InputField/InputField';
import { Spinner } from '../Spinner/Spinner';

type SignUpFormProps = {
  companiesOptions: Option[];
  isLoading?: boolean;
  onSubmit: (data: SignUpStateContextType['form']) => void;
  form: SignUpStateContextType['form'];
  setFormFields: (name: keyof SignUpStateContextType['form'], value: string) => void;
};

const inputFiledClass = {
  label: 'font-ambit',
  input: 'text-[1.5rem] lg:text-1324 p-[1.2rem] font-ambit',
};

export const SignUpForm: FC<SignUpFormProps> = ({
  isLoading,
  form,
  onSubmit,
  setFormFields,
  companiesOptions,
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    formState: { errors },
  } = useForm();

  const formState = {
    companyId: register('companyId', {
      required: t('join-page--sign-up--input-error-employer-or-insurance-name') as string,
    }),
    firstName: register('firstName', {
      required: t('join-page--sign-up--input-error-first-name') as string,
    }),
    lastName: register('lastName', {
      required: t('join-page--sign-up--input-error-last-name') as string,
    }),
    email: register('email', {
      validate: (value) =>
        emailRegExp.test(value) || (t('join-page--sign-up--input-error-email') as string),
      required: t('join-page--sign-up--input-error--invalid-email') as string,
    }),
    groupId: register('groupId', {
      required: t('join-page--sign-up--input-error-group-id') as string,
    }),
    memberId: register('memberId', {
      required: t('join-page--sign-up--input-error-member-id') as string,
    }),
  };
  const onSelect = (option: Option) => {
    setValue('companyId', option.value as string);
    trigger('companyId');
    setFormFields('companyId', option.value as string);
  };

  const submit = async (data: FieldValues) => {
    console.log(data);
    onSubmit({
      companyId: data.companyId,
      firstName: data.firstName?.trim(),
      lastName: data.lastName?.trim(),
      email: data.email?.trim(),
      groupId: data.groupId?.replace(/\s/g, ''),
      memberId: data.memberId?.replace(/\s/g, ''),
    });
  };

  useEffect(() => {
    setValue('companyId', form.companyId);
    setValue('firstName', form.firstName);
    setValue('lastName', form.lastName);
    setValue('email', form.email);
    setValue('groupId', form.groupId);
    setValue('memberId', form.memberId);
  }, []);

  const disabled = isLoading;
  return (
    <div
      id="SignupForm"
      className={`flex h-screen flex-col mx-[2rem] lg:ml-[10rem] justify-between max-w-[52.8rem]`}
    >
      <div className=" mt-[5rem] lg:mt-[15%]">
        <section className="mb-8">
          <h1 className="text-main text-2430 font-bold font-ambit">
            {t('join-page--sign-up--page-title')}
          </h1>
          <p className="text-1630 font-ambit">{t('join-page--sign-up--page-description')}</p>
        </section>
        <form className="form" noValidate onSubmit={handleSubmit(submit)}>
          <Autocomplete
            required
            classNames={{
              container: 'mb-4',
              ...inputFiledClass,
            }}
            label={
              t('join-page-sign-up-input-placeholder-employer-or-health-insurance-name') as string
            }
            onSelect={onSelect}
            options={companiesOptions}
            defaultValue={form.companyId}
            placeholder={t('enter-health-insurance-name') as string}
            error={errors.company?.message as string}
          />
          <InputField
            classNames={inputFiledClass}
            required
            label={t('join-page-sign-up-input-placeholder-group-id') as string}
            placeholder={t('join-page-sign-up-input-group-id') as string}
            type="text"
            error={errors.groupId?.message as string}
            {...formState.groupId}
            onBlur={(e) => setFormFields('groupId', e.target.value)}
          />

          <InputField
            classNames={inputFiledClass}
            required
            label={t('join-page-sign-up-input-placeholder-member-id') as string}
            placeholder={t('join-page-sign-up-input-member-id') as string}
            type="text"
            error={errors.memberId?.message as string}
            {...formState.memberId}
            onBlur={(e) => setFormFields('memberId', e.target.value)}
          />

          <InputField
            classNames={inputFiledClass}
            required
            label={t('join-page-sign-up-input-placeholder-first-name') as string}
            placeholder={t('join-page-sign-up-input-first-name') as string}
            type="text"
            error={errors.firstName?.message as string}
            {...formState.firstName}
            onBlur={(e) => setFormFields('firstName', e.target.value)}
          />
          <InputField
            classNames={inputFiledClass}
            required
            label={t('join-page-sign-up-input-placeholder-last-name') as string}
            placeholder={t('join-page-sign-up-input-last-name') as string}
            type="text"
            error={errors.lastName?.message as string}
            {...formState.lastName}
            onBlur={(e) => setFormFields('lastName', e.target.value)}
          />
          <InputField
            classNames={inputFiledClass}
            required
            label={t('join-page-sign-up-input-placeholder-email') as string}
            placeholder={t('join-page-sign-up-input-email') as string}
            type="email"
            error={errors.email?.message as string}
            {...formState.email}
            onBlur={(e) => setFormFields('email', e.target.value)}
          />

          <div className="">
            <Button disabled={disabled} width="16.8rem" type="submit">
              {isLoading ? <Spinner /> : t('confirm')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

import { FC } from 'react';
import { parseISO } from 'date-fns';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { ReactComponent as ClockIcon } from '../../assets/clock.svg';

import clsx from 'clsx';

type DateTimeLabelProps = {
  value: string;
};

export const DateTimeLabel: FC<DateTimeLabelProps> = ({ value }) => {
  const formattedValue = value.endsWith('Z') || value.includes('+') ? value : value + 'Z';
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = parseISO(formattedValue);

  // Change 'en-US' to locale if timezone is not in USA
  const tz = new Intl.DateTimeFormat('en-US', {
    timeZone: timeZone,
    timeZoneName: 'short',
  })
    .format(date)
    .split(' ')[1];

  const dateYear = Intl.DateTimeFormat('en-US', {
    timeZone: timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(date);

  const time = Intl.DateTimeFormat('en-GB', {
    timeZone: timeZone,
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }).format(date);

  return (
    <div data-testid="DateTimeLabel" className={clsx('w-full flex ')}>
      <div className="flex items-center mr-8">
        <CalendarIcon className="mr-4" />
        <div className="text-main font-ambit text-1423">{dateYear}</div>
      </div>
      <div className="flex items-center">
        <ClockIcon className="mr-4" />
        <div className="text-main font-ambit text-1423 uppercase">{`${time} (${tz})`}</div>
      </div>
    </div>
  );
};

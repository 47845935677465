import { FC } from 'react';
import clsx from 'clsx';
import { DateRangeLabel } from '../DateRangeLabel/DateRangeLabel';
import { useTranslation } from 'react-i18next';
import { TransformModuleDataResult } from '../../types';
import { ModuleDescriptionSkeleton } from './ModuleDescription.skeleton';
import { useWaitForData } from '../../utilities/useWaitForData';
import { parseJSON } from 'date-fns';

type ModuleDescriptionProps = {
  data?: TransformModuleDataResult['moduleDescription'];
  isLoading?: boolean;
  hasCohortStarted?: boolean;
};

export const ModuleDescription: FC<ModuleDescriptionProps> = ({
  data,
  isLoading: loading,
  hasCohortStarted,
}) => {
  const { t } = useTranslation();

  const isLoading = useWaitForData([data?.name, data?.description], loading);

  if (isLoading) return <ModuleDescriptionSkeleton />;

  return (
    <div
      data-testid="ModuleDescription"
      className={clsx('w-full flex flex-col border-b pt-[2.4rem] pb-[2.4rem]')}
    >
      <div className="flex flex-col md:flex-row md:items-center justify-between mb-[2.4rem]">
        <div className="flex flex-col md:flex-row md:items-center mr-[4rem]">
          <div className="text-outerSpace text-2430 font-semibold mr-8">{data?.name}</div>
          {data?.current && (
            <div className="flex w-fit h-[2.4rem] my-4 md:my-auto font-bold rounded-3xl px-[2.3rem] pt-[0.6rem] pb-[0.4rem] bg-success text-white text-1413 font-ambit">
              {t('current')}
            </div>
          )}
          {hasCohortStarted && (
            <div className="flex w-fit h-[2.4rem] my-4 md:my-auto font-bold rounded-3xl px-[2.3rem] pt-[0.6rem] pb-[0.4rem] bg-awaiting text-white text-1413 font-ambit">
              Awaiting
            </div>
          )}
        </div>
        {data?.start && data.end && (
          <div className="date-range">
            <DateRangeLabel from={parseJSON(data.start)} to={parseJSON(data.end)} />
          </div>
        )}
      </div>
      <p className="text-1522 font-montserrat text-black">{data?.description}</p>
    </div>
  );
};

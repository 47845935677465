export const fakeSession = {
  clockDrift: 1,
  refreshToken: {
    jwtToken: 'jwtToken',
  },
  accessToken: {
    jwtToken: 'jwtToken',
    payload: {
      client_id: 'client_id',
      event_id: 'event_id',
      iss: 'iss',
      jti: 'jti',
      origin_jti: 'origin_jti',
      scope: 'scope',
      sub: 'sub',
      token_use: 'token_use',
      username: 'username',
      auth_time: 1,
      exp: 1,
      iat: 1,
    },
  },
  idToken: {
    jwtToken: 'jwtToken',
    payload: {
      aud: 'aud',
      auth_time: 1,
      'cognito:username': 'username',
      'custom:isAdmin': 'isAdmin',
      'custom:passwordExpiredAt': 'passwordExpiredAt',
      email: 'email',
      email_verified: true,
      event_id: 'event_id',
      exp: 1,
      iat: 1,
      iss: 'iss',
      jti: 'jti',
      nickname: 'nickname',
      origin_jti: 'origin_jti',
      sub: 'sub',
      token_use: 'token_use',
    },
  },
};

import { FC } from 'react';
import clsx from 'clsx';
import { Avatar } from '../Avatar/Avatar';

type ChatParticipantsItemProps = {
  username: string;
  avatarUrl?: string;
  role: string;
  online?: boolean;
};

export const ChatParticipantsItem: FC<ChatParticipantsItemProps> = ({
  username,
  role,
  avatarUrl,
  online,
}) => {
  return (
    <div
      data-testid="ChatParticipantsItem"
      className={clsx(
        'flex bg-white rounded-[.8rem] py-[.9rem] px-[1.2rem] items-center my-[1.6rem]',
      )}
    >
      <div className="relative mr-[1.7rem]">
        <Avatar username={username} avatarUrl={avatarUrl} size={40} color="text-white" />
        {online && (
          <div className="absolute bg-success rounded-full border border-white w-[1rem] h-[1rem] right-0 bottom-0"></div>
        )}
      </div>
      <div className="flex flex-col">
        <div className="text-main text-1413 font-semibold mb-2 font-ambit px-[.6rem]">
          {username}
        </div>
        <div className="text-manatee font-semibold text-1214 font-ambit px-[.6rem]">{role}</div>
      </div>
    </div>
  );
};

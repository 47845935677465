export const ChatParticipantsListSkeleton = () => {
  return (
    <div className="bg-catskillWhite flex flex-col animate-pulse pt-[1.6rem]">
      <div className="flex justify-between mb-[1.6rem]">
        <div className="bg-lavenderGray w-[12.5rem] h-[3rem] rounded-[.8rem]"></div>
        <div className="bg-lavenderGray w-[3rem] h-[3rem] rounded-[.8rem]"></div>
      </div>
      <div className="bg-lavenderGray w-full h-[5.8rem] rounded-[.8rem] mb-[1.6rem]"></div>
      <div className="bg-lavenderGray w-full h-[5.8rem] rounded-[.8rem] mb-[1.6rem]"></div>
      <div className="bg-lavenderGray w-full h-[5.8rem] rounded-[.8rem]"></div>
      <div className="mt-[13.3rem]">
        <div className="h-px w-full bg-main opacity-10"></div>
      </div>
    </div>
  );
};

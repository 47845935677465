import { FC, useEffect, useState } from 'react';
// import { useAuthState } from '../../context/auth/authContext';
import { RpmPageView } from './Rpm.view';
import { useQueryMe } from '../../graphql/query/getMe';
import { useMutationaddWithing } from '../../graphql/mutation/addWithing';
import { API_BASE_URL } from '../../env';

export interface EmptyDict {
  name: string;
  link?: string;
  code?: string;
  title?: string;
  description?: string;
}

export const RpmPage: FC = () => {
  const { me, meLoading, refetchMe } = useQueryMe();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isWithingsConnected, setIsWithingsConnected] = useState(false);
  // eslint-disable-next-line prefer-const
  const { mutate: addDevice, isLoading: mutProgressLoading } = useMutationaddWithing();
  const [viewEmptyDict, setDevices] = useState<EmptyDict[]>([]);

  const newloadDataOnlyOnce = async () => {
    for await (const device of emptyDict) {
      const send_data = { mail: me.email, site: window.location.href, type_device: device.name };
      const uri = new URLSearchParams(send_data);
      const url = `${API_BASE_URL}` + 'api/devices/get_code?' + uri;

      await fetch(url, {
        headers: {
          'X-Auth-Key': 'X-Auth-Key',
          'Access-Control-Allow-Headers': '*',
        },
      })
        .then((res) => res.json())
        .then((result) => {
          // const dictLine = emptyDict.find((d) => d.name == device.name);
          if (result.auth_url) {
            // link
            device.link = result.auth_url;
            setIsWithingsConnected(false);
            // dictLine && (dictLine.link = result.auth_url);
          } else {
            // code
            device.code = result.token;
            setIsWithingsConnected(true);
            // dictLine && (dictLine.code = result.token);
          }
          // setDevices([device]);
        });
    }
  };

  const emptyDict: EmptyDict[] = [
    {
      name: 'withings',
      title: 'Weight scale',
      link: '',
      code: '',
      description: 'Weight, blood pressure, sleep.',
    },
    // {
    //   name: 'oruraring',
    //   link: '',
    //   code: '',
    //   description: 'See your sleep stages and discover your ideal bedtime.',
    // },
    // {
    //   name: 'fitbit',
    //   link: '',
    //   code: '',
    //   description: 'Steps, distance, calories burned and more.',
    // },
    // { name: 'dexcom', link: '', code: '', description: 'Continuous glucose monitoring.' },
  ];
  // const viewEmptyDict: EmptyDict[] = [];
  useEffect(() => {
    const fetchData = async () => {
      await newloadDataOnlyOnce();
      const meData = await refetchMe();
      setIsWithingsConnected(!!meData?.data?.me?.isWeight);
    };
    if (!me.email) {
      return;
    } else {
      fetchData().then((res) => {
        // console.log('res', JSON.stringify(emptyDict));
        setDevices(emptyDict);
        setIsLoaded(true);
      });
    }
  }, [me.email]);
  // const authState = useAuthState();
  // const isLoading = !!authState.isLoading;
  return (
    <RpmPageView
      isLoading={!isLoaded || meLoading}
      devices={viewEmptyDict}
      devicesLoading={emptyDict}
      isWithingsConnected={isWithingsConnected}
      setIsWithingsConnected={setIsWithingsConnected}
    />
  );
};

import { render } from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';
import './i18next';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './graphql/client';
import { ReactQueryDevtools } from 'react-query/devtools';
import * as Sentry from '@sentry/react';

const ENV = process.env.REACT_APP_ENV;

if ('prod' === ENV) {
  Sentry.init({
    dsn: 'https://8b4e6fd52bb5757223cf0b2ab157b50c@o4504128864845824.ingest.us.sentry.io/4507072805011456',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur
  });
}

const container = document.getElementById('root');
render(
  <QueryClientProvider client={queryClient}>
    <App />
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  container,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { ChangeEvent, FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Button } from '../Button/Button';
import { ReactComponent as ActivityIcon } from '../../assets/Activity.svg';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ReactComponent as TooltipIcon } from '../../assets/tooltip.svg';
import { TransformModuleDataResult, TransformProgressDataResult } from '../../types';
import { ActivitySectionSkeleton } from './ActivitySection.skeleton';
import { useWaitForData } from '../../utilities/useWaitForData';
import { InputField } from '../InputField/InputField';
import { ActivityModal } from './ActivityModal';
import { toast } from 'react-toastify';
import Tooltip from '../Tooltip/Tooltip';

type DataSuccess = TransformProgressDataResult['activity'][0] | null;
type ActivitySectionProps = {
  isSmallMobile?: boolean;
  isLoading?: boolean;
  data?: TransformModuleDataResult['activity'][0];
  progress: DataSuccess;
  onSendData: (value: string) => void;
  hasCohortStarted?: boolean;
};

export const ActivitySection: FC<ActivitySectionProps> = ({
  isSmallMobile,
  data,
  isLoading: loading,
  progress,
  onSendData,
  hasCohortStarted,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [activityValue, setActivityValue] = useState<string | null>();
  const placeHolder = progress?.completed ? progress.metadata.value + ' min' : undefined;

  useEffect(() => {
    const value = progress?.metadata.value;
    setActivityValue(value);
  }, [progress?.metadata.value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if ('0' === value) {
      return;
    }
    setActivityValue(value);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    // Block characters +, -, and e
    if (
      event.key === '+' ||
      event.key === '-' ||
      event.key === 'e' ||
      event.key === 'E' ||
      event.key === '.'
    ) {
      event.preventDefault();
    }
  };

  const handleSubmit = () => {
    if (!activityValue) return;
    onSendData(activityValue);
    setIsModalOpen(false);
    toast.success('Your current activity has been logged.');
  };

  const isLoading = useWaitForData([data?.title, data?.data], loading);

  if (!data && !isLoading) return null;

  if (isLoading) return <ActivitySectionSkeleton />;
  return (
    <div
      data-testid="ActivitySection"
      className={clsx('xl:fit flex flex-col justify-between pt-[2rem] border-b border-b-alto')}
    >
      <div className="flex flex-col justify-between ">
        <div className="pr-3">
          <div className="flex flex-col md:flex-row justify-between md:items-center">
            <div className="flex items-center">
              <ActivityIcon />
              <div className="ml-5 text-outerSpace text-1822">{data?.title}</div>
              <Tooltip
                content={
                  <div className="flex flex-col w-80">
                    <span className="mb-3 text-[13px]">Log your activity</span>
                    <span>
                      {' '}
                      Track your weekly activity minutes! Remember, try to work your way to 150
                      minutes of moderate activity a week to help you lose weight and feel great!
                    </span>
                  </div>
                }
              >
                <TooltipIcon className="ml-6" />
              </Tooltip>
            </div>
          </div>
        </div>

        <div className="flex justify-between flex-wrap mt-6 sm:mt-0 ml-0">
          {placeHolder ? (
            <span className="text-2430 ml-3 mt-0 sm:mt-9 text-main">{placeHolder}</span>
          ) : (
            <div
              className="flex items-center rounded-2xl py-2 px-6 my-3 sm:my-9 w-fit mt-0 sm:mt-9"
              style={{ background: '#F1F5F8' }}
            >
              <span className="mr-4 text-2632">💪</span>
              <span className="text-main font-montserrat text-1522">
                Don’t forget to add your activity for this week.
              </span>
            </div>
          )}
          <div className="w-full lg:w-auto flex justify-between">
            <div></div>
            <div className="py-9 pl-6">
              <Button
                // disabled={isDisabled}
                onClick={() => setIsModalOpen(true)}
                width={isSmallMobile ? '15rem' : '15rem'}
                {...(placeHolder ? { outlined: true } : {})}
              >
                <div className="flex items-center">
                  {placeHolder ? (
                    <>
                      <EditIcon />
                      <span className="ml-3">{'Edit'}</span>
                    </>
                  ) : (
                    <>
                      <PlusIcon />
                      <span className="ml-3">{'Add'}</span>
                    </>
                  )}
                </div>
              </Button>
              {isModalOpen && (
                <ActivityModal
                  onClose={() => setIsModalOpen(false)}
                  onConfirm={handleSubmit}
                  headerIcon={<ActivityIcon />}
                >
                  <div className="w-[32rem] sm:w-[42rem]">
                    <div className="relative overflow-hidden w-full h-full">
                      <div className="flex flex-col">
                        <div className="w-full text-1624 text-main font-lato font-semibold mt-2 mb-8">
                          Were you active this week? Enter minutes.
                        </div>
                        <div className="m-1">
                          <InputField
                            type="number"
                            classNames={{
                              // container: 'mx-r',
                              input:
                                'rounded-[3.2rem] text-[1.4rem] placeholder:text-independence py-[0.8rem] pl-[2rem] h-[4.2rem] my-1',
                              label: 'text-[1.6rem]',
                            }}
                            placeholder="Minutes"
                            onChange={handleChange}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            onKeyDown={handleKeyDown}
                            disabledInput={hasCohortStarted}
                            min={1}
                            value={activityValue || ''}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </ActivityModal>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

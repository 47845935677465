import { useMutation } from '../../hooks/useMutation';
import { VERIFY_CHANGE_PASSWORD } from './constants';
import { $, GraphQLTypes } from '../../zeus';
import { GQLArgs } from '../../types';
import { useTranslation } from 'react-i18next';

type MutationResponse = Record<
  'verifyChangePassword',
  | GraphQLTypes['VerifyChangePasswordTokenType']
  | GraphQLTypes['InvalidVerifyChangePasswordToken']
  | GraphQLTypes['VerifyChangePasswordTokenExpired']
>;

export const useMutationVerifyChangePassword = (args?: GQLArgs<MutationResponse>) => {
  const { t } = useTranslation();
  return useMutation(
    [VERIFY_CHANGE_PASSWORD],
    {
      verifyChangePassword: [
        {
          token: $`token`,
        },
        {
          '...on InvalidVerifyChangePasswordToken': {
            __typename: true,
            message: true,
          },
          '...on VerifyChangePasswordTokenExpired': {
            __typename: true,
            message: true,
          },
          '...on VerifyChangePasswordTokenType': {
            __typename: true,
            email: true,
            tmpPassword: true,
          },
        },
      ],
    },
    {
      errorTypes: [
        {
          entityName: 'verifyChangePassword',
          __typename: 'VerifyChangePasswordTokenExpired',
          message: t('link-expired') as string,
        },
        {
          entityName: 'verifyChangePassword',
          __typename: 'InvalidVerifyChangePasswordToken',
        },
      ],
      operationName: VERIFY_CHANGE_PASSWORD,
    },
    args,
  );
};

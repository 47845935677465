export const GET_ME = 'ME';
export const GET_CHAT_TOKEN = 'GET_CHAT_TOKEN';
export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_SECTION_MATERIALS_FILE = 'GET_SECTION_MATERIALS_FILE';
export const GET_PARTICIPANT_PROGRAM_MODULES = 'GET_PARTICIPANT_PROGRAM_MODULES';
export const GET_MODULE_PROGRESS = 'GET_MODULE_PROGRESS';
export const GET_PARTICIPANTS = 'GET_PARTICIPANTS';
export const GET_PROVIDERS = 'GET_PROVIDERS';
export const GET_TIME_ZONE = 'GET_TIME_ZONES';
export const GET_HANG_LINK = 'GET_HANG_LINK';

import { FC } from 'react';
import clsx from 'clsx';
import { humanTime } from '../../utilities/dateTime';
import { Avatar } from '../Avatar/Avatar';
import { wrapByLink } from '../../utilities/wrapByLink';
import { ProviderDataType } from '../../graphql/query/getCareTeam';
import { User } from '@twilio/conversations';

type ChatOpponentMessageProps = {
  datetime: Date;
  username: string;
  text: string;
  replyUsername?: string;
  replyText?: string;
  careTeam?: (ProviderDataType & { chatUser: Promise<User> | undefined; user?: User })[];
};

export const ChatOpponentMessage: FC<ChatOpponentMessageProps> = ({
  username,
  text,
  datetime,
  replyUsername,
  replyText,
  careTeam,
}) => {
  const nameParts = username.split(' ');
  let modifiedName = username;
  if (!careTeam?.some((user) => user.fullName === username)) {
    modifiedName = nameParts[0] + ' ' + nameParts[1]?.charAt(0) + '.';
  }
  return (
    <div data-testid="ChatOpponentMessage" className={clsx('flex my-[1.2rem]')}>
      <div className="mr-[1.6rem]">
        <Avatar username={username} color="text-white" />
      </div>
      <div className="w-full">
        <div className="font-ambit text-1214 text-main font-semibold mb-[.6rem]">
          {modifiedName}
        </div>
        <div className="bg-white min-w-[10rem] w-fit max-w-[80%] p-[1rem] rounded-[1.6rem] rounded-tl-[0rem] items-start self-start flex flex-col mb-[1.2rem]">
          {replyUsername && replyText && (
            <div className="flex mb-4 mt-[.2rem] px-[.6rem]">
              <div className="w-[.4rem] bg-slateBlue rounded-tl-[1rem] rounded-bl-[1rem]"></div>
              <div className="flex flex-col bg-catskillWhite max-w-[46rem] py-[.8rem] px-[1.2rem] rounded-tr-[1rem] rounded-br-[1rem]">
                <div className="text-manatee text-1214 font-ambit font-semibold mb-2">
                  {replyUsername}
                </div>
                <div className="text-independence text-ellipsis text-1622 font-semibold font-ambit truncate">
                  {wrapByLink(replyText)}
                </div>
              </div>
            </div>
          )}
          <div className="text-main text-1622 font-semibold mb-2 font-ambit px-[.6rem] break-word">
            {wrapByLink(
              text,
              'text-slateBlue hover:underline text-1622 font-semibold mb-2 font-ambit px-[.2rem] break-all',
            )}
          </div>
          <div className="text-manatee font-semibold text-1214 font-ambit px-[.6rem]">
            {humanTime(datetime)}
          </div>
        </div>
      </div>
    </div>
  );
};

import { FC } from 'react';
import ReactDOM from 'react-dom';

export const TitleContainer: FC = () => {
  return (
    <div>
      <span
        className="text-[2.8rem] leading-[2rem] font-semibold font-montserrat"
        id="header-title-container"
      />
    </div>
  );
};

export const Title: FC = ({ children }) => {
  const node = document.getElementById('header-title-container');

  return node ? ReactDOM.createPortal(children, node) : <></>;
};

import React, { FC } from 'react';
import { ReactComponent as ErrorCalendar } from '../../../assets/error-calendar.svg';
import { Trans } from 'react-i18next';

type ErrorProps = {
  error?: string;
};
const Error: FC<ErrorProps> = ({ error }) => {
  return (
    <div className="flex flex-col m-h-full justify-between items-center pt-[3rem]">
      <ErrorCalendar className="mt-[3rem] mb-[3.1rem]" />
      <div className="font-ambit font-bold text-2023 text-center text-deepPurple">
        {error}
        {/*Unfortunately, no available dates*/}
      </div>
      <div className="font-ambit font-semibold text-1624 text-deepPurple text-center text-balance opacity-50 mt-[1rem] max-w-[73rem]">
        Our team is currently working to add more. Please check this link again later. Once it
        appears, you'll be able to select the date and time for the program start.
      </div>
      <div className="flex flex-col items-center mt-[3rem] mb-[5rem]">
        <p className="font-ambit text-1421 text-black font-normal mt-auto">
          <Trans i18nKey="having-trouble-contact-us" values={{}}>
            Having trouble?
            <a
              href="https://cibahealth.com/contact-us"
              className="text-linkBlue underline ml-[1rem]"
              target={'_blank'}
            >
              Contact us
            </a>
          </Trans>
        </p>
        <p className="font-ambit text-1421 text-black mt-auto">
          <Trans i18nKey="having-questions-refer-here" values={{}}>
            Having questions?
            <a
              href="https://cibahealth.com/contact-us"
              className="text-linkBlue underline ml-[1rem]"
              target={'_blank'}
            >
              Refer Here
            </a>
          </Trans>
        </p>
      </div>
    </div>
  );
};
export default Error;

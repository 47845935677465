import { FC } from 'react';
import clsx from 'clsx';
import { generateAvatar } from '../../utilities/generateAvatar';

type AvatarProps = {
  username: string;
  avatarUrl?: string;
  size?: number;
  color?: string;
};

export const Avatar: FC<AvatarProps> = ({
  username,
  avatarUrl,
  size = 40,
  color = 'bg-manatee',
}) => {
  return (
    <div
      data-testid="Avatar"
      style={{ width: size, height: size }}
      className={clsx(
        'rounded-full font-nunito font-[1.6rem] leading-[2.2rem] bg-manatee flex justify-center items-center',
        color,
      )}
    >
      {avatarUrl ? <img src={avatarUrl} alt={username} /> : generateAvatar(username)}
    </div>
  );
};

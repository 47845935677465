import clsx from 'clsx';

import { Menu } from './Menu';
import Logo from '../../assets/logo.svg';
import { useHistory } from 'react-router-dom';
import { routesPath } from '../../constants/routes';

export const Sidebar = () => {
  const history = useHistory();
  return (
    <div className={clsx('flex flex-col h-full rounded-[2rem] px-4 transition-all w-[21.6rem]')}>
      <div
        onClick={() => history.push(routesPath.dashboard)}
        className="logo hidden cursor-pointer xl:flex justify-center items-center h-[6.4rem] mb-[6.2rem]"
      >
        <img src={Logo} alt="logo" className="w-[16rem]" />
      </div>
      <Menu />
    </div>
  );
};

import { FC, useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useAuthState, useAuthActions } from '../../context/auth/authContext';
import { useHistory, useLocation } from 'react-router-dom';
import { routesPath } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { LoginPageView } from './Login.view';
import { useMutationVerifyChangePassword } from '../../graphql/mutation/verifyChangePassword';
import { getSearchParams } from '../../utilities/getSearchParams';
import { useChatActions } from '../../context/chat/chatContext';
import { datadogRum } from '@datadog/browser-rum';

export const LoginPage: FC = () => {
  const { t } = useTranslation();
  const authState = useAuthState();
  const authActions = useAuthActions();
  const { chatLogout } = useChatActions();
  const { mutateAsync: verifyPassword } = useMutationVerifyChangePassword();
  const location = useLocation<{ from: { pathname: string } }>();

  const { from } = location.state || { from: { pathname: '/' } };

  const history = useHistory();
  const [showSpinner, setShowSpinner] = useState(false);
  const [newPasswordRequired, setNewPasswordRequired] = useState<
    | {
        FRIENDLY_DEVICE_NAME: string;
        USER_ID_FOR_SRP: string;
      }
    | undefined
  >(undefined);
  const [error, setError] = useState<string | undefined>();

  const isLoading = !!authState.isLoading;

  const signInClicked = async ({ email, password }: FieldValues) => {
    localStorage.removeItem('username');

    setError(undefined);
    try {
      const result = await authActions.signInWithEmail(email.toLowerCase(), password);

      if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
        toast.warning(t('please-set-a-new-password'));
        return setNewPasswordRequired(result.challengeParam);
      }
      history.push(from);
    } catch (err: any) {
      if (err.message === 'Network error') {
        toast.error(t('no-connection-to-the-server-try-again-later'));
      } else if (err.code === 'UserNotConfirmedException') {
        // history.push('verify');
      } else {
        toast.error(err.message);
        setError(err.message);
      }
    }
  };

  const newPasswordClicked = async ({ password }: FieldValues) => {
    setError(undefined);

    try {
      await authActions.completeNewPassword(password);
      history.push(routesPath.dashboard);
    } catch (err: any) {
      setError(err.message);
    }
  };

  const loginByTempPassword = async () => {
    const { token } = getSearchParams('token');
    if (!token) {
      return;
    }
    try {
      setShowSpinner(true);
      const result = await verifyPassword({ token });

      if (result?.verifyChangePassword?.__typename === 'VerifyChangePasswordTokenType') {
        const { email, tmpPassword } = result.verifyChangePassword;

        if (email && tmpPassword) {
          datadogRum.setUser({
            email: email,
          });

          signInClicked({
            email,
            password: tmpPassword,
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    loginByTempPassword();
    chatLogout();
  }, [location]);

  return (
    <LoginPageView
      error={error}
      isLoading={isLoading}
      showSpinner={showSpinner}
      newPasswordClicked={newPasswordClicked}
      newPasswordRequired={!!newPasswordRequired}
      signInClicked={signInClicked}
    />
  );
};

import React, { FC, ReactNode, useState, useEffect, useRef } from 'react';

type TooltipProps = {
  content?: ReactNode;
  children: ReactNode;
  position?: 'top' | 'bottom';
};

const Tooltip: FC<TooltipProps> = ({ content, children, position = 'top' }) => {
  const [isVisible, setIsVisible] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (isVisible && tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setIsVisible(false);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isVisible]);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="relative inline-block" ref={tooltipRef}>
      <div onClick={toggleVisibility} className="relative cursor-pointer">
        {children}
      </div>
      {isVisible && content && (
        <div
          className={`absolute transform -translate-x-full sm:-translate-x-1/2 ${
            position === 'bottom' ? 'top-full' : 'bottom-full'
          }`}
        >
          <div className="relative">
            <div className="rounded-lg bg-main text-white px-6 py-3 text-[12px] shadow-lg">
              <div className="">{content}</div>
            </div>
            <div
              className={`absolute w-0 h-0 bottom-0 left-full sm:left-1/2 pr-16 sm:pr-0 transform -translate-x-1/2  ${
                position === 'bottom' ? 'bottom-full' : 'top-full'
              }`}
            >
              <div className="w-4 h-4 bg-main rotate-45 absolute -bottom-2"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;

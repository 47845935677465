import { FC } from 'react';
import { parseJSON, format } from 'date-fns';
import clsx from 'clsx';
import { ReactComponent as Cam } from '../../assets/video-cam.svg';
import { DateTimeLabel } from '../DateTimeLabel/DateTimeLabel';
import { Button } from '../Button/Button';
import { useTranslation } from 'react-i18next';
import { TransformModuleDataResult } from '../../types';
import { WebinarSkeleton } from './WebinarSection.skeleton';
import { useWaitForData } from '../../utilities/useWaitForData';
import moment from 'moment';

type Data = TransformModuleDataResult['webinar'][0] | TransformModuleDataResult['dietitian'][0];

type WebinarSectionProps = {
  isSmallMobile?: boolean;
  onStartCall: () => void;
  isLoading?: boolean;
  data?: Data;
};

const makeGoogleDateTime = (date: Date) => {
  return format(date, 'yyyyMMdd___HHmmss').replace('___', 'T');
};

const makeGoogleCalendarEvent = (data?: Data) => {
  if (!data) return '';
  const { description, title } = data;
  const { startedAt, url: zoom } = data?.data;
  if (!startedAt) return '';

  const end = parseJSON(startedAt);
  end.setHours(end.getHours() + 1);
  const url = new URL('https://calendar.google.com/calendar/r/eventedit');
  url.searchParams.append('text', title);
  url.searchParams.append('details', description || '');
  // url.searchParams.append('location', zoom || 'zoom');
  url.searchParams.append('location', window.location.href);
  url.searchParams.append(
    'dates',
    `${makeGoogleDateTime(parseJSON(startedAt))}/${makeGoogleDateTime(end)}`,
  );
  return url.toString();
};
export const WebinarSection: FC<WebinarSectionProps> = ({
  isSmallMobile,
  data,
  onStartCall,
  isLoading: loading,
}) => {
  const { t } = useTranslation();
  const openZoomLink = () => {
    if (data?.data?.url) {
      onStartCall();
      window.open(data?.data?.url, '_blank');
    }
  };

  const googleCalendarLink = makeGoogleCalendarEvent(data);

  const isLoading = useWaitForData([data?.title, data?.data], loading);

  const isFutureDate =
    data?.data?.startedAt && moment.utc(data?.data?.startedAt).toDate() > moment.utc().toDate();
  const webinarAvailable = (startedAt: string | undefined): boolean => {
    if (!startedAt) {
      return false;
    }

    const webinarStartDate = moment.utc(startedAt).toDate();
    const webinarEndDate = moment.utc(startedAt).add(1, 'hour').toDate(); // Adding 1 hour

    const currentTimeUtc = moment.utc().toDate();
    return currentTimeUtc >= webinarStartDate && currentTimeUtc <= webinarEndDate;
  };

  const shouldRenderComponent = webinarAvailable(data?.data?.startedAt) || isFutureDate;

  if (!data && !isLoading) return null;

  if (isLoading) return <WebinarSkeleton />;

  return shouldRenderComponent ? (
    <div
      data-testid="VIdeoSection"
      className={clsx(
        'xl:h-fit md:min-h-[18rem] flex flex-col justify-between pt-[1.4rem] border-b border-b-alto',
      )}
    >
      <div className="">
        <div className="flex flex-col md:flex-row justify-between md:items-center mb-[1rem]">
          <div className="flex items-center">
            <Cam />
            <div className="ml-2 text-outerSpace text-1822">{data?.title}</div>
          </div>
          {data?.data?.startedAt && (
            <div className="w-[40rem] ml-2 flex md:justify-end">
              <div className="">
                <DateTimeLabel value={data?.data?.startedAt} />
              </div>
            </div>
          )}
        </div>
        <p className="text-manatee font-montserrat text-1522">{data?.description}</p>
      </div>
      <div className="flex flex-wrap justify-center md:self-end">
        {googleCalendarLink && (
          <div className="w-full max-w-[31rem] sm:w-[21rem] my-[2.4rem] sm:mr-10">
            <Button
              href={googleCalendarLink}
              width={isSmallMobile ? undefined : '210px'}
              align={isSmallMobile ? undefined : 'justify-left'}
              outlined
              icon={<div className="pl-6 mr-2">+</div>}
            >
              {t('add-to-google-calendar')}
            </Button>
          </div>
        )}
        <div className="w-full max-w-[31rem] sm:w-fit my-[2.4rem]">
          <Button
            disabled={!data?.data?.url}
            onClick={openZoomLink}
            width={isSmallMobile ? undefined : '15rem'}
          >
            {t('join')}
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export const LOGIN = 'LOGIN';
export const MAKE_PROGRESS = 'MAKE_PROGRESS';
export const VERIFY_CHANGE_PASSWORD = 'VERIFY_CHANGE_PASSWORD';
export const RESEND_CONFIRMATION_LINK_BY_TOKEN = 'RESEND_CONFIRMATION_LINK_BY_TOKEN';
export const RESEND_CONFIRMATION_LINK = 'RESEND_CONFIRMATION_LINK';

export const SIGN_UP = 'SIGN_UP';

export const SEND_HAND_OUT = 'SEND_HAND_OUT';

export const ADD_DEVICE = 'ADD_DEVICE';

export const ADD_CHAT_ACTIVITY = 'ADD_CHAT_ACTIVITY';

export const LAND_PARTICIPANT = 'LAND_PARTICIPANT';

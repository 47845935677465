import { toast } from 'react-toastify';
import { useAuthActions } from '../context/auth/authContext';
import { GraphQLTypes, InputType, OperationOptions, ValueTypes } from '../zeus';
import { useTypedMutation } from '../zeus/reactQuery';
import { MutOptions } from '../types';
import { isNotAuthError } from '../graphql/client';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { getResponseError } from '../utilities/getErrors';

const callToast = (error: any) => {
  const msg = getResponseError(error);
  if (error) {
    toast.error(msg, {
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
    });
  }
};

export function useMutation<
  O extends 'Mutation',
  TData extends ValueTypes[O],
  TResult = InputType<GraphQLTypes[O], TData>,
  TError extends Error = Error,
>(
  mutationKey: string | unknown[],
  mutation: TData | ValueTypes[O],
  zeusOptions?: OperationOptions & {
    successText?: string;
    errorTypes?: {
      __typename: string;
      message?: string;
      entityName: keyof Partial<InputType<GraphQLTypes[O], TData>>;
    }[];
    headers?: Record<string, any>;
  },
  options?: MutOptions<O, TData, TResult>,
) {
  const { signOut } = useAuthActions();
  const { t } = useTranslation();

  return useTypedMutation<O, TData, Record<string, any>, TResult, TError>(
    mutationKey,
    mutation,
    {
      ...options,
      onSuccess: (data, variables, ctx) => {
        if (data) {
          const errorTag = zeusOptions?.errorTypes?.find(
            (type) => _.get(data, `${type.entityName as string}.__typename`) === type.__typename,
          );
          /*console.log('data', data);
          console.log('zeusOptions', zeusOptions);*/
          if (
            zeusOptions?.errorTypes?.length &&
            !_.get(data, `${zeusOptions?.errorTypes?.[0]?.__typename as string}.__typename`)
          ) {
            console.warn(
              'Looks like you forgot to add __typename to your error type',
              zeusOptions?.errorTypes?.[0]?.entityName,
            );
          }

          if (errorTag && _.get(data, `${errorTag.entityName as string}`)) {
            const msg =
              errorTag.message ||
              (_.get(
                data,
                `${errorTag.entityName as string}.message`,
                'Oops, something went wrong',
              ) as string);
            const apolloError = new Error(msg);
            callToast(apolloError);
            options?.onError?.(apolloError, variables, ctx);
            return;
          }
        }

        options?.onSuccess?.(data, variables, ctx);

        if (zeusOptions?.successText) {
          toast.success(zeusOptions.successText, {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        }
      },
      onError: (error, vars, ctx) => {
        options?.onError?.(error, vars, ctx);
        if (isNotAuthError(error)) {
          signOut();
          callToast({
            message: t('your-session-has-expired'),
          });
        }

        if (error.message === 'Failed to fetch') {
          toast.error(t('no-connection-to-the-server-try-again-later'), {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        } else {
          callToast(error);
        }
      },
    },
    zeusOptions,
    undefined,
    {
      headers: {
        ...zeusOptions?.headers,
      },
    },
  );
}

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import ar from './locales/ar.json';

const resources = { en, ar } as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'ar'],
    debug: false,
    resources,

    detection: {
      caches: ['blnLngDetector', 'localStorage'],
      order: ['blnLngDetector', 'localStorage', 'navigator'],
    },
  });

export default i18n;

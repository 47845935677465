import { toast } from 'react-toastify';
import { UseQueryOptions } from 'react-query';
import { useAuthActions } from '../context/auth/authContext';
import { GraphQLTypes, InputType, OperationOptions, ValueTypes } from '../zeus';
import { useTypedLazyQuery, useTypedQuery } from '../zeus/reactQuery';
import { isNotAuthError } from '../graphql/client';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { getResponseError } from '../utilities/getErrors';
import { useState } from 'react';

const callToast = (error: any) => {
  const msg = getResponseError(error);
  if (error) {
    toast.error(msg, {
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
    });
  }
};

export function useQuery<
  O extends 'Query',
  TData extends ValueTypes[O],
  TResult = InputType<GraphQLTypes[O], TData>,
>(
  queryKey: string | unknown[],
  query: TData | ValueTypes[O],
  zeusOptions?: OperationOptions & {
    headers?: Record<string, any>;
    errorTypes?: {
      __typename: string;
      message?: string;
      entityName: keyof Partial<InputType<GraphQLTypes[O], TData>>;
    }[];
  },
  options?: Omit<UseQueryOptions<TResult>, 'queryKey' | 'queryFn'>,
) {
  const { signOut } = useAuthActions();
  const { t } = useTranslation();

  return useTypedQuery(
    queryKey,
    query,
    {
      ...options,
      onSuccess: (data) => {
        if (data) {
          const errorTag = zeusOptions?.errorTypes?.find(
            (type) => _.get(data, `${type.entityName as string}.__typename`) === type.__typename,
          );

          if (errorTag && _.get(data, `${errorTag.entityName as string}`)) {
            const msg =
              errorTag.message ||
              (_.get(
                data,
                `${errorTag.entityName as string}.message`,
                'Oops, something went wrong',
              ) as string);
            const apolloError = new Error(msg);
            callToast(apolloError);
            options?.onError?.(apolloError);
            return;
          }
        }
        options?.onSuccess?.(data);
      },
      onError: (error: any) => {
        options?.onError?.(error);
        if (isNotAuthError(error)) {
          signOut();
          callToast({
            message: t('your-session-has-expired'),
          });

          return;
        }

        if (error?.message === 'Failed to fetch') {
          toast.error(t('no-connection-to-the-server-try-again-later'), {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        } else {
          callToast(error);
        }
      },
    },
    zeusOptions,
    undefined,
    {
      headers: {
        ...zeusOptions?.headers,
      },
    },
  );
}
export function useLazyQuery<
  O extends 'Query',
  TData extends ValueTypes[O],
  TResult = InputType<GraphQLTypes[O], TData>,
>(
  query: TData | ValueTypes[O],
  zeusOptions?: OperationOptions & {
    headers?: Record<string, any>;
    errorTypes?: {
      __typename: string;
      message?: string;
      entityName: keyof Partial<InputType<GraphQLTypes[O], TData>>;
    }[];
  },
  options?: { onSuccess: (d: TResult) => void; onError: (e: any) => void },
) {
  const { signOut } = useAuthActions();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const fetch = useTypedLazyQuery(
    query,
    {
      onSuccess: (data: TResult) => {
        if (data) {
          const errorTag = zeusOptions?.errorTypes?.find(
            (type) => _.get(data, `${type.entityName as string}.__typename`) === type.__typename,
          );

          if (errorTag && _.get(data, `${errorTag.entityName as string}`)) {
            const msg =
              errorTag.message ||
              (_.get(
                data,
                `${errorTag.entityName as string}.message`,
                'Oops, something went wrong',
              ) as string);
            const apolloError = new Error(msg);
            callToast(apolloError);
            options?.onError?.(apolloError);
            return;
          }
        }
        options?.onSuccess?.(data);
      },
      onError: (error: any) => {
        options?.onError?.(error);
        if (isNotAuthError(error)) {
          signOut();
          callToast({
            message: t('your-session-has-expired'),
          });

          return;
        }

        if (error?.message === 'Failed to fetch') {
          toast.error(t('no-connection-to-the-server-try-again-later'), {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        } else {
          callToast(error);
        }
      },
    },
    zeusOptions,
    undefined,
    {
      headers: {
        ...zeusOptions?.headers,
      },
    },
  );

  return {
    fetch: (variables: any) => {
      setIsLoading(true);
      return fetch(variables).finally(() => setIsLoading(false));
    },
    isLoading,
  };
}

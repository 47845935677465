import { useMutation } from '../../hooks/useMutation';
import { CreateMutationResponse, GQLArgs } from '../../types';
import { SIGN_UP } from './constants';
import { $ } from '../../zeus';
import { useTranslation } from 'react-i18next';

export const useMutationSignUp = (args?: GQLArgs<CreateMutationResponse<'signUpParticipant'>>) => {
  const { t } = useTranslation();
  return useMutation(
    [SIGN_UP],
    {
      signUpParticipant: [
        {
          data: {
            companyId: $`companyId`,
            email: $`email`,
            firstName: $`firstName`,
            lastName: $`lastName`,
            groupId: $`groupId`,
            memberId: $`memberId`,
          },
        },
        {
          '...on ParticipantDuplicatedError': {
            message: true,
            __typename: true,
          },
          '...on ParticipantType': {
            __typename: true,
            id: true,
            createdAt: true,
            updatedAt: true,
            email: true,
            firstName: true,
            lastName: true,
            memberId: true,
            groupId: true,
            status: true,
          },
        },
      ],
    },
    {
      errorTypes: [
        {
          entityName: 'signUpParticipant',
          __typename: 'ParticipantDuplicatedError',
          message: t('sorry-current-email-already-registered') as string,
        },
      ],
      operationName: SIGN_UP,
    },
    args,
  );
};

import { GraphQLTypes } from '../zeus';
import { Option } from '../types';

export const convertCompaniesToOptions = (
  companies: GraphQLTypes['CompanyType'][],
  withAllOptions = true,
): Option[] => {
  const mapped = companies.map((r): Option => ({ value: r.id, label: r.name }));
  if (!withAllOptions) return mapped;

  return [{ value: null, label: 'Surgeries' }, ...mapped];
};

import { FC, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as ChevronIcon } from '../../assets/chevron.svg';

type CollapseProps = {
  header: React.ReactNode | string;
  isOpen?: boolean;
  onToggle?: (isExpanded: boolean) => void;
};

export const Collapse: FC<CollapseProps> = ({ header, children, onToggle, isOpen }) => {
  const [isExpanded, setIsExpanded] = useState(!!isOpen);

  return (
    <div data-testid="Collapse" className={clsx('animate')}>
      <div
        onClick={() => {
          setIsExpanded(!isExpanded);
          onToggle && onToggle(!isExpanded);
        }}
        className="flex w-full items-center relative"
      >
        <div className="w-full">{header}</div>
        <div className="absolute top-[2.5rem] right-4 cursor-pointer">
          <ChevronIcon
            className={clsx('transition-transform transform', {
              '-rotate-90': !isExpanded,
              'rotate-90': isExpanded,
            })}
          />
        </div>
      </div>
      <div
        style={{
          transition: 'max-height 0.2s ease-out',
          maxHeight: isExpanded ? 900 : 0,
        }}
        className={clsx('overflow-hidden', {})}
      >
        {children}
      </div>
    </div>
  );
};

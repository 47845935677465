import { FC, useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useAuthState, useAuthActions } from '../../context/auth/authContext';
import { useHistory, useLocation } from 'react-router-dom';
import { routesPath } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ResetPasswordView } from './ResetPassword.view';
import { getSearchParams } from '../../utilities/getSearchParams';
import { useMutationResendConfirmationLinkByToken } from '../../graphql/mutation/resendConfirmationLinkByToken';
import { useMutationResendConfirmationLink } from '../../graphql/mutation/resendConfirmationLink';
import { datadogRum } from '@datadog/browser-rum';

export const ResetPassword: FC = () => {
  const { t } = useTranslation();
  const authState = useAuthState();
  const authActions = useAuthActions();
  const { mutateAsync: resendConfirmation } = useMutationResendConfirmationLinkByToken();
  const { mutateAsync: resendConfirmationByEmail } = useMutationResendConfirmationLink();

  const location = useLocation<{ from: { pathname: string } }>();
  const { reset_code, username } = getSearchParams('reset_code', 'username');
  const history = useHistory();
  const [isResetPasswordFlow, setIsResetPassword] = useState(!!reset_code && !!username);
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const isLoading = !!authState.isLoading || loading;

  const restorePasswordClicked = async ({ email }: FieldValues) => {
    setError(undefined);
    datadogRum.setUser({
      email: email,
    });
    try {
      setLoading(true);
      await resendConfirmationByEmail({ email });
      await authActions.sendForgotPasswordCode(email);
      setLoading(false);

      toast.success(t('please-check-your-email-to-confirm-your-password-reset'));
      history.push(routesPath.login);
    } catch (err: any) {
      setLoading(false);
      if (err.message === 'Network error') {
        toast.error(t('no-connection-to-the-server-try-again-later'));
      } else if (err.code === 'UserNotConfirmedException') {
        // history.push('verify');
      } else {
        toast.error(err.message);
        setError(err.message);
      }
    }
  };

  const newPasswordClicked = async ({ password }: FieldValues) => {
    setError(undefined);
    const { reset_code, username } = getSearchParams('reset_code', 'username');
    if (!reset_code || !username) return;

    try {
      await authActions.forgotPassword(username.toLowerCase(), reset_code, password);
      toast.success(t('password-reset-successfully')); // You successfully reset your password
      history.push(routesPath.login);
    } catch (err: any) {
      setError(err.message);
    }
  };

  useEffect(() => {
    const { token, reset_code, username, resend } = getSearchParams(
      'token',
      'reset_code',
      'username',
      'resend',
    );

    if (Number(resend)) {
      resendConfirmation({ token }).then((data) => {
        if (data?.resendConfirmationLinkByToken) {
          toast.success(t('the-confirmation-link-has-been-resent-successfully'));
          history.push({
            pathname: routesPath.login,
          });
        }
      });
      return;
    }

    if (reset_code && username) {
      setIsResetPassword(true);
      return;
    }

    if (token) {
      history.push({
        pathname: routesPath.login,
        search: `?token=${token}`,
      });
    }
  }, [location]);

  return (
    <ResetPasswordView
      error={error}
      isLoading={isLoading}
      newPasswordClicked={newPasswordClicked}
      newPasswordRequired={isResetPasswordFlow}
      restorePasswordClicked={restorePasswordClicked}
    />
  );
};

/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */

export type IState = Record<string, any>;

export type IAction<T extends string = string> = {
  type: T;
  payload?: any;
};

export type Reducer<S, A extends IAction<T>, T extends string> = (
  payload: any,
  state: S,
  type: A['type'],
) => IState;

let __DEV__ = false;
try {
  __DEV__ = process.env.NODE_ENV !== 'production';
} catch (e) {}

export function makeAction<A extends IAction>(type: A['type']) {
  return (payload?: A['payload']) => ({ type, payload });
}
export function createReducer<
  S extends Record<string, any>,
  A extends IAction<T>,
  T extends string,
>(initialState: S = {} as S, handlers: Record<T, Reducer<S, A, T>>) {
  if (__DEV__ && (handlers as any)['undefined']) {
    console.warn('Reducer contains an "undefined" action type. Have you misspelled a constant?');
  }

  return function reducer(state = initialState, action: A) {
    let newState = state;

    if (handlers.hasOwnProperty(action.type)) {
      const result = handlers[action.type](action.payload as any, state, action.type);
      newState = { ...state, ...result };
    }
    if ((handlers as any)['*']) {
      return { ...newState, ...(handlers as any)['*'](action.payload, newState, action.type) };
    }
    return newState;
  };
}

export default createReducer;

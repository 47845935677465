import { TitleContainer } from '../HeaderTitle/HeaderTitle';
import { clsx } from 'clsx';
import { Sidebar } from '../SideBar/SideBar';
import { TopAvatar } from '../TopAvatar/TopAvatar';
import logo from '../../assets/logo.svg';
import hamburger from '../../assets/hamburger.svg';
import { ReactComponent as BellIcon } from '../../assets/bell.svg';
import { useAuthState } from '../../context/auth/authContext';
import { Drawer } from 'antd';
import { useEffect, useState } from 'react';
import { routesPath } from '../../constants/routes';
import { useHistory } from 'react-router-dom';
import { useChatState } from '../../context/chat/chatContext';

export const Layout: React.FC<{ removedContentPadding?: boolean }> = ({
  children,
  removedContentPadding,
}) => {
  const history = useHistory();
  const { messageCounter } = useChatState();
  const { isLoading } = useAuthState();
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="flex bg-catskillWhite">
      <div className="hidden xl:block mt-4 mb-4 min-h-[70rem] h-full">
        <Sidebar />
      </div>
      <Drawer
        title={
          <div
            onClick={() => history.push(routesPath.dashboard)}
            className="flex xl:hidden justify-center items-center"
          >
            <img src={logo} alt="logo" className="w-[16rem]" />
          </div>
        }
        width={264}
        placement="left"
        onClose={onClose}
        open={open}
      >
        <Sidebar />
      </Drawer>
      <div className="w-full flex flex-col">
        <header className="page-head flex justify-between mt-2 mb-2 mx-9 xl:mx-12 h-[8rem]">
          <div className="hidden xl:flex flex-col justify-center">
            <div className="">
              <TitleContainer />
            </div>
          </div>
          <div onClick={showDrawer} className="flex xl:hidden justify-center items-center cursor">
            <img src={hamburger} alt="hamburger" className="w-[4rem]" />
          </div>
          <div
            onClick={() => history.push(routesPath.dashboard)}
            className="flex xl:hidden mr-0 lg:mr-auto justify-center items-center ml-4 xl:ml-0"
          >
            <img src={logo} alt="logo" className="w-[16rem]" />
          </div>
          <div className="flex items-center">
            <div className="w-[5rem] ">
              <div
                onClick={() => history.push(routesPath.groupChat)}
                className="bell flex items-center justify-center w-[36px] h-[36px] p-2 rounded-full relative cursor-pointer"
              >
                <BellIcon className="" />
                {!!messageCounter && (
                  <div className="absolute -top-[2px] -right-[2px] rounded-full font-bold bg-error text-[1rem] text-white flex justify-center items-center h-[20px] w-[20px]">
                    {messageCounter}
                  </div>
                )}
              </div>
            </div>
            <div className="">
              <TopAvatar isLoading={isLoading} />
            </div>
          </div>
        </header>
        <div
          className={clsx('flex flex-col flex-grow overflow-y-visible h-full relative', {
            'px-9 pb-8': !removedContentPadding,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

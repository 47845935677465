/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { UseMutationOptions } from 'react-query';
import { GraphQLTypes, InputType, ValueTypes } from '../zeus';

export type Option = {
  value: string | number | null;
  label: string;
};

export type Pagination = {
  currentPage?: number;
  lastPage?: number;
  nextPage?: number;
  perPage?: number;
  totalNodes?: number;
  totalPages?: number;
};

export type Node = Record<string, unknown>;

export type NodesPagination = {
  nodes?: Node[];
  pagination?: Pagination;
};

export type IRoutes = {
  path: string;
  exact: boolean;
  component: FC;
  private: boolean;
  redirectToDashboard?: boolean;
  pageTitle?: string;
};

export type PrivateRouteProps = {
  path: string;
  exact?: boolean;
  pageTitle?: string;
  routeComponent: FC;
};

export type IconProps = {
  className?: string;
};

export type ErrorForToast = {
  name: any;
  errors: any[];
};

export type MutOptions<
  O extends 'Mutation',
  TData extends ValueTypes[O],
  TResult = InputType<GraphQLTypes[O], TData>,
> = Omit<UseMutationOptions<TResult, Error, Record<string, any>>, 'mutationKey' | 'mutationFn'>;

export type GQLArgs<TRes = InputType<GraphQLTypes['Mutation'], ValueTypes['Mutation']>> =
  MutOptions<'Mutation', ValueTypes['Mutation'], TRes>;

export type DeepPartial<T> = T extends Record<string, any>
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type CreateMutationResponse<V extends keyof GraphQLTypes['Mutation']> = {
  [P in V]: DeepPartial<GraphQLTypes['Mutation'][V]>;
};

export type CreateQueryResponse<V extends keyof GraphQLTypes['Query']> = {
  [P in V]: DeepPartial<GraphQLTypes['Query'][V]>;
};

export type ParticipantProgramModule = GraphQLTypes['ProgramModuleType'];

export type PersonalSuccessType = GraphQLTypes['PersonalSuccessType'];
export type CurriculumType = GraphQLTypes['CurriculumType'];
export type CoachingCallType = GraphQLTypes['CoachingCallType'];
export type DietitianCallType = GraphQLTypes['DietitianCallType'];
export type WeightType = GraphQLTypes['WeightType'];
export type VideoType = GraphQLTypes['VideoType'];
export type RecipeType = GraphQLTypes['RecipeType'];
export type ActivityType = GraphQLTypes['ActivityType'];

export type PersonalSuccessProgressMetadataType =
  GraphQLTypes['PersonalSuccessProgressMetadataType'];
export type CoachingCallProgressMetadataType = GraphQLTypes['CoachingCallProgressMetadataType'];
export type CurriculumProgressMetadataType = GraphQLTypes['CurriculumProgressMetadataType'];
export type DietitianCallProgressMetadataType = GraphQLTypes['DietitianCallProgressMetadataType'];
export type WeightTypeProgressMetadataType = GraphQLTypes['WeightTypeProgressMetadataType'];
export type VideoTypeProgressMetadataType = GraphQLTypes['VideoTypeProgressMetadataType'];
export type RecipeTypeProgressMetadataType = GraphQLTypes['RecipeTypeProgressMetadataType'];
export type ActivityTypeProgressMetadataType = GraphQLTypes['ActivityTypeProgressMetadataType'];

export type ProgramModuleSectionType = GraphQLTypes['ProgramModuleSectionType'];
export type ProgressSectionDataType = GraphQLTypes['ProgressDataType'];

export enum SectionType {
  PersonalSuccessType = 'PersonalSuccessType',
  CurriculumType = 'CurriculumType',
  CoachingCallType = 'CoachingCallType',
  DietitianCallType = 'DietitianCallType',
  WeightType = 'WeightType',
  VideoType = 'VideoType',
  RecipeType = 'RecipeType',
  ActivityType = 'ActivityType',
}
export enum ProgressSectionType {
  PersonalSuccessProgressMetadataType = 'PersonalSuccessProgressMetadataType',
  CurriculumProgressMetadataType = 'CurriculumProgressMetadataType',
  CoachingCallProgressMetadataType = 'CoachingCallProgressMetadataType',
  DietitianCallProgressMetadataType = 'DietitianCallProgressMetadataType',
  WeightTypeProgressMetadataType = 'WeightTypeProgressMetadataType',
  VideoTypeProgressMetadataType = 'VideoTypeProgressMetadataType',
  RecipeTypeProgressMetadataType = 'RecipeTypeProgressMetadataType',
  ActivityTypeProgressMetadataType = 'ActivityTypeProgressMetadataType',
}

export type ModuleDescription = {
  name?: string;
  description?: string;
  current?: boolean;
  start?: string;
  end?: string;
};

export type ProgramModuleCleaner<T> = Omit<ProgramModuleSectionType, 'data'> & { data: T };
export type UserProgressCleaner<T> = Omit<ProgressSectionDataType, 'metadata'> & { metadata: T };

export type TransformModuleDataResult = {
  moduleDescription: ModuleDescription;
  personalSuccess: ProgramModuleCleaner<PersonalSuccessType>[];
  webinar: ProgramModuleCleaner<CoachingCallType>[];
  curriculum: ProgramModuleCleaner<CurriculumType>[];
  dietitian: ProgramModuleCleaner<DietitianCallType>[];
  weight: ProgramModuleCleaner<WeightType>[];
  video: ProgramModuleCleaner<VideoType>[];
  recipe: ProgramModuleCleaner<RecipeType>[];
  activity: ProgramModuleCleaner<ActivityType>[];
};
export type TransformProgressDataResult = {
  personalSuccess: UserProgressCleaner<PersonalSuccessProgressMetadataType>[];
  webinar: UserProgressCleaner<CoachingCallProgressMetadataType>[];
  curriculum: UserProgressCleaner<CurriculumProgressMetadataType>[];
  dietitian: UserProgressCleaner<DietitianCallProgressMetadataType>[];
  weight: UserProgressCleaner<WeightTypeProgressMetadataType>[];
  video: UserProgressCleaner<VideoTypeProgressMetadataType>[];
  recipe: UserProgressCleaner<RecipeTypeProgressMetadataType>[];
  activity: UserProgressCleaner<ActivityTypeProgressMetadataType>[];
};

export type ModuleSectionResult =
  | ModuleDescription
  | ProgramModuleCleaner<PersonalSuccessType>
  | ProgramModuleCleaner<CoachingCallType>
  | ProgramModuleCleaner<DietitianCallType>
  | ProgramModuleCleaner<WeightType>
  | ProgramModuleCleaner<VideoType>
  | ProgramModuleCleaner<RecipeType>
  | ProgramModuleCleaner<ActivityType>;

export type UserProgress =
  | UserProgressCleaner<PersonalSuccessProgressMetadataType>
  | UserProgressCleaner<CoachingCallProgressMetadataType>
  | UserProgressCleaner<CurriculumProgressMetadataType>
  | UserProgressCleaner<DietitianCallProgressMetadataType>
  | UserProgressCleaner<WeightTypeProgressMetadataType>
  | UserProgressCleaner<VideoTypeProgressMetadataType>
  | UserProgressCleaner<RecipeTypeProgressMetadataType>
  | UserProgressCleaner<ActivityTypeProgressMetadataType>;

import clsx from 'clsx';
import * as H from 'history';
import { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { MenuItem } from './MenuItem';
import { routesPath } from '../../constants/routes';
import { Tab } from './types';
import { DashboardSvg, GroupChatSvg, DeviceSvg } from '../Icons';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../context/auth/authContext';
type ValueOf<T> = T[keyof T];
type MenuKeys = ValueOf<typeof routesPath>;

type MenuType = Partial<Record<MenuKeys, Tab>>;

export const Menu = () => {
  const meData = useAuthState();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  location.pathname;
  const ENV = process.env.REACT_APP_ENV;

  const status = false;
  const listItemsConfig: MenuType = {
    [routesPath.dashboard]: {
      id: 0,
      icon: DashboardSvg,
      text: t('dashboard'),
      onClick: () => {
        history.push(routesPath.dashboard);
      },
    },
    [routesPath.groupChat]: {
      id: 1,
      icon: GroupChatSvg,
      text: t('group-chat'),
      onClick: () => {
        history.push(routesPath.groupChat);
      },
    },
  };
  if (meData.userData.soleraId === null) {
    listItemsConfig[routesPath.device] = {
      id: 2,
      icon: DeviceSvg,
      text: t('device'),
      onClick: () => {
        history.push(routesPath.device);
      },
      disabled: status,
    };
  } else {
    listItemsConfig[routesPath.add_device] = {
      id: 2,
      icon: DeviceSvg,
      text: t('device_api'),
      onClick: () => {
        history.push(routesPath.add_device);
      },
      disabled: status,
    };
  }

  const [currentTab, setCurrentTab] = useState<Tab>(
    (listItemsConfig[location.pathname as MenuKeys] as Tab) ||
      (listItemsConfig[routesPath.dashboard as MenuKeys] as Tab),
  );

  useEffect(() => {
    if (listItemsConfig[location.pathname as MenuKeys]) {
      setCurrentTab(listItemsConfig[location.pathname as MenuKeys] as Tab);
    } else {
      setCurrentTab(listItemsConfig[routesPath.dashboard as MenuKeys] as Tab);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  const listItems: Tab[] = Object.values<Tab>(listItemsConfig);
  return (
    <div className="menu flex justify-center flex-grow">
      <div className="w-full h-full flex flex-col">
        {Object.values(listItems).map((tab: Tab) => {
          const { id, text } = tab;
          const isActive = currentTab?.text === text;

          return (
            <Fragment key={id}>
              <MenuItem
                key={id}
                active={isActive}
                disabled={tab.disabled}
                setCurrentTab={() => {
                  setCurrentTab(tab);
                  tab?.onClick?.();
                }}
                icon={<tab.icon isActive={isActive} />}
                title={tab.text}
              />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

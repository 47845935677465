import {
  createContext,
  FC,
  Reducer,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

import { Action, ChatActionsContextType, ChatStateContextType } from './types';
import { dispatchActions, initState, chatContextReducer } from './reducer';
import { routesPath } from '../../constants/routes';
import { Client, ConnectionState, Conversation } from '@twilio/conversations';
import { useQueryMe } from '../../graphql/query/getMe';
import { useQueryChatToken } from '../../graphql/query/getChatToken';
import { useLocation } from 'react-router-dom';
import { CHAT_API_BASE_URL } from '../../env';

const ChatStateContext = createContext<ChatStateContextType | undefined>(undefined);
const ChatActionsContext = createContext<ChatActionsContextType | undefined>(undefined);

ChatStateContext.displayName = 'ChatStateContext';
interface GetChatToken {
  chatIdentity: string;
}
export const ChatProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<ChatStateContextType, Action>>(
    chatContextReducer,
    initState,
  );
  const { pathname } = useLocation();

  const { me } = useQueryMe();
  const [chatTokenData, setChatTokenData] = useState({ token: '' });
  async function performGetChatToken(input: GetChatToken) {
    try {
      const url = `${CHAT_API_BASE_URL}` + 'graphql';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('idToken'),
        },
        body: JSON.stringify({
          query: `query GetChatToken ($chatIdentity: String!) {
              token(chatIdentity: $chatIdentity) {
                ... on TokenType {
                    token
                    chatIdentity
                }
                ... on ParticipantDneError {
                    message
                }
                ... on ParticipantNotInConversationError {
                    message
                }
              }
            }
        `,
          variables: input,
        }),
      }).then((response) => response.json());
      if (response) {
        setChatTokenData({ token: response.data.token.token });
      } else {
      }
      return response;
    } catch (error) {
      console.error('Error performing performGetChatToken operation:', error);
      return { error: 'Failed to performGetChatToken operation', details: error };
    }
  }
  useEffect(() => {
    if (me.chatIdentity) {
      performGetChatToken({ chatIdentity: me.chatIdentity });
    }
  }, [me.chatIdentity]);

  const { chatClient, selectedConversation, messageCounter } = state;

  const readAllMessages = useCallback(() => {
    if (selectedConversation) {
      selectedConversation.setAllMessagesRead().then(() => {
        dispatch(dispatchActions.setMessageCounter(0));
      });
    }
  }, [selectedConversation]);

  useEffect(() => {
    if (chatTokenData?.token) {
      dispatch(dispatchActions.setClient(new Client(chatTokenData?.token)));
      dispatch(dispatchActions.setIdentity(me.chatIdentity));
    }
  }, [chatTokenData?.token]);

  useEffect(() => {
    const { twilioConnection, conversationJoined, conversationLeft } = dispatchActions;
    const connectionStateChangedListener = (s: ConnectionState) => dispatch(twilioConnection(s));
    const conversationJoinedListener = (c: Conversation) => dispatch(conversationJoined(c));
    const conversationLeftListener = (c: Conversation) => dispatch(conversationLeft(c));

    chatClient?.on('connectionStateChanged', connectionStateChangedListener);
    chatClient?.on('conversationJoined', conversationJoinedListener);
    chatClient?.on('conversationLeft', conversationLeftListener);

    return () => {
      chatClient?.off('connectionStateChanged', connectionStateChangedListener);
      chatClient?.off('conversationJoined', conversationJoinedListener);
      chatClient?.off('conversationLeft', conversationLeftListener);
    };
  }, [chatClient]);

  useEffect(() => {
    const listener = () => {
      if (routesPath.groupChat !== pathname) {
        dispatch(dispatchActions.setMessageCounter(messageCounter + 1));
      } else {
        readAllMessages();
      }
    };
    selectedConversation?.on('messageAdded', listener);

    return () => {
      selectedConversation?.off('messageAdded', listener);
    };
  }, [selectedConversation, pathname, routesPath, messageCounter]);

  useEffect(() => {
    let currentCount = 0;
    const index = selectedConversation?.lastMessage?.index;
    const consumedIndex = selectedConversation?.lastReadMessageIndex;
    let nextMessagesCount = 0;
    if (index !== undefined && consumedIndex === null) {
      nextMessagesCount = index + 1;
    } else {
      nextMessagesCount = (index || 0) - (consumedIndex || 0);
    }
    if (routesPath.groupChat === pathname) return;

    currentCount = currentCount + nextMessagesCount;
    dispatch(dispatchActions.setMessageCounter(currentCount < 0 ? 0 : currentCount));
  }, [selectedConversation, pathname]);

  const actions = {
    readAllMessages,
    chatLogout: () => {
      chatClient?.shutdown();
      dispatch(dispatchActions.cleanup());
    },
  };

  return (
    <ChatStateContext.Provider value={state}>
      <ChatActionsContext.Provider value={actions}>{children}</ChatActionsContext.Provider>
    </ChatStateContext.Provider>
  );
};

export function useChatState() {
  const context = useContext(ChatStateContext);
  if (context === undefined) {
    throw new Error('useChatState must be used within a AuthProvider');
  }

  return context;
}

export function useChatActions() {
  const context = useContext(ChatActionsContext);
  if (context === undefined) {
    throw new Error('useChatActions must be used within a AuthProvider');
  }
  return context;
}

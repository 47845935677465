import { FC } from 'react';
import clsx from 'clsx';
import { format } from 'date-fns';

type ChatDateLineProps = {
  datetime: Date;
};

export const ChatDateLine: FC<ChatDateLineProps> = ({ datetime }) => {
  return (
    <div
      data-testid="ChatDateLine"
      className={clsx('w-full flex justify-between items-center my-[1.2rem]')}
    >
      <div className="bg-main opacity-10 w-full h-px"></div>
      <div className="mx-[1.6rem] min-w-[16.8rem] text-1220 text-center text-manatee">
        {format(datetime, 'EEEE, LLLL do')}
      </div>
      <div className="bg-main opacity-10 w-full h-px"></div>
    </div>
  );
};

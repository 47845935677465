import { useMutation } from '../../hooks/useMutation';
import { ADD_DEVICE } from './constants';
import { $ } from '../../zeus';
import { GQLArgs } from '../../types';

type MutationResponse = Record<'addDevice', boolean>;

export const useMutationaddWithing = (args?: GQLArgs<MutationResponse>) => {
  return useMutation(
    [ADD_DEVICE],
    {
      addDevice: [
        {
          deviceId: $`deviceId`,
          device: $`device`,
        },
        true,
      ],
    },
    {
      operationName: ADD_DEVICE,
    },
    args,
  );
};

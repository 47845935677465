import { URL_REGEXP } from '../constants/regexp';

export function wrapByLink(text: string, className?: string) {
  const matches = text.match(URL_REGEXP);
  if (!matches?.length) return text;

  const regExp = /(?:http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(?:\/\S*)?/g;
  const otherText = text.split(regExp);
  // const otherText = text.split(URL_REGEXP);
  const wrapped = matches.map((match) => {
    return (
      <a className={className} href={match.trim()} rel="noreferrer" target="_blank">
        {match.trim()}
      </a>
    );
  });

  const result = [];

  for (let i = 0; i < otherText.length; i++) {
    result.push(otherText[i]);
    if (wrapped[i]) result.push(wrapped[i]);
  }
  return result;
}

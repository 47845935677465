import { useQuery } from '../../hooks/useQuery';
import { GET_PARTICIPANT_PROGRAM_MODULES } from './constants';
import { $, GraphQLTypes, InputType, Selector } from '../../zeus';
import _ from 'lodash';

type QueryParticipantProgramModulesVariables = {
  participantId?: string;
};

const common = {
  __typename: true,
  type: true,
  url: true,
};

const ProgramModuleSectionType = Selector('ProgramModuleSectionType')({
  __typename: true,
  id: true,
  title: true,
  description: true,
  data: {
    '...on CoachingCallType': {
      recordingUrl: true,
      startedAt: true,
      ...common,
    },
    '...on CurriculumType': {
      signedUrl: true,
      // isIntro: true,
      ...common,
    },
    '...on DietitianCallType': {
      recordingUrl: true,
      startedAt: true,
      ...common,
    },
    '...on WeightType': {
      __typename: true,
      type: true,
    },
    '...on VideoType': {
      __typename: true,
      type: true,
      signedUrl: true,
      // isIntro: true,
    },
    '...on RecipeType': {
      signedUrl: true,
      ...common,
    },
    '...on PersonalSuccessType': {
      __typename: true,
      type: true,
      formId: true,
    },
    '...on ActivityType': {
      __typename: true,
    },
  },
  type: true,
});

export type ModuleSectionDataType = InputType<
  GraphQLTypes['ProgramModuleSectionType'],
  typeof ProgramModuleSectionType
>;

const ProgramModuleType = Selector('ProgramModuleType')({
  __typename: true,
  id: true,
  createdAt: true,
  updatedAt: true,
  shortTitle: true,
  title: true,
  startedAt: true,
  endedAt: true,
  description: true,
  current: true,
  sections: ProgramModuleSectionType,
});

export type ProgramModuleType = InputType<
  GraphQLTypes['ProgramModuleType'],
  typeof ProgramModuleType
>;

export const useQueryParticipantProgramModules = (
  variables?: QueryParticipantProgramModulesVariables,
) => {
  const { isFetching, data } = useQuery(
    [GET_PARTICIPANT_PROGRAM_MODULES],
    {
      getParticipantProgramModules: [
        {
          participantId: $`participantId`,
        },
        ProgramModuleType,
      ],
    },
    { operationName: GET_PARTICIPANT_PROGRAM_MODULES, variables },
    { enabled: !!variables?.participantId },
  );
  const participantProgramModules = data?.getParticipantProgramModules || [];
  const indexOfActiveModule = participantProgramModules.findIndex((module) => module.current);

  let modules = participantProgramModules;

  if (indexOfActiveModule !== -1) {
    modules = participantProgramModules.slice(0, indexOfActiveModule + 1);
  }

  return {
    participantProgramModulesLoading: isFetching,
    isLoading: isFetching,
    participantProgramModules: modules.sort((a, b) => {
      if (a.startedAt && b.startedAt) {
        return new Date(a.startedAt).getTime() - new Date(b.startedAt).getTime();
      }

      return 0;
    }),
    data: data?.getParticipantProgramModules || [],
  };
};

import { useState, useEffect } from 'react';

type Size = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';

type ScreenViewProps = {
  isDesktop?: boolean;
  size?: Size;
  width?: number;
};

const scale = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
  '3xl': 1600,
};

const getWindowsWidth = (s: Size, w?: number) => {
  if (w) return w;
  return scale[s] || scale.lg;
};

export const useScreenView = (props?: ScreenViewProps) => {
  const { isDesktop, width, size = 'lg' } = props || {};
  const [isVisible, setIsVisible] = useState(false);
  const val = getWindowsWidth(size, width);

  useEffect(() => {
    const fn = () => {
      const isVisible = isDesktop ? window.innerWidth > val : window.innerWidth <= val;
      setIsVisible(isVisible);
    };
    window.addEventListener('resize', fn);
    fn();
    return () => document.removeEventListener('resize', fn);
  }, [size, width, isDesktop, val]);

  return isVisible;
};

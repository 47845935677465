import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyDict } from './Rpm';
import Withings from '../../assets/Withings.svg';
import { RpmSkeleton } from './Rpm.skeleton';
import { ReactComponent as GreenCheckmark } from '../../assets/green-checkmark-devices.svg';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { ReactComponent as TooltipIcon } from '../../assets/tooltip.svg';
import Tooltip from '../../components/Tooltip/Tooltip';
import { Modal } from '../../components/Modal/Modal';
import { Title } from '../../components/HeaderTitle/HeaderTitle';
import { Button } from '../../components/Button/Button';
import { useQueryMe } from '../../graphql/query/getMe';
import { API_BASE_URL } from '../../env';
import { toast } from 'react-toastify';

type RpmPageViewProps = {
  isLoading: boolean;
  devices: any;
  devicesLoading: EmptyDict[];
  isWithingsConnected: boolean;
  setIsWithingsConnected: any;
};

export const RpmPageView: FC<RpmPageViewProps> = ({
  isLoading,
  devices,
  isWithingsConnected,
  setIsWithingsConnected,
}) => {
  const { me, refetchMe } = useQueryMe();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation();

  // change connection manually for UI testing assignments only:
  // const isWithingsConnected = true;

  const renderCurrentSelection = (param = '') => {
    switch (param) {
      case '':
      case 'withings':
        return <img src={Withings} alt="" width="44px" height="44px" />;
      // case 'fitbit':
      //   return <img src={Fitbit} alt="" width="44px" height="44px" />;
      // case 'oruraring':
      //   return <img src={Oura} alt="" width="44px" height="44px" />;
      // case 'dexcom':
      //   return <img src={Dexcom} alt="" width="44px" height="44px" />;
      default:
        return null;
    }
  };

  async function disconnectDevice() {
    try {
      const response = await fetch(`${API_BASE_URL}api/devices/disconnect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-key': 'Bearer ' + localStorage.getItem('idToken'),
        },
        body: JSON.stringify({ email: me.email, type_device: 'withings' }),
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.disconnected) {
          refetchMe();
          setIsWithingsConnected(false);
          toast.success('Disconnected');
          window.location.reload();
        } else {
          // Handle the case when disconnection fails
          toast.error(responseData.detail);
        }
      } else {
        // Handle the case when the server returns an error response
        toast.error('Failed to disconnect: ' + response.statusText);
      }
    } catch (error) {
      // Handle network errors or other exceptions
      toast.error('Failed to disconnect: ' + error);
    }

    setIsModalOpen(false);
  }
  const disconnect = () => {
    disconnectDevice();
  };

  const onClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    refetchMe();
  }, [refetchMe, isWithingsConnected]);

  return (
    <div className="xl:px-9 xl:pb-8 h-full flex flex-col grow">
      <Title>
        <div className="flex">
          <div className="mr-4">{t('device')}</div>
        </div>
      </Title>
      <>
        <div className="flex relative flex-col xl:flex-row h-full">
          <section className="w-full">
            <div className="flex w-full">
              <div className="flex flex-col p-10 w-full gap-3.5">
                {isLoading && <RpmSkeleton />}

                {!isLoading &&
                  devices.map((item: EmptyDict) => (
                    <div
                      key={item.link}
                      className="rounded-[1rem] justify-between flex flex-col sm:flex-row items-betwen gap-4 bg-white text-gray-800 w-full border pt-[1rem] pb-[1rem] px-4 max-w-7xl items-center"
                    >
                      <div className="flex justify-between items-betwen w-full">
                        <div className="flex">
                          {/* Icon */}
                          <div className="mx-auto w-24 my-auto">
                            {renderCurrentSelection(item.name)}
                          </div>
                          {/* end Icon */}

                          <div className="flex flex-col">
                            {/* Title */}
                            <div className="font-semibold text-[16px] text-gray-800 my-auto items-center ml-3 mb-1.5 sm:mb-auto">
                              {item.title}
                            </div>
                            {/* end Title */}

                            {/* Connected badge mobile */}
                            <div className="block md:hidden mb-2">
                              {isWithingsConnected && (
                                <span className="px-3 rounded-lg py-1 bg-green-200 flex items-center text-md">
                                  <GreenCheckmark className="w-7 mr-3" /> Connected
                                </span>
                              )}

                              {!isWithingsConnected && (
                                <span className="px-3 rounded-lg py-1 bg-[#E5E7EB] flex items-center text-md">
                                  ✖️ Not connected
                                </span>
                              )}
                            </div>
                            {/* end Connected badge mobile */}
                          </div>
                        </div>

                        <div className="flex">
                          {/* Tooltip */}
                          <div className="flex items-center my-auto">
                            <Tooltip
                              position="bottom"
                              content={
                                <div className="flex flex-col w-80">
                                  <span className="mb-3 text-[13px]">Where is my data?</span>
                                  <span>
                                    Make sure to connect with the right Withings user to avoid data
                                    issues. Consider reconnecting if the wrong user has already been
                                    connected.
                                    <br />
                                    <br />
                                    Follow the
                                    <a
                                      href="https://cibahealth.com/help-center/how-to-connect-your-weight-scale-to-ciba-health/"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="font-semibold mx-2 underline"
                                    >
                                      How To
                                    </a>
                                    guide to know more about Withings connection Dos and Don’ts.
                                  </span>
                                </div>
                              }
                            >
                              <TooltipIcon />
                            </Tooltip>
                          </div>
                          {/* end Tooltip */}

                          {/* Connected badge */}
                          <div className="hidden md:block my-auto ml-4">
                            {isWithingsConnected && (
                              <span className="px-3 rounded-lg py-1 bg-green-200 flex items-center text-md">
                                <GreenCheckmark className="w-7 mr-3" /> Connected
                              </span>
                            )}

                            {!isWithingsConnected && (
                              <span className="px-3 rounded-lg py-1 bg-[#E5E7EB] flex items-center text-md">
                                ✖️ Not connected
                              </span>
                            )}
                          </div>
                          {/* end Connected badge */}
                        </div>
                      </div>

                      {/* Button Connect */}
                      <div className="w-full sm:w-auto">
                        {isWithingsConnected && (
                          <button
                            onClick={() => {
                              setIsModalOpen(true);
                            }}
                            className="border border-error text-error w-full rounded-[2.4rem] text-1313 px-7 py-5 "
                          >
                            Disconnect
                          </button>
                        )}
                        {!isWithingsConnected && (
                          <button
                            onClick={() => {
                              !isWithingsConnected && window.open(item.link, '_blank');
                            }}
                            className="bg-main text-white w-full rounded-[2.4rem] text-1313 px-7 py-5 "
                          >
                            Connect
                          </button>
                        )}
                      </div>
                      {/* end Button Connect */}
                    </div>
                  ))}
              </div>
            </div>
          </section>
        </div>
        {isModalOpen && (
          <Modal onCancel={onClose}>
            <div className="flex flex-col justify-between w-[30rem] sm:w-[42rem] ">
              <div className="flex flex-col justify-between">
                <div className="flex justify-between font-semibold text-1622">
                  <span>Disconnect your device</span>
                  <div onClick={onClose} className="close cursor-pointer self-end">
                    <CloseIcon />
                  </div>
                </div>

                <div className="text-1421 text-independence w-full flex flex-col mt-8">
                  Are you sure you want to disconnect?
                </div>
              </div>
              <div>
                <div className="bg-galery h-px mb-12"></div>
                <div className="flex justify-end">
                  <div className="w-40 mr-4">
                    <Button outlined onClick={onClose}>
                      No, cancel
                    </Button>
                  </div>
                  <div className="w-56">
                    <Button onClick={disconnect}>Yes, disconnect</Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </>
    </div>
  );
};

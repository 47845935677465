import { FC } from 'react';

export const YourProgressSkeleton: FC = () => {
  const lines = [1, 2, 3].map((i) => (
    <div key={'YourProgressSkeleton' + i} className="flex items-center justify-between">
      <div className="mt-[2.1rem] flex items-center w-full">
        <div className="bg-catskillWhite rounded-[.8rem] min-h-[3rem] min-w-[3rem] mr-[1rem]"></div>
        <div className="bg-catskillWhite rounded-[.8rem] h-[3rem] w-full md:w-[24.7rem] "></div>
      </div>
    </div>
  ));
  return (
    <div className="h-[18rem] mt-[2.4rem] animate-pulse mb-16">
      <div className="bg-catskillWhite rounded-[.8rem] h-[3rem] w-full md:w-[28.8rem]"></div>
      {lines}
    </div>
  );
};

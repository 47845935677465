/* eslint-disable */

import { AllTypesProps, ReturnTypes } from './const';
type ZEUS_INTERFACES = never;
type ZEUS_UNIONS =
  | GraphQLTypes['PersonalSuccessTypeCurriculumTypeCoachingCallTypeDietitianCallType']
  | GraphQLTypes['DietitianCallProgressMetadataTypeCoachingCallProgressMetadataTypeCurriculumProgressMetadataTypePersonalSuccessProgressMetadataTypeWeightTypeProgressMetadataTypeFoodTypeProgressMetadataTypeVideoTypeProgressMetadataTypeRecipeTypeProgressMetadataType']
  | GraphQLTypes['ParticipantTypeParticipantDuplicatedError']
  | GraphQLTypes['ParticipantTypeParticipantDneError']
  | GraphQLTypes['VerifyChangePasswordTokenTypeInvalidVerifyChangePasswordTokenVerifyChangePasswordTokenExpired']
  | GraphQLTypes['HangoutUrlTypeInvalidTimezoneInvalidDataSend']
  | GraphQLTypes['ModuleProgressTypeDoesNotBelongToCourse']
  | GraphQLTypes['ProgramGroupCourseTypeProgramGroupCourseProgramGroupCourseFile']
  | GraphQLTypes['ProgramGroupTypeParticipantAlreadyInGroup'];

export type ValueTypes = {
  ['Query']: AliasType<{
    getAdmins?: ValueTypes['ProviderType'];
    getProviders?: [{ ids?: ValueTypes['UUID'][] }, ValueTypes['ProviderType']];
    getCompanies?: [
      { sort?: ValueTypes['CompaniesSortType'] | null; search?: string | null },
      ValueTypes['CompanyType'],
    ];
    getParticipant?: [{ participantId: ValueTypes['UUID'] }, ValueTypes['ParticipantType']];
    getParticipants?: [
      { page: number; perPage: number; filters?: ValueTypes['ParticipantsFilterType'] | null },
      ValueTypes['ParticipantTypeConnection'],
    ];
    me?: ValueTypes['ParticipantType'];
    getTimeZones?: ValueTypes['TimeZonesType'];
    getHangLink?: ValueTypes['HangoutUrl'];
    getParticipantProgramModules?: [
      { participantId: ValueTypes['UUID'] },
      ValueTypes['ProgramModuleType'],
    ];
    getParticipantProgramCourses?: [
      { participantId: ValueTypes['UUID'] },
      ValueTypes['ProgramCourseType'],
    ];
    getModuleProgress?: [
      { programModuleId: ValueTypes['UUID']; participantId: ValueTypes['UUID'] },
      ValueTypes['ModuleProgressType'],
    ];
    getSectionMaterialsFile?: [
      { programModuleId: ValueTypes['UUID']; sectionId: ValueTypes['UUID'] },
      boolean,
    ];
    getProgramCourses?: [
      { page: number; perPage: number },
      ValueTypes['ProgramCourseTypeConnection'],
    ];
    getProgramCourseGroups?: [
      { programCourseId: ValueTypes['UUID']; page: number; perPage: number },
      ValueTypes['ProgramGroupTypeConnection'],
    ];
    getGroupModules?: [{ programGroupId: ValueTypes['UUID'] }, ValueTypes['ProgramModuleType']];
    getProgramModule?: [{ moduleId: ValueTypes['UUID'] }, ValueTypes['ProgramModuleType']];
    __typename?: boolean;
  }>;
  ['ProviderType']: AliasType<{
    id?: boolean;
    fullName?: boolean;
    firstName?: boolean;
    lastName?: boolean;
    email?: boolean;
    chatIdentity?: boolean;
    avatarUrl?: boolean;
    isAdmin?: boolean;
    __typename?: boolean;
  }>;
  ['UUID']: unknown;
  ['CompanyType']: AliasType<{
    createdAt?: boolean;
    updatedAt?: boolean;
    id?: boolean;
    name?: boolean;
    __typename?: boolean;
  }>;
  /** Date with time (isoformat) */
  ['DateTime']: unknown;
  ['CompaniesSortType']: {
    orderBy: ValueTypes['CompanyOrderByEnum'];
    direction: ValueTypes['OrderDirection'];
  };
  ['CompanyOrderByEnum']: CompanyOrderByEnum;
  ['OrderDirection']: OrderDirection;
  ['ParticipantType']: AliasType<{
    chatIdentity?: boolean;
    createdAt?: boolean;
    updatedAt?: boolean;
    id?: boolean;
    email?: boolean;
    firstName?: boolean;
    lastName?: boolean;
    groupId?: boolean;
    memberId?: boolean;
    status?: boolean;
    cognitoSub?: boolean;
    medicalRecord?: boolean;
    isTest?: boolean;
    lastReset?: boolean;
    headsUpId?: boolean;
    headsUpToken?: boolean;
    withingId?: boolean;
    ouraId?: boolean;
    fitbitId?: boolean;
    soleraId?: boolean;
    soleraKey?: boolean;
    soleraProgramId?: boolean;
    soleraEnrollmentId?: boolean;
    programGroupId?: boolean;
    __typename?: boolean;
  }>;
  ['ParticipantTypeConnection']: AliasType<{
    pageInfo?: ValueTypes['PageInfo'];
    items?: ValueTypes['ParticipantType'];
    __typename?: boolean;
  }>;
  ['PageInfo']: AliasType<{
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
    currentPage?: boolean;
    perPage?: boolean;
    lastPage?: boolean;
    total?: boolean;
    __typename?: boolean;
  }>;
  ['ParticipantsFilterType']: {
    ids?: ValueTypes['UUID'][];
    emails?: string[];
    groupId?: ValueTypes['UUID'] | null;
  };
  ['TimeZonesType']: AliasType<{
    id?: boolean;
    label?: boolean;
    __typename?: boolean;
  }>;
  ['HangoutUrl']: AliasType<{
    url?: boolean;
    __typename?: boolean;
  }>;
  ['ProgramModuleType']: AliasType<{
    sections?: ValueTypes['ProgramModuleSectionType'];
    createdAt?: boolean;
    updatedAt?: boolean;
    id?: boolean;
    shortTitle?: boolean;
    title?: boolean;
    startedAt?: boolean;
    endedAt?: boolean;
    description?: boolean;
    current?: boolean;
    __typename?: boolean;
  }>;
  ['ProgramModuleSectionType']: AliasType<{
    id?: boolean;
    title?: boolean;
    type?: boolean;
    description?: boolean;
    metadata?: ValueTypes['PersonalSuccessTypeCurriculumTypeCoachingCallTypeDietitianCallType'];
    data?: ValueTypes['PersonalSuccessTypeCurriculumTypeCoachingCallTypeDietitianCallType'];
    __typename?: boolean;
  }>;
  ['ModuleDataTypes']: ModuleDataTypes;
  ['PersonalSuccessTypeCurriculumTypeCoachingCallTypeDietitianCallType']: AliasType<{
    ['...on PersonalSuccessType']: ValueTypes['PersonalSuccessType'];
    ['...on CurriculumType']: ValueTypes['CurriculumType'];
    ['...on CoachingCallType']: ValueTypes['CoachingCallType'];
    ['...on DietitianCallType']: ValueTypes['DietitianCallType'];
    __typename?: boolean;
  }>;
  ['PersonalSuccessType']: AliasType<{
    formId?: boolean;
    type?: boolean;
    signedUrl?: boolean;
    __typename?: boolean;
  }>;
  ['CurriculumType']: AliasType<{
    url?: boolean;
    type?: boolean;
    signedUrl?: boolean;
    __typename?: boolean;
  }>;
  ['CoachingCallType']: AliasType<{
    url?: boolean;
    startedAt?: boolean;
    recordingUrl?: boolean;
    type?: boolean;
    __typename?: boolean;
  }>;
  ['DietitianCallType']: AliasType<{
    url?: boolean;
    startedAt?: boolean;
    recordingUrl?: boolean;
    type?: boolean;
    __typename?: boolean;
  }>;
  ['ProgramCourseType']: AliasType<{
    program?: ValueTypes['ProgramType'];
    createdAt?: boolean;
    updatedAt?: boolean;
    id?: boolean;
    startedAt?: boolean;
    __typename?: boolean;
  }>;
  ['ProgramType']: AliasType<{
    createdAt?: boolean;
    updatedAt?: boolean;
    id?: boolean;
    title?: boolean;
    __typename?: boolean;
  }>;
  ['ModuleProgressType']: AliasType<{
    id?: boolean;
    completed?: boolean;
    sections?: ValueTypes['ProgressDataType'];
    __typename?: boolean;
  }>;
  ['ProgressDataType']: AliasType<{
    sectionId?: boolean;
    sectionType?: boolean;
    metadata?: ValueTypes['DietitianCallProgressMetadataTypeCoachingCallProgressMetadataTypeCurriculumProgressMetadataTypePersonalSuccessProgressMetadataTypeWeightTypeProgressMetadataTypeFoodTypeProgressMetadataTypeVideoTypeProgressMetadataTypeRecipeTypeProgressMetadataType'];
    completed?: boolean;
    __typename?: boolean;
  }>;
  ['DietitianCallProgressMetadataTypeCoachingCallProgressMetadataTypeCurriculumProgressMetadataTypePersonalSuccessProgressMetadataTypeWeightTypeProgressMetadataTypeFoodTypeProgressMetadataTypeVideoTypeProgressMetadataTypeRecipeTypeProgressMetadataType']: AliasType<{
    ['...on DietitianCallProgressMetadataType']: ValueTypes['DietitianCallProgressMetadataType'];
    ['...on CoachingCallProgressMetadataType']: ValueTypes['CoachingCallProgressMetadataType'];
    ['...on CurriculumProgressMetadataType']: ValueTypes['CurriculumProgressMetadataType'];
    ['...on PersonalSuccessProgressMetadataType']: ValueTypes['PersonalSuccessProgressMetadataType'];
    ['...on WeightTypeProgressMetadataType']: ValueTypes['WeightTypeProgressMetadataType'];
    ['...on FoodTypeProgressMetadataType']: ValueTypes['FoodTypeProgressMetadataType'];
    ['...on VideoTypeProgressMetadataType']: ValueTypes['VideoTypeProgressMetadataType'];
    ['...on RecipeTypeProgressMetadataType']: ValueTypes['RecipeTypeProgressMetadataType'];
    ['...on ActivityTypeProgressMetadataType']: ValueTypes['ActivityTypeProgressMetadataType'];
    __typename?: boolean;
  }>;
  ['DietitianCallProgressMetadataType']: AliasType<{
    startDate?: boolean;
    endDate?: boolean;
    __typename?: boolean;
  }>;
  ['CoachingCallProgressMetadataType']: AliasType<{
    startDate?: boolean;
    endDate?: boolean;
    __typename?: boolean;
  }>;
  ['CurriculumProgressMetadataType']: AliasType<{
    startDate?: boolean;
    endDate?: boolean;
    __typename?: boolean;
  }>;
  ['PersonalSuccessProgressMetadataType']: AliasType<{
    startDate?: boolean;
    endDate?: boolean;
    responseId?: boolean;
    __typename?: boolean;
  }>;
  ['WeightTypeProgressMetadataType']: AliasType<{
    startDate?: boolean;
    endDate?: boolean;
    weight?: boolean;
    responseId?: boolean;
    __typename?: boolean;
  }>;
  ['VideoTypeProgressMetadataType']: AliasType<{
    startDate?: boolean;
    endDate?: boolean;
    weight?: boolean;
    responseId?: boolean;
    __typename?: boolean;
  }>;
  ['RecipeTypeProgressMetadataType']: AliasType<{
    startDate?: boolean;
    endDate?: boolean;
    weight?: boolean;
    responseId?: boolean;
    __typename?: boolean;
  }>;
  ['ActivityTypeProgressMetadataType']: AliasType<{
    startDate?: boolean;
    endDate?: boolean;
    value?: string;
    responseId?: boolean;
    __typename?: boolean;
  }>;
  ['FoodTypeProgressMetadataType']: AliasType<{
    startDate?: boolean;
    endDate?: boolean;
    __typename?: boolean;
  }>;
  ['ProgramCourseTypeConnection']: AliasType<{
    pageInfo?: ValueTypes['PageInfo'];
    items?: ValueTypes['ProgramCourseType'];
    __typename?: boolean;
  }>;
  ['ProgramGroupTypeConnection']: AliasType<{
    pageInfo?: ValueTypes['PageInfo'];
    items?: ValueTypes['ProgramGroupType'];
    __typename?: boolean;
  }>;
  ['ProgramGroupType']: AliasType<{
    participants?: ValueTypes['ParticipantType'];
    createdAt?: boolean;
    updatedAt?: boolean;
    id?: boolean;
    name?: boolean;
    startedAt?: boolean;
    limit?: boolean;
    __typename?: boolean;
  }>;
  ['Mutation']: AliasType<{
    signUpParticipant?: [
      { data: ValueTypes['SignUpInput'] },
      ValueTypes['ParticipantTypeParticipantDuplicatedError'],
    ];
    updateStatus?: [
      { participantId: ValueTypes['UUID']; status: ValueTypes['ParticipantStatus'] },
      ValueTypes['ParticipantTypeParticipantDneError'],
    ];
    resendConfirmationLink?: [{ email: string }, boolean];
    resendConfirmationLinkByToken?: [{ token: string }, boolean];
    verifyChangePassword?: [
      { token: string },
      ValueTypes['VerifyChangePasswordTokenTypeInvalidVerifyChangePasswordTokenVerifyChangePasswordTokenExpired'],
    ];
    sendHandOut?: [
      { data: ValueTypes['HangoutsType'] },
      ValueTypes['HangoutUrlTypeInvalidTimezoneInvalidDataSend'],
    ];
    addDevice?: [{ device: string; deviceId: ValueTypes['UUID'] }, boolean];
    makeProgress?: [
      {
        programModuleId: ValueTypes['UUID'];
        sectionId: ValueTypes['UUID'];
        data?: ValueTypes['PersonalSuccessInput'] | null;
      },
      ValueTypes['ModuleProgressTypeDoesNotBelongToCourse'],
    ];
    addChatActivity?: [{ participantId: string }, boolean];
    landParticipant?: [{ lookUpKey: string; email?: string }, { title: boolean }];
    addProgramModule?: [
      { programCourseId: ValueTypes['UUID']; data: ValueTypes['ProgramModuleInput'] },
      ValueTypes['ProgramModuleType'],
    ];
    addProgramModules?: [
      { programCourseId: ValueTypes['UUID']; programGroupId: ValueTypes['UUID'] },
      ValueTypes['ProgramGroupCourseTypeProgramGroupCourseProgramGroupCourseFile'],
    ];
    removeProgramModule?: [{ moduleId: ValueTypes['UUID'] }, boolean];
    assignProgramModuleToGroup?: [
      { groupId: ValueTypes['UUID']; moduleId: ValueTypes['UUID'] },
      ValueTypes['ProgramModuleType'],
    ];
    addProgramCourseGroup?: [
      {
        programCourseId: ValueTypes['UUID'];
        name: string;
        startedAt: ValueTypes['Date'];
        limit: number;
      },
      ValueTypes['ProgramGroupType'],
    ];
    addParticipantToGroup?: [
      { programGroupId: ValueTypes['UUID']; participantId: ValueTypes['UUID'] },
      ValueTypes['ProgramGroupTypeParticipantAlreadyInGroup'],
    ];
    removeParticipantFromGroup?: [
      { programGroupId: ValueTypes['UUID']; participantId: ValueTypes['UUID'] },
      ValueTypes['ProgramGroupType'],
    ];
    updateProgramModule?: [
      { moduleId: ValueTypes['UUID']; data: ValueTypes['ProgramModuleUpdateInput'] },
      ValueTypes['ProgramModuleType'],
    ];
    updateProgramModuleSection?: [
      { sectionId: ValueTypes['UUID']; data: ValueTypes['ProgramModuleSectionInput'] },
      ValueTypes['ProgramModuleSectionType'],
    ];
    deleteProgramModuleSection?: [{ sectionId: ValueTypes['UUID'] }, boolean];
    addProgramModuleSection?: [
      { moduleId: ValueTypes['UUID']; data: ValueTypes['ProgramModuleSectionInput'] },
      ValueTypes['ProgramModuleSectionType'],
    ];
    removeAdminFromGroupChat?: [
      { groupId: ValueTypes['UUID']; adminId: ValueTypes['UUID'] },
      boolean,
    ];
    addAdminToGroupChat?: [
      { groupId: ValueTypes['UUID']; adminId: ValueTypes['UUID']; adminChatIdentity: string },
      boolean,
    ];
    removeMemberFromGroupChat?: [
      { groupId: ValueTypes['UUID']; memberId: ValueTypes['UUID'] },
      boolean,
    ];
    addMemberToGroupChat?: [
      {
        groupId: ValueTypes['UUID'];
        memberId: ValueTypes['UUID'];
        memberChatIdentity: string;
        memberType: ValueTypes['MemberTypes'];
      },
      boolean,
    ];
    __typename?: boolean;
  }>;
  ['ParticipantTypeParticipantDuplicatedError']: AliasType<{
    ['...on ParticipantType']: ValueTypes['ParticipantType'];
    ['...on ParticipantDuplicatedError']: ValueTypes['ParticipantDuplicatedError'];
    __typename?: boolean;
  }>;
  ['ParticipantDuplicatedError']: AliasType<{
    message?: boolean;
    __typename?: boolean;
  }>;
  ['SignUpInput']: {
    email: string;
    firstName: string;
    lastName: string;
    companyId: ValueTypes['UUID'];
    groupId: string;
    memberId: string;
  };
  ['ParticipantTypeParticipantDneError']: AliasType<{
    ['...on ParticipantType']: ValueTypes['ParticipantType'];
    ['...on ParticipantDneError']: ValueTypes['ParticipantDneError'];
    __typename?: boolean;
  }>;
  ['ParticipantDneError']: AliasType<{
    message?: boolean;
    __typename?: boolean;
  }>;
  ['ParticipantStatus']: ParticipantStatus;
  ['VerifyChangePasswordTokenTypeInvalidVerifyChangePasswordTokenVerifyChangePasswordTokenExpired']: AliasType<{
    ['...on VerifyChangePasswordTokenType']: ValueTypes['VerifyChangePasswordTokenType'];
    ['...on InvalidVerifyChangePasswordToken']: ValueTypes['InvalidVerifyChangePasswordToken'];
    ['...on VerifyChangePasswordTokenExpired']: ValueTypes['VerifyChangePasswordTokenExpired'];
    __typename?: boolean;
  }>;
  ['VerifyChangePasswordTokenType']: AliasType<{
    email?: boolean;
    tmpPassword?: boolean;
    __typename?: boolean;
  }>;
  ['InvalidVerifyChangePasswordToken']: AliasType<{
    message?: boolean;
    __typename?: boolean;
  }>;
  ['VerifyChangePasswordTokenExpired']: AliasType<{
    message?: boolean;
    __typename?: boolean;
  }>;
  ['HangoutUrlTypeInvalidTimezoneInvalidDataSend']: AliasType<{
    ['...on HangoutUrlType']: ValueTypes['HangoutUrlType'];
    ['...on InvalidTimezone']: ValueTypes['InvalidTimezone'];
    ['...on InvalidDataSend']?: ValueTypes['InvalidDataSend'];
    __typename?: boolean;
  }>;
  ['HangoutUrlType']: AliasType<{
    url?: boolean;
    __typename?: boolean;
  }>;
  ['InvalidTimezone']: AliasType<{
    message?: boolean;
    __typename?: boolean;
  }>;
  ['InvalidDataSend']: AliasType<{
    message?: boolean;
    __typename?: boolean;
  }>;
  ['HangoutsType']: {
    hangPass: string;
    timeZone: string;
    gaSource: string;
  };
  ['ModuleProgressTypeDoesNotBelongToCourse']: AliasType<{
    ['...on ModuleProgressType']: ValueTypes['ModuleProgressType'];
    ['...on DoesNotBelongToCourse']: ValueTypes['DoesNotBelongToCourse'];
    __typename?: boolean;
  }>;
  ['DoesNotBelongToCourse']: AliasType<{
    message?: boolean;
    __typename?: boolean;
  }>;
  ['PersonalSuccessInput']: {
    responseId?: string | null;
    weight?: string | null;
  };
  ['ProgramModuleInput']: {
    title: string;
    shortTitle: string;
    description: string;
    startedAt: ValueTypes['DateTime'];
    endedAt: ValueTypes['DateTime'];
    sections: ValueTypes['ProgramModuleSectionInput'][];
  };
  ['ProgramModuleSectionInput']: {
    title: string;
    description?: string | null;
    personalSuccess?: ValueTypes['SectionPersonalSuccessInput'] | null;
    curriculum?: ValueTypes['SectionCurriculumInput'] | null;
    coachingCall?: ValueTypes['SectionCoachingCallInput'] | null;
  };
  ['SectionPersonalSuccessInput']: {
    formId?: string | null;
  };
  ['SectionCurriculumInput']: {
    url?: string | null;
  };
  ['SectionCoachingCallInput']: {
    startedAt?: ValueTypes['DateTime'] | null;
    url?: string | null;
    recordingUrl?: string | null;
  };
  ['ProgramGroupCourseTypeProgramGroupCourseProgramGroupCourseFile']: AliasType<{
    ['...on ProgramGroupCourseType']: ValueTypes['ProgramGroupCourseType'];
    ['...on ProgramGroupCourse']: ValueTypes['ProgramGroupCourse'];
    ['...on ProgramGroupCourseFile']: ValueTypes['ProgramGroupCourseFile'];
    __typename?: boolean;
  }>;
  ['ProgramGroupCourseType']: AliasType<{
    completed?: boolean;
    sectionsAdd?: boolean;
    sectionsNot?: boolean;
    __typename?: boolean;
  }>;
  ['ProgramGroupCourse']: AliasType<{
    message?: boolean;
    __typename?: boolean;
  }>;
  ['ProgramGroupCourseFile']: AliasType<{
    message?: boolean;
    __typename?: boolean;
  }>;
  /** Date (isoformat) */
  ['Date']: unknown;
  ['ProgramGroupTypeParticipantAlreadyInGroup']: AliasType<{
    ['...on ProgramGroupType']: ValueTypes['ProgramGroupType'];
    ['...on ParticipantAlreadyInGroup']: ValueTypes['ParticipantAlreadyInGroup'];
    __typename?: boolean;
  }>;
  ['ParticipantAlreadyInGroup']: AliasType<{
    message?: boolean;
    __typename?: boolean;
  }>;
  ['ProgramModuleUpdateInput']: {
    title: string;
    shortTitle: string;
    startedAt: ValueTypes['DateTime'];
    endedAt: ValueTypes['DateTime'];
    description?: string | null;
  };
  ['MemberTypes']: MemberTypes;
};

export type ModelTypes = {
  ['Query']: {
    getAdmins: ModelTypes['ProviderType'][];
    getProviders: ModelTypes['ProviderType'][];
    getCompanies: ModelTypes['CompanyType'][];
    getParticipant?: ModelTypes['ParticipantType'];
    getParticipants: ModelTypes['ParticipantTypeConnection'];
    me?: ModelTypes['ParticipantType'];
    getTimeZones: ModelTypes['TimeZonesType'][];
    getHangLink: ModelTypes['HangoutUrl'];
    getParticipantProgramModules: ModelTypes['ProgramModuleType'][];
    getParticipantProgramCourses: ModelTypes['ProgramCourseType'][];
    getModuleProgress?: ModelTypes['ModuleProgressType'];
    getSectionMaterialsFile?: string;
    getProgramCourses: ModelTypes['ProgramCourseTypeConnection'];
    getProgramCourseGroups: ModelTypes['ProgramGroupTypeConnection'];
    getGroupModules: ModelTypes['ProgramModuleType'][];
    getProgramModule: ModelTypes['ProgramModuleType'];
  };
  ['ProviderType']: {
    id: ModelTypes['UUID'];
    fullName: string;
    firstName: string;
    lastName: string;
    email: string;
    chatIdentity: string;
    avatarUrl?: string;
    isAdmin?: boolean;
  };
  ['UUID']: any;
  ['CompanyType']: {
    createdAt?: ModelTypes['DateTime'];
    updatedAt?: ModelTypes['DateTime'];
    id: ModelTypes['UUID'];
    name: string;
  };
  /** Date with time (isoformat) */
  ['DateTime']: any;
  ['CompaniesSortType']: GraphQLTypes['CompaniesSortType'];
  ['CompanyOrderByEnum']: GraphQLTypes['CompanyOrderByEnum'];
  ['OrderDirection']: GraphQLTypes['OrderDirection'];
  ['ParticipantType']: {
    chatIdentity: string;
    createdAt?: ModelTypes['DateTime'];
    updatedAt?: ModelTypes['DateTime'];
    id: ModelTypes['UUID'];
    email: string;
    firstName: string;
    lastName: string;
    groupId: string;
    memberId: string;
    status: string;
    cognitoSub?: ModelTypes['UUID'];
    medicalRecord?: string;
    isTest: boolean;
    lastReset?: ModelTypes['DateTime'];
    headsUpId?: string;
    headsUpToken?: string;
    withingId?: ModelTypes['UUID'];
    ouraId?: ModelTypes['UUID'];
    fitbitId?: ModelTypes['UUID'];
    soleraId?: ModelTypes['UUID'];
    soleraKey?: string;
    soleraProgramId?: string;
    soleraEnrollmentId?: string;
    programGroupId?: ModelTypes['UUID'];
  };
  ['ParticipantTypeConnection']: {
    pageInfo: ModelTypes['PageInfo'];
    items: ModelTypes['ParticipantType'][];
  };
  ['PageInfo']: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    currentPage: number;
    perPage: number;
    lastPage: number;
    total: number;
  };
  ['ParticipantsFilterType']: GraphQLTypes['ParticipantsFilterType'];
  ['TimeZonesType']: {
    id: string;
    label: string;
  };
  ['HangoutUrl']: {
    url: string;
  };
  ['ProgramModuleType']: {
    sections: ModelTypes['ProgramModuleSectionType'][];
    createdAt?: ModelTypes['DateTime'];
    updatedAt?: ModelTypes['DateTime'];
    id: ModelTypes['UUID'];
    shortTitle: string;
    title: string;
    startedAt: ModelTypes['DateTime'];
    endedAt: ModelTypes['DateTime'];
    description?: string;
    current: boolean;
  };
  ['ProgramModuleSectionType']: {
    id: string;
    title: string;
    type: ModelTypes['ModuleDataTypes'];
    description?: string;
    metadata: ModelTypes['PersonalSuccessTypeCurriculumTypeCoachingCallTypeDietitianCallType'];
    data: ModelTypes['PersonalSuccessTypeCurriculumTypeCoachingCallTypeDietitianCallType'];
  };
  ['ModuleDataTypes']: GraphQLTypes['ModuleDataTypes'];
  ['PersonalSuccessTypeCurriculumTypeCoachingCallTypeDietitianCallType']:
    | ModelTypes['PersonalSuccessType']
    | ModelTypes['CurriculumType']
    | ModelTypes['CoachingCallType']
    | ModelTypes['DietitianCallType'];
  ['PersonalSuccessType']: {
    formId?: string;
    type: string;
    signedUrl?: string;
  };
  ['CurriculumType']: {
    url?: string;
    type: string;
    signedUrl?: string;
  };
  ['CoachingCallType']: {
    url?: string;
    startedAt?: string;
    recordingUrl?: string;
    type: string;
  };
  ['DietitianCallType']: {
    url?: string;
    startedAt?: string;
    recordingUrl?: string;
    type: string;
  };
  ['ProgramCourseType']: {
    program: ModelTypes['ProgramType'];
    createdAt?: ModelTypes['DateTime'];
    updatedAt?: ModelTypes['DateTime'];
    id: ModelTypes['UUID'];
    startedAt: ModelTypes['DateTime'];
  };
  ['ProgramType']: {
    createdAt?: ModelTypes['DateTime'];
    updatedAt?: ModelTypes['DateTime'];
    id: ModelTypes['UUID'];
    title: string;
  };
  ['ModuleProgressType']: {
    id: string;
    completed: boolean;
    sections: ModelTypes['ProgressDataType'][];
  };
  ['ProgressDataType']: {
    sectionId: string;
    sectionType: string;
    metadata: ModelTypes['DietitianCallProgressMetadataTypeCoachingCallProgressMetadataTypeCurriculumProgressMetadataTypePersonalSuccessProgressMetadataTypeWeightTypeProgressMetadataTypeFoodTypeProgressMetadataTypeVideoTypeProgressMetadataTypeRecipeTypeProgressMetadataType'];
    completed: boolean;
  };
  ['DietitianCallProgressMetadataTypeCoachingCallProgressMetadataTypeCurriculumProgressMetadataTypePersonalSuccessProgressMetadataTypeWeightTypeProgressMetadataTypeFoodTypeProgressMetadataTypeVideoTypeProgressMetadataTypeRecipeTypeProgressMetadataType']:
    | ModelTypes['DietitianCallProgressMetadataType']
    | ModelTypes['CoachingCallProgressMetadataType']
    | ModelTypes['CurriculumProgressMetadataType']
    | ModelTypes['PersonalSuccessProgressMetadataType']
    | ModelTypes['WeightTypeProgressMetadataType']
    | ModelTypes['FoodTypeProgressMetadataType']
    | ModelTypes['VideoTypeProgressMetadataType']
    | ModelTypes['ActivityTypeProgressMetadataType'];
  ['DietitianCallProgressMetadataType']: {
    startDate: string;
    endDate?: string;
  };
  ['CoachingCallProgressMetadataType']: {
    startDate: string;
    endDate?: string;
  };
  ['CurriculumProgressMetadataType']: {
    startDate: string;
    endDate?: string;
  };
  ['PersonalSuccessProgressMetadataType']: {
    startDate: string;
    endDate?: string;
    responseId?: string;
  };
  ['WeightTypeProgressMetadataType']: {
    startDate: string;
    endDate?: string;
    weight?: string;
    responseId?: string;
  };
  ['RecipeTypeProgressMetadataType']: {
    startDate: string;
    endDate?: string;
    weight?: string;
    responseId?: string;
  };
  ['VideoTypeProgressMetadataType']: {
    startDate: string;
    endDate?: string;
    weight?: string;
    responseId?: string;
  };
  ['ActivityTypeProgressMetadataType']: {
    startDate: string;
    endDate?: string;
    value?: string;
    responseId?: string;
  };
  ['FoodTypeProgressMetadataType']: {
    startDate: string;
    endDate?: string;
  };
  ['ProgramCourseTypeConnection']: {
    pageInfo: ModelTypes['PageInfo'];
    items: ModelTypes['ProgramCourseType'][];
  };
  ['ProgramGroupTypeConnection']: {
    pageInfo: ModelTypes['PageInfo'];
    items: ModelTypes['ProgramGroupType'][];
  };
  ['ProgramGroupType']: {
    participants: ModelTypes['ParticipantType'][];
    createdAt?: ModelTypes['DateTime'];
    updatedAt?: ModelTypes['DateTime'];
    id: ModelTypes['UUID'];
    name: string;
    startedAt: ModelTypes['DateTime'];
    limit: number;
  };
  ['Mutation']: {
    signUpParticipant: ModelTypes['ParticipantTypeParticipantDuplicatedError'];
    updateStatus: ModelTypes['ParticipantTypeParticipantDneError'];
    resendConfirmationLink: boolean;
    resendConfirmationLinkByToken: boolean;
    verifyChangePassword: ModelTypes['VerifyChangePasswordTokenTypeInvalidVerifyChangePasswordTokenVerifyChangePasswordTokenExpired'];
    sendHandOut: ModelTypes['HangoutUrlTypeInvalidTimezoneInvalidDataSend'];
    addDevice: boolean;
    makeProgress?: ModelTypes['ModuleProgressTypeDoesNotBelongToCourse'];
    addProgramModule: ModelTypes['ProgramModuleType'];
    addProgramModules: ModelTypes['ProgramGroupCourseTypeProgramGroupCourseProgramGroupCourseFile'];
    removeProgramModule: boolean;
    assignProgramModuleToGroup: ModelTypes['ProgramModuleType'];
    addProgramCourseGroup: ModelTypes['ProgramGroupType'];
    addParticipantToGroup: ModelTypes['ProgramGroupTypeParticipantAlreadyInGroup'];
    removeParticipantFromGroup: ModelTypes['ProgramGroupType'];
    updateProgramModule: ModelTypes['ProgramModuleType'];
    updateProgramModuleSection: ModelTypes['ProgramModuleSectionType'];
    deleteProgramModuleSection: boolean;
    addProgramModuleSection: ModelTypes['ProgramModuleSectionType'];
    removeAdminFromGroupChat: boolean;
    addAdminToGroupChat: boolean;
    removeMemberFromGroupChat: boolean;
    addMemberToGroupChat: boolean;
  };
  ['ParticipantTypeParticipantDuplicatedError']:
    | ModelTypes['ParticipantType']
    | ModelTypes['ParticipantDuplicatedError'];
  ['ParticipantDuplicatedError']: {
    message: string;
  };
  ['SignUpInput']: GraphQLTypes['SignUpInput'];
  ['ParticipantTypeParticipantDneError']:
    | ModelTypes['ParticipantType']
    | ModelTypes['ParticipantDneError'];
  ['ParticipantDneError']: {
    message: string;
  };
  ['ParticipantStatus']: GraphQLTypes['ParticipantStatus'];
  ['VerifyChangePasswordTokenTypeInvalidVerifyChangePasswordTokenVerifyChangePasswordTokenExpired']:
    | ModelTypes['VerifyChangePasswordTokenType']
    | ModelTypes['InvalidVerifyChangePasswordToken']
    | ModelTypes['VerifyChangePasswordTokenExpired'];
  ['VerifyChangePasswordTokenType']: {
    email: string;
    tmpPassword: string;
  };
  ['InvalidVerifyChangePasswordToken']: {
    message: string;
  };
  ['VerifyChangePasswordTokenExpired']: {
    message: string;
  };
  ['HangoutUrlTypeInvalidTimezoneInvalidDataSend']:
    | ModelTypes['HangoutUrlType']
    | ModelTypes['InvalidTimezone']
    | ModelTypes['InvalidDataSend'];
  ['HangoutUrlType']: {
    url: string;
  };
  ['InvalidTimezone']: {
    message: string;
  };
  ['InvalidDataSend']: {
    message: string;
  };
  ['participantId']: {
    participantId: string;
  };
  ['HangoutsType']: GraphQLTypes['HangoutsType'];
  ['ModuleProgressTypeDoesNotBelongToCourse']:
    | ModelTypes['ModuleProgressType']
    | ModelTypes['DoesNotBelongToCourse'];
  ['DoesNotBelongToCourse']: {
    message: string;
  };
  ['PersonalSuccessInput']: GraphQLTypes['PersonalSuccessInput'];
  ['ProgramModuleInput']: GraphQLTypes['ProgramModuleInput'];
  ['ProgramModuleSectionInput']: GraphQLTypes['ProgramModuleSectionInput'];
  ['SectionPersonalSuccessInput']: GraphQLTypes['SectionPersonalSuccessInput'];
  ['SectionCurriculumInput']: GraphQLTypes['SectionCurriculumInput'];
  ['SectionCoachingCallInput']: GraphQLTypes['SectionCoachingCallInput'];
  ['ProgramGroupCourseTypeProgramGroupCourseProgramGroupCourseFile']:
    | ModelTypes['ProgramGroupCourseType']
    | ModelTypes['ProgramGroupCourse']
    | ModelTypes['ProgramGroupCourseFile'];
  ['ProgramGroupCourseType']: {
    completed: boolean;
    sectionsAdd: string[];
    sectionsNot: string[];
  };
  ['ProgramGroupCourse']: {
    message: string;
  };
  ['ProgramGroupCourseFile']: {
    message: string;
  };
  /** Date (isoformat) */
  ['Date']: any;
  ['ProgramGroupTypeParticipantAlreadyInGroup']:
    | ModelTypes['ProgramGroupType']
    | ModelTypes['ParticipantAlreadyInGroup'];
  ['ParticipantAlreadyInGroup']: {
    message: string;
  };
  ['ProgramModuleUpdateInput']: GraphQLTypes['ProgramModuleUpdateInput'];
  ['MemberTypes']: GraphQLTypes['MemberTypes'];
};

export type GraphQLTypes = {
  ['Query']: {
    __typename: 'Query';
    getAdmins: Array<GraphQLTypes['ProviderType']>;
    getProviders: Array<GraphQLTypes['ProviderType']>;
    getCompanies: Array<GraphQLTypes['CompanyType']>;
    getParticipant?: GraphQLTypes['ParticipantType'];
    getParticipants: GraphQLTypes['ParticipantTypeConnection'];
    me?: GraphQLTypes['ParticipantType'];
    getTimeZones: Array<GraphQLTypes['TimeZonesType']>;
    getHangLink: GraphQLTypes['HangoutUrl'];
    getParticipantProgramModules: Array<GraphQLTypes['ProgramModuleType']>;
    getParticipantProgramCourses: Array<GraphQLTypes['ProgramCourseType']>;
    getModuleProgress?: GraphQLTypes['ModuleProgressType'];
    getSectionMaterialsFile?: string;
    getProgramCourses: GraphQLTypes['ProgramCourseTypeConnection'];
    getProgramCourseGroups: GraphQLTypes['ProgramGroupTypeConnection'];
    getGroupModules: Array<GraphQLTypes['ProgramModuleType']>;
    getProgramModule: GraphQLTypes['ProgramModuleType'];
  };
  ['ProviderType']: {
    __typename: 'ProviderType';
    id: GraphQLTypes['UUID'];
    fullName: string;
    firstName: string;
    lastName: string;
    email: string;
    chatIdentity: string;
    avatarUrl?: string;
    isAdmin?: boolean;
  };
  ['UUID']: any;
  ['CompanyType']: {
    __typename: 'CompanyType';
    createdAt?: GraphQLTypes['DateTime'];
    updatedAt?: GraphQLTypes['DateTime'];
    id: GraphQLTypes['UUID'];
    name: string;
  };
  /** Date with time (isoformat) */
  ['DateTime']: any;
  ['CompaniesSortType']: {
    orderBy: GraphQLTypes['CompanyOrderByEnum'];
    direction: GraphQLTypes['OrderDirection'];
  };
  ['CompanyOrderByEnum']: CompanyOrderByEnum;
  ['OrderDirection']: OrderDirection;
  ['ParticipantType']: {
    __typename: 'ParticipantType';
    chatIdentity: string;
    createdAt?: GraphQLTypes['DateTime'];
    updatedAt?: GraphQLTypes['DateTime'];
    id: GraphQLTypes['UUID'];
    email: string;
    firstName: string;
    lastName: string;
    groupId: string;
    memberId: string;
    status: string;
    cognitoSub?: GraphQLTypes['UUID'];
    medicalRecord?: string;
    isTest: boolean;
    lastReset?: GraphQLTypes['DateTime'];
    headsUpId?: string;
    headsUpToken?: string;
    withingId?: GraphQLTypes['UUID'];
    ouraId?: GraphQLTypes['UUID'];
    fitbitId?: GraphQLTypes['UUID'];
    soleraId?: GraphQLTypes['UUID'];
    soleraKey?: string;
    soleraProgramId?: string;
    soleraEnrollmentId?: string;
    programGroupId?: GraphQLTypes['UUID'];
  };
  ['ParticipantTypeConnection']: {
    __typename: 'ParticipantTypeConnection';
    pageInfo: GraphQLTypes['PageInfo'];
    items: Array<GraphQLTypes['ParticipantType']>;
  };
  ['PageInfo']: {
    __typename: 'PageInfo';
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    currentPage: number;
    perPage: number;
    lastPage: number;
    total: number;
  };
  ['ParticipantsFilterType']: {
    ids?: Array<GraphQLTypes['UUID']>;
    emails?: Array<string>;
    groupId?: GraphQLTypes['UUID'];
  };
  ['TimeZonesType']: {
    __typename: 'TimeZonesType';
    id: string;
    label: string;
  };
  ['HangoutUrl']: {
    __typename: 'HangoutUrl';
    url: string;
  };
  ['ProgramModuleType']: {
    __typename: 'ProgramModuleType';
    sections: Array<GraphQLTypes['ProgramModuleSectionType']>;
    createdAt?: GraphQLTypes['DateTime'];
    updatedAt?: GraphQLTypes['DateTime'];
    id: GraphQLTypes['UUID'];
    shortTitle: string;
    title: string;
    startedAt: GraphQLTypes['DateTime'];
    endedAt: GraphQLTypes['DateTime'];
    description?: string;
    current: boolean;
  };
  ['ProgramModuleSectionType']: {
    __typename: 'ProgramModuleSectionType';
    id: string;
    title: string;
    type: GraphQLTypes['ModuleDataTypes'];
    description?: string;
    metadata: GraphQLTypes['PersonalSuccessTypeCurriculumTypeCoachingCallTypeDietitianCallType'];
    data: GraphQLTypes['PersonalSuccessTypeCurriculumTypeCoachingCallTypeDietitianCallType'];
  };
  ['ModuleDataTypes']: ModuleDataTypes;
  ['PersonalSuccessTypeCurriculumTypeCoachingCallTypeDietitianCallType']: {
    __typename: 'PersonalSuccessType' | 'CurriculumType' | 'CoachingCallType' | 'DietitianCallType';
    ['...on PersonalSuccessType']: '__union' & GraphQLTypes['PersonalSuccessType'];
    ['...on CurriculumType']: '__union' & GraphQLTypes['CurriculumType'];
    ['...on CoachingCallType']: '__union' & GraphQLTypes['CoachingCallType'];
    ['...on DietitianCallType']: '__union' & GraphQLTypes['DietitianCallType'];
    ['...on WeightType']: '__union' & GraphQLTypes['WeightType'];
    ['...on RecipeType']: '__union' & GraphQLTypes['RecipeType'];
    ['...on VideoType']: '__union' & GraphQLTypes['VideoType'];
    ['...on ActivityType']: '__union' & GraphQLTypes['ActivityType'];
  };
  ['PersonalSuccessType']: {
    __typename: 'PersonalSuccessType';
    formId?: string;
    type: string;
    signedUrl?: string;
  };
  ['CurriculumType']: {
    __typename: 'CurriculumType';
    url?: string;
    type: string;
    signedUrl?: string;
  };
  ['CoachingCallType']: {
    __typename: 'CoachingCallType';
    url?: string;
    startedAt?: string;
    recordingUrl?: string;
    type: string;
  };
  ['DietitianCallType']: {
    __typename: 'DietitianCallType';
    url?: string;
    startedAt?: string;
    recordingUrl?: string;
    type: string;
  };
  ['WeightType']: {
    __typename: 'WeightType';
    type: string;
    value: string;
  };
  ['RecipeType']: {
    __typename: 'RecipeType';
    type: string;
    value: string;
    url: string;
  };
  ['VideoType']: {
    __typename: 'VideoType';
    type: string;
    signedUrl: string;
  };
  ['ActivityType']: {
    __typename: 'ActivityType';
    type: string;
  };
  ['ProgramCourseType']: {
    __typename: 'ProgramCourseType';
    program: GraphQLTypes['ProgramType'];
    createdAt?: GraphQLTypes['DateTime'];
    updatedAt?: GraphQLTypes['DateTime'];
    id: GraphQLTypes['UUID'];
    startedAt: GraphQLTypes['DateTime'];
  };
  ['ProgramType']: {
    __typename: 'ProgramType';
    createdAt?: GraphQLTypes['DateTime'];
    updatedAt?: GraphQLTypes['DateTime'];
    id: GraphQLTypes['UUID'];
    title: string;
  };
  ['ModuleProgressType']: {
    __typename: 'ModuleProgressType';
    id: string;
    completed: boolean;
    sections: Array<GraphQLTypes['ProgressDataType']>;
  };
  ['ProgressDataType']: {
    __typename: 'ProgressDataType';
    sectionId: string;
    sectionType: string;
    metadata: GraphQLTypes['DietitianCallProgressMetadataTypeCoachingCallProgressMetadataTypeCurriculumProgressMetadataTypePersonalSuccessProgressMetadataTypeWeightTypeProgressMetadataTypeFoodTypeProgressMetadataTypeVideoTypeProgressMetadataTypeRecipeTypeProgressMetadataType'];
    completed: boolean;
  };
  ['DietitianCallProgressMetadataTypeCoachingCallProgressMetadataTypeCurriculumProgressMetadataTypePersonalSuccessProgressMetadataTypeWeightTypeProgressMetadataTypeFoodTypeProgressMetadataTypeVideoTypeProgressMetadataTypeRecipeTypeProgressMetadataType']: {
    __typename:
      | 'DietitianCallProgressMetadataType'
      | 'CoachingCallProgressMetadataType'
      | 'CurriculumProgressMetadataType'
      | 'PersonalSuccessProgressMetadataType'
      | 'WeightTypeProgressMetadataType'
      | 'FoodTypeProgressMetadataType'
      | 'VideoTypeProgressMetadataType'
      | 'RecipeTypeProgressMetadataType'
      | 'ActivityTypeProgressMetadataType';
    ['...on DietitianCallProgressMetadataType']: '__union' &
      GraphQLTypes['DietitianCallProgressMetadataType'];
    ['...on CoachingCallProgressMetadataType']: '__union' &
      GraphQLTypes['CoachingCallProgressMetadataType'];
    ['...on CurriculumProgressMetadataType']: '__union' &
      GraphQLTypes['CurriculumProgressMetadataType'];
    ['...on PersonalSuccessProgressMetadataType']: '__union' &
      GraphQLTypes['PersonalSuccessProgressMetadataType'];
    ['...on WeightTypeProgressMetadataType']: '__union' &
      GraphQLTypes['WeightTypeProgressMetadataType'];
    ['...on FoodTypeProgressMetadataType']: '__union' &
      GraphQLTypes['FoodTypeProgressMetadataType'];
    ['...on VideoTypeProgressMetadataType']: '__union' &
      GraphQLTypes['VideoTypeProgressMetadataType'];
    ['...on RecipeTypeProgressMetadataType']: '__union' &
      GraphQLTypes['RecipeTypeProgressMetadataType'];
    ['...on ActivityTypeProgressMetadataType']: '__union' &
      GraphQLTypes['ActivityTypeProgressMetadataType'];
  };
  ['DietitianCallProgressMetadataType']: {
    __typename: 'DietitianCallProgressMetadataType';
    startDate: string;
    endDate?: string;
  };
  ['CoachingCallProgressMetadataType']: {
    __typename: 'CoachingCallProgressMetadataType';
    startDate: string;
    endDate?: string;
  };
  ['CurriculumProgressMetadataType']: {
    __typename: 'CurriculumProgressMetadataType';
    startDate: string;
    endDate?: string;
  };
  ['PersonalSuccessProgressMetadataType']: {
    __typename: 'PersonalSuccessProgressMetadataType';
    startDate: string;
    endDate?: string;
    responseId?: string;
  };
  ['WeightTypeProgressMetadataType']: {
    __typename: 'WeightTypeProgressMetadataType';
    startDate: string;
    endDate?: string;
    value?: string;
    responseId?: string;
  };
  ['VideoTypeProgressMetadataType']: {
    __typename: 'VideoTypeProgressMetadataType';
    startDate: string;
    endDate?: string;
    value?: string;
    responseId?: string;
  };
  ['ActivityTypeProgressMetadataType']: {
    __typename: 'ActivityTypeProgressMetadataType';
    startDate: string;
    endDate?: string;
    value?: string;
    responseId?: string;
  };
  ['RecipeTypeProgressMetadataType']: {
    __typename: 'WeightTypeProgressMetadataType';
    startDate: string;
    endDate?: string;
    value?: string;
    responseId?: string;
  };
  ['FoodTypeProgressMetadataType']: {
    __typename: 'FoodTypeProgressMetadataType';
    startDate: string;
    endDate?: string;
  };
  ['ProgramCourseTypeConnection']: {
    __typename: 'ProgramCourseTypeConnection';
    pageInfo: GraphQLTypes['PageInfo'];
    items: Array<GraphQLTypes['ProgramCourseType']>;
  };
  ['ProgramGroupTypeConnection']: {
    __typename: 'ProgramGroupTypeConnection';
    pageInfo: GraphQLTypes['PageInfo'];
    items: Array<GraphQLTypes['ProgramGroupType']>;
  };
  ['ProgramGroupType']: {
    __typename: 'ProgramGroupType';
    participants: Array<GraphQLTypes['ParticipantType']>;
    createdAt?: GraphQLTypes['DateTime'];
    updatedAt?: GraphQLTypes['DateTime'];
    id: GraphQLTypes['UUID'];
    name: string;
    startedAt: GraphQLTypes['DateTime'];
    limit: number;
  };
  ['Mutation']: {
    __typename: 'Mutation';
    signUpParticipant: GraphQLTypes['ParticipantTypeParticipantDuplicatedError'];
    updateStatus: GraphQLTypes['ParticipantTypeParticipantDneError'];
    resendConfirmationLink: boolean;
    resendConfirmationLinkByToken: boolean;
    verifyChangePassword: GraphQLTypes['VerifyChangePasswordTokenTypeInvalidVerifyChangePasswordTokenVerifyChangePasswordTokenExpired'];
    sendHandOut: GraphQLTypes['HangoutUrlTypeInvalidTimezoneInvalidDataSend'];
    addDevice: boolean;
    makeProgress?: GraphQLTypes['ModuleProgressTypeDoesNotBelongToCourse'];
    addChatActivity?: GraphQLTypes['ChatActivity'];
    landParticipant?: GraphQLTypes['LandParticipant'];
    addProgramModule: GraphQLTypes['ProgramModuleType'];
    addProgramModules: GraphQLTypes['ProgramGroupCourseTypeProgramGroupCourseProgramGroupCourseFile'];
    removeProgramModule: boolean;
    assignProgramModuleToGroup: GraphQLTypes['ProgramModuleType'];
    addProgramCourseGroup: GraphQLTypes['ProgramGroupType'];
    addParticipantToGroup: GraphQLTypes['ProgramGroupTypeParticipantAlreadyInGroup'];
    removeParticipantFromGroup: GraphQLTypes['ProgramGroupType'];
    updateProgramModule: GraphQLTypes['ProgramModuleType'];
    updateProgramModuleSection: GraphQLTypes['ProgramModuleSectionType'];
    deleteProgramModuleSection: boolean;
    addProgramModuleSection: GraphQLTypes['ProgramModuleSectionType'];
    removeAdminFromGroupChat: boolean;
    addAdminToGroupChat: boolean;
    removeMemberFromGroupChat: boolean;
    addMemberToGroupChat: boolean;
  };
  ['ParticipantTypeParticipantDuplicatedError']: {
    __typename: 'ParticipantType' | 'ParticipantDuplicatedError';
    ['...on ParticipantType']: '__union' & GraphQLTypes['ParticipantType'];
    ['...on ParticipantDuplicatedError']: '__union' & GraphQLTypes['ParticipantDuplicatedError'];
  };
  ['ParticipantDuplicatedError']: {
    __typename: 'ParticipantDuplicatedError';
    message: string;
  };
  ['SignUpInput']: {
    email: string;
    firstName: string;
    lastName: string;
    companyId: GraphQLTypes['UUID'];
    groupId: string;
    memberId: string;
  };
  ['ParticipantTypeParticipantDneError']: {
    __typename: 'ParticipantType' | 'ParticipantDneError';
    ['...on ParticipantType']: '__union' & GraphQLTypes['ParticipantType'];
    ['...on ParticipantDneError']: '__union' & GraphQLTypes['ParticipantDneError'];
  };
  ['ParticipantDneError']: {
    __typename: 'ParticipantDneError';
    message: string;
  };
  ['ParticipantStatus']: ParticipantStatus;
  ['VerifyChangePasswordTokenTypeInvalidVerifyChangePasswordTokenVerifyChangePasswordTokenExpired']: {
    __typename:
      | 'VerifyChangePasswordTokenType'
      | 'InvalidVerifyChangePasswordToken'
      | 'VerifyChangePasswordTokenExpired';
    ['...on VerifyChangePasswordTokenType']: '__union' &
      GraphQLTypes['VerifyChangePasswordTokenType'];
    ['...on InvalidVerifyChangePasswordToken']: '__union' &
      GraphQLTypes['InvalidVerifyChangePasswordToken'];
    ['...on VerifyChangePasswordTokenExpired']: '__union' &
      GraphQLTypes['VerifyChangePasswordTokenExpired'];
  };
  ['VerifyChangePasswordTokenType']: {
    __typename: 'VerifyChangePasswordTokenType';
    email: string;
    tmpPassword: string;
  };
  ['InvalidVerifyChangePasswordToken']: {
    __typename: 'InvalidVerifyChangePasswordToken';
    message: string;
  };
  ['VerifyChangePasswordTokenExpired']: {
    __typename: 'VerifyChangePasswordTokenExpired';
    message: string;
  };
  ['HangoutUrlTypeInvalidTimezoneInvalidDataSend']: {
    __typename: 'HangoutUrlType' | 'InvalidTimezone' | 'InvalidDataSend';
    ['...on HangoutUrlType']: '__union' & GraphQLTypes['HangoutUrlType'];
    ['...on InvalidTimezone']: '__union' & GraphQLTypes['InvalidTimezone'];
    ['...on InvalidDataSend']: '__union' & GraphQLTypes['InvalidDataSend'];
  };
  ['HangoutUrlType']: {
    __typename: 'HangoutUrlType';
    url: string;
  };
  ['InvalidTimezone']: {
    __typename: 'InvalidTimezone';
    message: string;
  };
  ['InvalidDataSend']: {
    __typename: 'InvalidDataSend';
    message: string;
  };
  ['HangoutsType']: {
    hangPass: string;
    timeZone: string;
    gaSource: string;
  };
  ['ChatActivity']: {
    participantId: string;
  };
  ['LandParticipant']: {
    lookUpKey: string;
    email?: string;
  };
  ['ModuleProgressTypeDoesNotBelongToCourse']: {
    __typename: 'ModuleProgressType' | 'DoesNotBelongToCourse';
    ['...on ModuleProgressType']: '__union' & GraphQLTypes['ModuleProgressType'];
    ['...on DoesNotBelongToCourse']: '__union' & GraphQLTypes['DoesNotBelongToCourse'];
  };
  ['DoesNotBelongToCourse']: {
    __typename: 'DoesNotBelongToCourse';
    message: string;
  };
  ['PersonalSuccessInput']: {
    responseId?: string;
    weight?: string;
  };
  ['ProgramModuleInput']: {
    title: string;
    shortTitle: string;
    description: string;
    startedAt: GraphQLTypes['DateTime'];
    endedAt: GraphQLTypes['DateTime'];
    sections: Array<GraphQLTypes['ProgramModuleSectionInput']>;
  };
  ['ProgramModuleSectionInput']: {
    title: string;
    description?: string;
    personalSuccess?: GraphQLTypes['SectionPersonalSuccessInput'];
    curriculum?: GraphQLTypes['SectionCurriculumInput'];
    coachingCall?: GraphQLTypes['SectionCoachingCallInput'];
  };
  ['SectionPersonalSuccessInput']: {
    formId?: string;
  };
  ['SectionCurriculumInput']: {
    url?: string;
  };
  ['SectionCoachingCallInput']: {
    startedAt?: GraphQLTypes['DateTime'];
    url?: string;
    recordingUrl?: string;
  };
  ['ProgramGroupCourseTypeProgramGroupCourseProgramGroupCourseFile']: {
    __typename: 'ProgramGroupCourseType' | 'ProgramGroupCourse' | 'ProgramGroupCourseFile';
    ['...on ProgramGroupCourseType']: '__union' & GraphQLTypes['ProgramGroupCourseType'];
    ['...on ProgramGroupCourse']: '__union' & GraphQLTypes['ProgramGroupCourse'];
    ['...on ProgramGroupCourseFile']: '__union' & GraphQLTypes['ProgramGroupCourseFile'];
  };
  ['ProgramGroupCourseType']: {
    __typename: 'ProgramGroupCourseType';
    completed: boolean;
    sectionsAdd: Array<string>;
    sectionsNot: Array<string>;
  };
  ['ProgramGroupCourse']: {
    __typename: 'ProgramGroupCourse';
    message: string;
  };
  ['ProgramGroupCourseFile']: {
    __typename: 'ProgramGroupCourseFile';
    message: string;
  };
  /** Date (isoformat) */
  ['Date']: any;
  ['ProgramGroupTypeParticipantAlreadyInGroup']: {
    __typename: 'ProgramGroupType' | 'ParticipantAlreadyInGroup';
    ['...on ProgramGroupType']: '__union' & GraphQLTypes['ProgramGroupType'];
    ['...on ParticipantAlreadyInGroup']: '__union' & GraphQLTypes['ParticipantAlreadyInGroup'];
  };
  ['ParticipantAlreadyInGroup']: {
    __typename: 'ParticipantAlreadyInGroup';
    message: string;
  };
  ['ProgramModuleUpdateInput']: {
    title: string;
    shortTitle: string;
    startedAt: GraphQLTypes['DateTime'];
    endedAt: GraphQLTypes['DateTime'];
    description?: string;
  };
  ['MemberTypes']: MemberTypes;
};
export const enum CompanyOrderByEnum {
  NAME = 'NAME',
}
export const enum OrderDirection {
  DESC = 'DESC',
  ASC = 'ASC',
}
export const enum ModuleDataTypes {
  coaching_call = 'coaching_call',
  dietitian_call = 'dietitian_call',
  personal_success = 'personal_success',
  curriculum = 'curriculum',
  weight_type = 'weight_type',
  food_type = 'food_type',
  video_type = 'video_type',
  recipe_type = 'recipe_type',
  activity_type = 'activity_type',
}
export const enum ParticipantStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}
export const enum MemberTypes {
  participant = 'participant',
  admin = 'admin',
  provider = 'provider',
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
  }
  toString() {
    return 'GraphQL Response Error';
  }
}

export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<
  UnwrapPromise<ReturnType<T>>
>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>,
> = ZeusState<ReturnType<T>[N]>;

type WithTypeNameValue<T> = T & {
  __typename?: boolean;
};
type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
type IsArray<T, U> = T extends Array<infer R> ? InputType<R, U>[] : InputType<T, U>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;

type IsInterfaced<SRC extends DeepAnify<DST>, DST> = FlattenArray<SRC> extends
  | ZEUS_INTERFACES
  | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P]>
          : {}
        : never;
    }[keyof DST] & {
      [P in keyof Omit<
        Pick<
          SRC,
          {
            [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
          }[keyof DST]
        >,
        '__typename'
      >]: IsPayLoad<DST[P]> extends boolean ? SRC[P] : IsArray<SRC[P], DST[P]>;
    }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends boolean
        ? SRC[P]
        : IsArray<SRC[P], DST[P]>;
    };

export type MapType<SRC, DST> = SRC extends DeepAnify<DST> ? IsInterfaced<SRC, DST> : never;
export type InputType<SRC, DST> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P]>;
    } & MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>>
  : MapType<SRC, IsPayLoad<DST>>;
type Func<P extends any[], R> = (...args: P) => R;
type AnyFunc = Func<any, any>;
export type ArgsType<F extends AnyFunc> = F extends Func<infer P, any> ? P : never;
export type OperationOptions = {
  variables?: Record<string, any>;
  operationName?: string;
};
export type SubscriptionToGraphQL<Z, T> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z>) => void) => void;
  off: (
    fn: (e: { data?: InputType<T, Z>; code?: number; reason?: string; message?: string }) => void,
  ) => void;
  error: (fn: (e: { data?: InputType<T, Z>; errors?: string[] }) => void) => void;
  open: () => void;
};
export type SelectionFunction<V> = <T>(t: T | V) => T;
export type fetchOptions = ArgsType<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions =
  | [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }]
  | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, any>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export const ZeusSelect = <T>() => ((t: any) => t) as SelectionFunction<T>;

export const ScalarResolver = (scalar: string, value: any) => {
  switch (scalar) {
    case 'String':
      return `${JSON.stringify(value)}`;
    case 'Int':
      return `${value}`;
    case 'Float':
      return `${value}`;
    case 'Boolean':
      return `${value}`;
    case 'ID':
      return `"${value}"`;
    case 'enum':
      return `${value}`;
    case 'scalar':
      return `${value}`;
    default:
      return false;
  }
};

export const TypesPropsResolver = ({
  value,
  type,
  name,
  key,
  blockArrays,
}: {
  value: any;
  type: string;
  name: string;
  key?: string;
  blockArrays?: boolean;
}): string => {
  if (value === null) {
    return `null`;
  }
  let resolvedValue = AllTypesProps[type][name];
  if (key) {
    resolvedValue = resolvedValue[key];
  }
  if (!resolvedValue) {
    throw new Error(`Cannot resolve ${type} ${name}${key ? ` ${key}` : ''}`);
  }
  const typeResolved = resolvedValue.type;
  const isArray = resolvedValue.array;
  const isArrayRequired = resolvedValue.arrayRequired;
  if (typeof value === 'string' && value.startsWith(`ZEUS_VAR$`)) {
    const isRequired = resolvedValue.required ? '!' : '';
    let t = `${typeResolved}`;
    if (isArray) {
      if (isRequired) {
        t = `${t}!`;
      }
      t = `[${t}]`;
      if (isArrayRequired) {
        t = `${t}!`;
      }
    } else {
      if (isRequired) {
        t = `${t}!`;
      }
    }
    return `\$${value.split(`ZEUS_VAR$`)[1]}__ZEUS_VAR__${t}`;
  }
  if (isArray && !blockArrays) {
    return `[${value
      .map((v: any) => TypesPropsResolver({ value: v, type, name, key, blockArrays: true }))
      .join(',')}]`;
  }
  const reslovedScalar = ScalarResolver(typeResolved, value);
  if (!reslovedScalar) {
    const resolvedType = AllTypesProps[typeResolved];
    if (typeof resolvedType === 'object') {
      const argsKeys = Object.keys(resolvedType);
      return `{${argsKeys
        .filter((ak) => value[ak] !== undefined)
        .map(
          (ak) => `${ak}:${TypesPropsResolver({ value: value[ak], type: typeResolved, name: ak })}`,
        )}}`;
    }
    return ScalarResolver(AllTypesProps[typeResolved], value) as string;
  }
  return reslovedScalar;
};

const isArrayFunction = (parent: string[], a: any[]) => {
  const [values, r] = a;
  const [mainKey, key, ...keys] = parent;
  const keyValues = Object.keys(values).filter((k) => typeof values[k] !== 'undefined');

  if (!keys.length) {
    return keyValues.length > 0
      ? `(${keyValues
          .map(
            (v) =>
              `${v}:${TypesPropsResolver({
                value: values[v],
                type: mainKey,
                name: key,
                key: v,
              })}`,
          )
          .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
      : traverseToSeekArrays(parent, r);
  }

  const [typeResolverKey] = keys.splice(keys.length - 1, 1);
  let valueToResolve = ReturnTypes[mainKey][key];
  for (const k of keys) {
    valueToResolve = ReturnTypes[valueToResolve][k];
  }

  const argumentString =
    keyValues.length > 0
      ? `(${keyValues
          .map(
            (v) =>
              `${v}:${TypesPropsResolver({
                value: values[v],
                type: valueToResolve,
                name: typeResolverKey,
                key: v,
              })}`,
          )
          .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
      : traverseToSeekArrays(parent, r);
  return argumentString;
};

const resolveKV = (k: string, v: boolean | string | { [x: string]: boolean | string }) =>
  typeof v === 'boolean' ? k : typeof v === 'object' ? `${k}{${objectToTree(v)}}` : `${k}${v}`;

const objectToTree = (o: { [x: string]: boolean | string }): string =>
  `{${Object.keys(o)
    .map((k) => `${resolveKV(k, o[k])}`)
    .join(' ')}}`;

const traverseToSeekArrays = (parent: string[], a?: any): string => {
  if (!a) return '';
  if (Object.keys(a).length === 0) {
    return '';
  }
  let b: Record<string, any> = {};
  if (Array.isArray(a)) {
    return isArrayFunction([...parent], a);
  } else {
    if (typeof a === 'object') {
      Object.keys(a)
        .filter((k) => typeof a[k] !== 'undefined')
        .forEach((k) => {
          if (k === '__alias') {
            Object.keys(a[k]).forEach((aliasKey) => {
              const aliasOperations = a[k][aliasKey];
              const aliasOperationName = Object.keys(aliasOperations)[0];
              const aliasOperation = aliasOperations[aliasOperationName];
              b[`${aliasOperationName}__alias__${aliasKey}: ${aliasOperationName}`] =
                traverseToSeekArrays([...parent, aliasOperationName], aliasOperation);
            });
          } else {
            b[k] = traverseToSeekArrays([...parent, k], a[k]);
          }
        });
    } else {
      return '';
    }
  }
  return objectToTree(b);
};

const buildQuery = (type: string, a?: Record<any, any>) => traverseToSeekArrays([type], a);

const inspectVariables = (query: string) => {
  const regex = /\$\b\w*__ZEUS_VAR__\[?[^!^\]^\s^,^\)^\}]*[!]?[\]]?[!]?/g;
  let result;
  const AllVariables: string[] = [];
  while ((result = regex.exec(query))) {
    if (AllVariables.includes(result[0])) {
      continue;
    }
    AllVariables.push(result[0]);
  }
  if (!AllVariables.length) {
    return query;
  }
  let filteredQuery = query;
  AllVariables.forEach((variable) => {
    while (filteredQuery.includes(variable)) {
      filteredQuery = filteredQuery.replace(variable, variable.split('__ZEUS_VAR__')[0]);
    }
  });
  return `(${AllVariables.map((a) => a.split('__ZEUS_VAR__'))
    .map(([variableName, variableType]) => `${variableName}:${variableType}`)
    .join(', ')})${filteredQuery}`;
};

export const queryConstruct =
  (t: 'query' | 'mutation' | 'subscription', tName: string, operationName?: string) =>
  (o: Record<any, any>) =>
    `${t.toLowerCase()}${operationName ? ' ' + operationName : ''}${inspectVariables(
      buildQuery(tName, o),
    )}`;

export const fullChainConstruct =
  (fn: FetchFunction) =>
  (t: 'query' | 'mutation' | 'subscription', tName: string) =>
  (o: Record<any, any>, options?: OperationOptions) =>
    fn(queryConstruct(t, tName, options?.operationName)(o), options?.variables).then((r: any) => {
      seekForAliases(r);
      return r;
    });

export const fullSubscriptionConstruct =
  (fn: SubscriptionFunction) =>
  (t: 'query' | 'mutation' | 'subscription', tName: string) =>
  (o: Record<any, any>, options?: OperationOptions) =>
    fn(queryConstruct(t, tName, options?.operationName)(o));

const seekForAliases = (response: any) => {
  const traverseAlias = (value: any) => {
    if (Array.isArray(value)) {
      value.forEach(seekForAliases);
    } else {
      if (typeof value === 'object') {
        seekForAliases(value);
      }
    }
  };
  if (typeof response === 'object' && response) {
    const keys = Object.keys(response);
    if (keys.length < 1) {
      return;
    }
    keys.forEach((k) => {
      const value = response[k];
      if (k.indexOf('__alias__') !== -1) {
        const [operation, alias] = k.split('__alias__');
        response[alias] = {
          [operation]: value,
        };
        delete response[k];
      }
      traverseAlias(value);
    });
  }
};

export const $ = (t: TemplateStringsArray): any => `ZEUS_VAR$${t.join('')}`;

export const resolverFor = <X, T extends keyof ValueTypes, Z extends keyof ValueTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ValueTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any, source?: any) => any;

const handleFetchResponse = (
  response: Parameters<Extract<Parameters<ReturnType<typeof fetch>['then']>[0], Function>>[0],
): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json();
};

export const apiFetch =
  (options: fetchOptions) =>
  (query: string, variables: Record<string, any> = {}) => {
    let fetchFunction = fetch;
    let queryString = query;
    let fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      queryString = encodeURIComponent(query);
      return fetchFunction(`${options[0]}?query=${queryString}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetchFunction(`${options[0]}`, {
      body: JSON.stringify({ query: queryString, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      ...fetchOptions,
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };

export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            if (data) {
              seekForAliases(data);
            }
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};

const allOperations = {
  query: 'Query',
  mutation: 'Mutation',
};

export type GenericOperation<O> = O extends 'query'
  ? 'Query'
  : O extends 'mutation'
  ? 'Mutation'
  : never;

export const Thunder =
  (fn: FetchFunction) =>
  <O extends 'query' | 'mutation', R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
    fullChainConstruct(fn)(operation, allOperations[operation])(o as any, ops) as Promise<
      InputType<GraphQLTypes[R], Z>
    >;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder =
  (fn: SubscriptionFunction) =>
  <O extends 'query' | 'mutation', R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
    fullSubscriptionConstruct(fn)(operation, allOperations[operation])(
      o as any,
      ops,
    ) as SubscriptionToGraphQL<Z, GraphQLTypes[R]>;

export const Subscription = (...options: chainOptions) =>
  SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends 'query' | 'mutation',
  R extends keyof ValueTypes = GenericOperation<O>,
>(
  operation: O,
  o: Z | ValueTypes[R],
  operationName?: string,
) => queryConstruct(operation, allOperations[operation], operationName)(o as any);
export const Selector = <T extends keyof ValueTypes>(key: T) => ZeusSelect<ValueTypes[T]>();

export const Gql = Chain('https://participant-api-dev.cibahealth.com/graphql');

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { Button } from '../Button/Button';
import { Modal } from '../Modal/Modal';

type WeightModalType = {
  onClose: () => void;
  onConfirm: () => void;
  confirmButtonName?: string;
  cancelButtonName?: string;
  headerIcon?: JSX.Element;
  isDisabled?: boolean;
};

export const WeightModal: FC<WeightModalType> = ({
  onClose,
  children,
  onConfirm,
  cancelButtonName = 'cancel',
  confirmButtonName = 'confirm',
  headerIcon,
  isDisabled,
}) => {
  const { t } = useTranslation();
  return (
    <Modal onCancel={onClose}>
      <div className="flex flex-col justify-between w-[30rem] sm:w-[42rem] h-[25rem]">
        <div className="flex flex-col justify-between">
          <div className="flex align-middle items-start justify-between">
            <div>{headerIcon}</div>
            <div onClick={onClose} className="close  cursor-pointer">
              <CloseIcon />
            </div>
          </div>
          <div className="text-[1.5rem] w-full flex flex-col justify-center items-center mt-8">
            {children}
          </div>
        </div>
        <div>
          <div className="bg-galery h-px mb-10"></div>
          <div className="flex justify-end">
            <div className="w-[20rem] mr-4">
              <Button outlined onClick={onClose}>
                {t(cancelButtonName)}
              </Button>
            </div>
            <div className="w-[20rem]">
              <Button onClick={onConfirm} disabled={isDisabled}>
                {t(confirmButtonName)}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

import { Title } from '../../components/HeaderTitle/HeaderTitle';
import { Plate } from '../../components/Plate/Plate';

import React, { FC } from 'react';
import { YourProgress } from '../../components/YourProgress/YourProgress';
import { WeekSwitch } from '../../components/WeekSwitch/WeekSwitch';
import { WebinarSection } from '../../components/WebinarSection/WebinarSection';
import { ModuleDescription } from '../../components/ModuleDescription/ModuleDescription';
import { PersonalSuccessSection } from '../../components/PersonalSuccessSection/PersonalSuccessSection';
import { CurriculumSection } from '../../components/CurriculumSection/CurriculumSection';
import { useScreenView } from '../../utilities/isScreenView';
import { useTranslation } from 'react-i18next';
import {
  CoachingCallType,
  CurriculumType,
  DietitianCallType,
  ProgramModuleCleaner,
  TransformModuleDataResult,
  TransformProgressDataResult,
  VideoType,
  WeightType,
  RecipeType,
  ActivityType,
} from '../../types';
import { WeightSection } from '../../components/WeightSection/WeightSection';
import { VideoSection } from '../../components/VideorSection/VIdeoSection';
import { ActivitySection } from '../../components/ActivitySection/ActivitySection';
import { RecipeSection } from '../../components/RecipeSection/RecipeSection';

type DashboardViewProps = {
  isLoading: boolean;
  username: string;
  weekTitle: string;
  module: TransformModuleDataResult | null;
  progress: TransformProgressDataResult;
  modulesLength: number;
  currentModuleIndex: number;
  fileUrl?: string;
  onNextWeek: () => void;
  onPrevWeek: () => void;
  personalSuccessForm: string;
  onStartCall: (
    section:
      | ProgramModuleCleaner<CoachingCallType>
      | ProgramModuleCleaner<DietitianCallType>
      | ProgramModuleCleaner<CurriculumType>
      | ProgramModuleCleaner<RecipeType>,
  ) => void;
  onSendData: (
    section:
      | ProgramModuleCleaner<WeightType>
      | ProgramModuleCleaner<VideoType>
      | ProgramModuleCleaner<ActivityType>,
    value: string,
  ) => void;
  onOpenPersonalSuccessForm: (formId: string) => void;
  openCurriculumHtmlForm: (url: string) => void;
  openCurriculumPDFForm: (url: string) => void;
  openRecipesHtmlForm: (url: string) => void;
  currentModuleId: string;
  hasCohortStarted?: boolean;
  isWeight?: boolean;
};

export const DashboardView: FC<DashboardViewProps> = ({
  isLoading,
  username,
  weekTitle,
  module,
  /*isFileLoading,*/
  modulesLength,
  progress,
  currentModuleIndex,
  onNextWeek,
  onPrevWeek,
  onOpenPersonalSuccessForm,
  openCurriculumHtmlForm,
  openCurriculumPDFForm,
  openRecipesHtmlForm,
  onStartCall,
  onSendData,
  /*onOpenDocument,*/
  currentModuleId,
  hasCohortStarted,
  isWeight,
}) => {
  const { t } = useTranslation();
  const isSmallMobile = useScreenView({ width: 670 });

  return (
    <>
      {username && <Title>Hello, {username}</Title>}
      <Plate>
        <div className="flex flex-col tab:flex-row py-[2.4rem] h-full">
          <aside className="flex flex-col px-[2.4rem] min-w-[33.5rem] h-full">
            <WeekSwitch
              isLoading={isLoading}
              moduleIndex={currentModuleIndex}
              onNext={onNextWeek}
              onPrev={onPrevWeek}
              title={weekTitle}
              modulesQTY={modulesLength}
            />
            <div className="divider bg-alto w-full h-px"></div>
            <YourProgress
              module={module}
              progress={progress}
              isLoading={isLoading}
              hasCohortStarted={hasCohortStarted}
            />
          </aside>
          <div className="divider bg-alto h-full w-px"></div>
          <section className="h-full w-full flex flex-col xl:px-[4rem] px-[2rem] justify-start">
            <ModuleDescription
              isLoading={isLoading}
              data={module?.moduleDescription}
              hasCohortStarted={hasCohortStarted}
            />
            {module?.webinar.map((item) =>
              item.data && item.data.url ? (
                <WebinarSection
                  key={item.id}
                  isLoading={isLoading}
                  onStartCall={() => onStartCall(item)}
                  isSmallMobile={isSmallMobile}
                  data={item}
                />
              ) : null,
            )}
            {module?.curriculum.map((item) => (
              <CurriculumSection
                key={item.id}
                isLoading={isLoading}
                sendProgress={() => onStartCall(item)}
                isSmallMobile={isSmallMobile}
                data={item}
                currentModuleId={currentModuleId}
                // onOpen={openCurriculumPDFForm}
                onOpen={
                  item.data.url && item.data.url.endsWith('.pdf')
                    ? openCurriculumPDFForm
                    : openCurriculumHtmlForm
                }
              />
            ))}
            {!hasCohortStarted &&
              module?.personalSuccess.map((item) => (
                <PersonalSuccessSection
                  key={item.id}
                  isLoading={isLoading}
                  onOpen={onOpenPersonalSuccessForm}
                  isSmallMobile={isSmallMobile}
                  data={item}
                  hasCohortStarted={hasCohortStarted}
                />
              ))}
            {!hasCohortStarted &&
              module?.activity.map((item) => (
                <ActivitySection
                  key={item.id}
                  isLoading={isLoading}
                  isSmallMobile={isSmallMobile}
                  data={item}
                  progress={progress?.activity ? progress?.activity[0] : null}
                  onSendData={(value) => onSendData(item, value)}
                  hasCohortStarted={hasCohortStarted}
                />
              ))}
            {!hasCohortStarted &&
              module?.dietitian.map((item) => (
                <WebinarSection
                  key={item.id}
                  isLoading={isLoading}
                  onStartCall={() => onStartCall(item)}
                  isSmallMobile={isSmallMobile}
                  data={item}
                />
              ))}
            {!hasCohortStarted &&
              module?.weight.map((item) => (
                <WeightSection
                  key={item.id}
                  isLoading={isLoading}
                  data={item}
                  moduleDescription={module?.moduleDescription}
                  onSendData={(value) => onSendData(item, value)}
                  progress={progress?.weight ? progress?.weight[0] : null}
                  hasCohortStarted={hasCohortStarted}
                  isWeight={isWeight}
                />
              ))}
            {module?.video.map((item) => (
              <VideoSection
                key={item.id}
                data={item}
                onSendData={(value) => onSendData(item, value)}
              />
            ))}
            {!hasCohortStarted &&
              module?.recipe.map((item) => (
                <CurriculumSection
                  key={item.id}
                  isLoading={isLoading}
                  sendProgress={() => onStartCall(item)}
                  isSmallMobile={isSmallMobile}
                  data={item}
                  currentModuleId={currentModuleId}
                  onOpen={openRecipesHtmlForm}
                />
              ))}
          </section>
        </div>
      </Plate>
    </>
  );
};

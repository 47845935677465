import { FC, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import settingsSvg from '../../assets/settings.svg';

import { ReactComponent as ExitIcon } from '../../assets/exit.svg';
import logoutSvg from '../../assets/logout.svg';
import { useOutsideClick } from '../../hooks/useOutsideClick';

import type { ListItem } from './types';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
import { Trans, useTranslation } from 'react-i18next';
import { Avatar } from '../Avatar/Avatar';

const DropdownContent: FC<{ listItems: ListItem[]; onClick: () => void }> = ({
  listItems = [],
  onClick,
}) => {
  if (!listItems?.length) return <span>No actions available</span>;

  return (
    <ul className="bg-white shadow absolute w-[22rem] mt-4 py-[.8rem] right-8 lg:right-auto rounded-xl z-30">
      {listItems.map(({ title, id, onPress, icon }, i) => {
        const handleClick = () => {
          onClick?.();
          onPress();
        };

        return (
          <li
            className="flex px-[1.8rem] py-4 cursor-pointer font-nunito hover:bg-manatee hover:bg-opacity-10"
            key={id}
            onClick={handleClick}
          >
            <img className="w-[2rem]" src={icon} alt={title} />
            <span className="text-[1.3rem] leading-8 font-semibold tracking-wider px-4">
              {title}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export const TopAvatarView: FC<{
  isLoading?: boolean;
  username: string;
  logoutNative: () => void;
}> = ({ isLoading, username, logoutNative }) => {
  const { t } = useTranslation();
  const menu = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  useOutsideClick(menu, () => setIsMenuOpen(false));

  const listItems = [
    /*{
      id: 1,
      title: t('settings'),
      onPress: () => history.push('/settings'),
      icon: settingsSvg,
    },*/
    {
      id: 0,
      title: t('log-out'),
      onPress: () => setIsModalOpen(true),
      icon: logoutSvg,
    },
  ];

  return (
    <>
      <div ref={menu}>
        {isLoading && (
          <div className="flex cursor-pointer items-center animate-pulse">
            <div className="bg-lavenderGray  w-[48px] h-[48px] rounded-full flex justify-center items-center text-white"></div>
            <div className="hidden lg:block ml-6 w-[14.6]">
              <div className="bg-lavenderGray rounded-[.8rem] mt-2 mb-3 w-[14.6rem] h-[1.8rem]"></div>
              <div className="bg-lavenderGray rounded-[.8rem] w-[14.6rem] h-6"></div>
            </div>
          </div>
        )}
        {!isLoading && (
          <div
            className="flex cursor-pointer items-center mr-6"
            onClick={() => setIsMenuOpen(true)}
          >
            <div className="text-white">
              <Avatar username={username} size={48} />
            </div>

            <div className="hidden lg:block w-fit ml-6 ">
              <div className="text-1822 font-semibold  font-ambit text-main">{username}</div>
              <div className="text-1419 font-semibold font-ambit text-manatee">Participant</div>
            </div>
          </div>
        )}
        {isMenuOpen && (
          <DropdownContent listItems={listItems} onClick={() => setIsMenuOpen(false)} />
        )}
      </div>
      {isModalOpen && (
        <ConfirmationModal
          onClose={() => setIsModalOpen(false)}
          onConfirm={logoutNative}
          confirmButtonName={'log-out'}
        >
          <div className="w-[4.8rem] h-[4.8rem]">
            <ExitIcon />
          </div>
          <div className="text-center text-1624 text-main font-lato font-bold mt-8 mb-4">
            <Trans i18nKey="logout-confirmation" values={{}}>
              Are you sure
              <br /> you want to Log Out?
            </Trans>
          </div>
        </ConfirmationModal>
      )}
    </>
  );
};

import React, { ChangeEvent, FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import { ReactComponent as WeightIcon } from '../../assets/weight.svg';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ReactComponent as TooltipIcon } from '../../assets/tooltip.svg';
import { ReactComponent as StatisticIcon } from '../../assets/statistic.svg';

import { Button } from '../Button/Button';
import { WeightSkeleton } from './WeightSection.skeleton';
import { useWaitForData } from '../../utilities/useWaitForData';
import { InputField } from '../InputField/InputField';
import { WeightModal } from './WeightModal';
import { toast } from 'react-toastify';
import Tooltip from '../Tooltip/Tooltip';
import { WeightHistoryModal } from './WeightHistoryModal';
import { WeightRecord } from './WeightRecord';
import { WeightSectionProps, WithingsWeights } from './Types';
import { API_BASE_URL } from '../../env';
import { format, parse } from 'date-fns';

export const getDefaultHeaders = () => ({
  'Content-Type': 'application/json',
  authorization: 'Bearer ' + localStorage.getItem('idToken'),
});

export const WeightSection: FC<WeightSectionProps> = ({
  isSmallMobile,
  data,
  isLoading: loading,
  onSendData,
  progress,
  hasCohortStarted,
  isWeight,
  moduleDescription: { start: startDate, end: endDate },
}) => {
  const [isWeightModalOpen, setIsWeightModalOpen] = useState<boolean>(false);
  const [isStatisticModalOpen, setIsStatisticModalOpen] = useState<boolean>(false);
  const [manualWeight, setManualWeight] = useState<string | null>();
  const [weights, setWeights] = useState<WithingsWeights[]>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);

  async function performGetParticipantWeights() {
    if (!startDate || !endDate) return;

    const getFormattedDate = (date: string) => {
      const dateToParse = new Date(date);

      const parsedDate = parse(
        dateToParse.toISOString(),
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
        new Date(),
      );
      const offset = new Date().getTimezoneOffset() / 60;
      parsedDate.setHours(parsedDate.getHours() + offset);
      const formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

      return formattedDate;
    };

    try {
      const url = `${API_BASE_URL}` + 'graphql';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          ...getDefaultHeaders(),
        },
        body: JSON.stringify({
          query: `query GetParticipantWeights($startDate: String!, $endDate: String!) {
              getParticipantWeights(startDate: $startDate, endDate: $endDate) {
                  id
                  createdAt
                  value
                  unit
              }
          }`,
          variables: {
            startDate: getFormattedDate(startDate),
            endDate: getFormattedDate(endDate),
          },
        }),
      }).then((response) => response.json());
      if (response) {
        setWeights(response.data.getParticipantWeights);
      } else {
      }
      return response;
    } catch (error) {
      console.error('Error performing GetParticipantWeights operation:', error);
      return { error: 'Failed to GetParticipantWeights operation', details: error };
    }
  }
  useEffect(() => {
    performGetParticipantWeights();
  }, []);

  useEffect(() => {
    const value = progress?.metadata.value;
    setManualWeight(value);
  }, [progress?.metadata.value]);

  const sendWeight = () => {
    if (!manualWeight) return;
    const finalValue = (+manualWeight).toFixed(1).toString();
    onSendData(finalValue);
    setIsWeightModalOpen(false);
    toast.success('Your current weight has been logged.');
  };

  const placeHolderManualWeight = progress?.completed ? progress.metadata.value + ' lb' : undefined;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if ('0' === value) {
      return;
    }
    setManualWeight(value);
    20 < +value && 1000 >= +value ? setIsDisabled(false) : setIsDisabled(true);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    // Block characters +, -, and e
    if (event.key === '+' || event.key === '-' || event.key === 'e' || event.key === 'E') {
      event.preventDefault();
    }
  };

  const isLoading = useWaitForData([data?.title, data?.data], loading);
  if (!data && !isLoading) return null;

  if (isLoading) return <WeightSkeleton />;

  return (
    <div
      data-testid="WeightSection"
      className={clsx('xl:fit flex flex-col justify-between pt-[2rem] border-b border-b-alto')}
    >
      <div className="flex flex-col justify-between">
        <div className="pr-3">
          <div className="flex md:flex-row justify-between md:items-center">
            <div className="flex items-center">
              <WeightIcon />
              <div className="ml-5 text-outerSpace text-1822">{data?.title}</div>
              {!isWeight && (
                <Tooltip
                  content={
                    <div className="flex flex-col w-80">
                      <span className="mb-3 text-[13px]">Log your weight</span>
                      <span>
                        {' '}
                        Log your current weight while your scale is being shipped. Once it arrives
                        and is set up, this process will be automated. Assistance with the set up
                        will be available.
                      </span>
                    </div>
                  }
                >
                  <TooltipIcon className="ml-6" />
                </Tooltip>
              )}
              {isWeight && (
                <Tooltip
                  content={
                    <div className="flex flex-col w-80">
                      <span className="mb-3 text-[13px]">Log your weight</span>
                      <p>
                        Make sure to connect with the right Withings user to avoid data issues.
                        Consider reconnecting if the wrong user has already been connected.
                        <br />
                        <br />
                        Follow the
                        <a
                          href="https://cibahealth.com/help-center/how-to-connect-your-weight-scale-to-ciba-health/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-semibold mx-2 underline"
                        >
                          How To
                        </a>
                        guide to know more about Withings connection Dos and Don’ts.
                      </p>
                    </div>
                  }
                >
                  <TooltipIcon className="ml-6" />
                </Tooltip>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-between flex-wrap mt-6 sm:mt-0 ml-0">
          {!isWeight && placeHolderManualWeight && (
            <span className="text-2430 ml-3 mt-0 sm:mt-12">{placeHolderManualWeight}</span>
          )}
          {isWeight && weights.length ? (
            <span className="ml-3 mt-0 sm:mt-9 text-main ">
              <WeightRecord
                weight={weights[0]?.value}
                prevWeight={weights[1]?.value}
                createdAt={weights[0]?.createdAt || ''}
                size="md"
                unit={weights[0].unit}
              />
            </span>
          ) : (
            <>
              {isWeight && (
                <div
                  className="flex items-center rounded-2xl py-2 px-6 my-3 sm:my-9 w-fit mt-0 sm:mt-9"
                  style={{ background: '#F1F5F8' }}
                >
                  <span className="mr-4 text-2632">💡</span>
                  <span className="text-main font-montserrat text-1522">
                    <span className="font-semibold">Step on scale</span> to measure your weight.
                  </span>
                </div>
              )}
              {!isWeight && !placeHolderManualWeight && (
                <div
                  className="flex items-center rounded-2xl py-2 px-6 my-3 sm:my-9 w-fit mt-0 sm:mt-9"
                  style={{ background: '#F1F5F8' }}
                >
                  <span className="mr-4 text-2632">☝️</span>
                  <span className="text-main font-montserrat text-1522">
                    Don’t forget to add your weight for this week.
                  </span>
                </div>
              )}
            </>
          )}
          <div className="w-full lg:w-auto flex justify-between">
            <div></div>
            <div className="py-9 pl-6">
              {isWeight ? (
                <Button
                  onClick={() => setIsStatisticModalOpen(true)}
                  width={isSmallMobile ? '15rem' : '15rem'}
                  outlined
                >
                  <div className="flex items-center">
                    <StatisticIcon />
                    <span className="ml-3">{'Weight History'}</span>
                  </div>
                </Button>
              ) : (
                <Button
                  // disabled={isDisabled}
                  onClick={() => setIsWeightModalOpen(true)}
                  width={isSmallMobile ? '15rem' : '15rem'}
                  {...(placeHolderManualWeight ? { outlined: true } : {})}
                >
                  <div className="flex items-center">
                    {placeHolderManualWeight ? (
                      <>
                        <EditIcon />
                        <span className="ml-3">{'Edit'}</span>
                      </>
                    ) : (
                      <>
                        <PlusIcon />
                        <span className="ml-3">{'Add'}</span>
                      </>
                    )}
                  </div>
                </Button>
              )}

              {isWeightModalOpen && (
                <WeightModal
                  onClose={() => setIsWeightModalOpen(false)}
                  onConfirm={sendWeight}
                  headerIcon={<WeightIcon />}
                  isDisabled={isDisabled}
                >
                  <div className="w-[32rem] sm:w-[42rem] ">
                    <div className="relative overflow-hidden w-full h-full">
                      <div className="flex flex-col">
                        <div className="w-full text-1624 text-main font-lato font-semibold mt-2 mb-8">
                          Add your current weight, lb.
                        </div>
                        <div className="m-1">
                          <InputField
                            type="number"
                            classNames={{
                              container: 'mx-r',
                              input:
                                'rounded-[3.2rem] text-[1.4rem] placeholder:text-independence py-[0.8rem] pl-[2rem] h-[4.2rem] my-1',
                              label: 'text-[1.6rem]',
                            }}
                            placeholder="Pounds"
                            onChange={handleChange}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            onKeyDown={handleKeyDown}
                            disabledInput={hasCohortStarted}
                            min={1}
                            value={manualWeight || ''}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </WeightModal>
              )}

              {isStatisticModalOpen && (
                <WeightHistoryModal
                  onClose={() => setIsStatisticModalOpen(false)}
                  isHistoryLoading={isHistoryLoading}
                >
                  <div className="w-[32rem] sm:w-[42rem] ">
                    <div className="relative overflow-hidden w-full h-full">
                      {weights.length ? (
                        <div className="flex flex-col h-[18rem] overflow-y-scroll">
                          {weights.map((data, index) => (
                            <WeightRecord
                              index={index}
                              weight={data.value}
                              prevWeight={weights[index + 1]?.value || undefined}
                              createdAt={data.createdAt}
                              size="sm"
                              unit={data.unit}
                            />
                          ))}
                        </div>
                      ) : (
                        <div className="text-independence">
                          <p className="">
                            Nothing logged yet. Step on scale to measure your weight. Once done, the
                            value will be displayed here automatically.
                          </p>
                          <p className="mt-4">
                            Make sure to connect with the right Withings user to avoid data issues.
                            Consider reconnecting if the wrong user has already been connected.
                          </p>
                          <p className="mt-4">
                            Follow the
                            <a
                              href="https://cibahealth.com/help-center/how-to-connect-your-weight-scale-to-ciba-health/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="font-semibold mx-2 underline"
                            >
                              How To
                            </a>
                            guide to know more about Withings connection Dos and Don’ts.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </WeightHistoryModal>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { FC, useEffect, useState } from 'react';
import { DeviceFormPageView } from './DeviceForm.view';
import { useAuthState } from '../../context/auth/authContext';
import { FieldValues } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutationSendHandOut } from './../../graphql/mutation/sendHandOut';
import { useQueryTimezones } from '../../graphql/query/getTimeZone';

export const DeviceFormPage: FC = () => {
  const authState = useAuthState();
  const isLoading = !!authState.isLoading;
  const [error, setError] = useState<string | undefined>();
  const { mutate: SendHandOut, isLoading: mutProgressLoading } = useMutationSendHandOut();

  const signInClicked = async ({ time_zone, password_hang }: FieldValues) => {
    const dict = {
      timeZone: time_zone,
      hangPass: password_hang,
      gaSource: window.location.hostname,
    };
    const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

    try {
      SendHandOut({
        data: dict,
      });
      toast.success('Connection ok you will be redirected to the next page');
      await delay(5000);
      const url: any = 'device';
      const blank: any = '_self';
      window.open(url, blank);
    } catch (err: any) {
      toast.error('Device added');
    }
  };

  const { timeZonesLoading, timeZones } = useQueryTimezones();
  const isLoadingpage = timeZonesLoading;
  return (
    <DeviceFormPageView
      isLoading={isLoadingpage}
      error={error}
      signInClicked={signInClicked}
      time_zones={timeZones}
    />
  );
};

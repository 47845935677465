import clsx from 'clsx';
import { FC, useEffect, useLayoutEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { validatePassword } from '../../utilities/validatePassword';
import { Spinner } from '../Spinner/Spinner';
import { InputField } from './../../components/InputField/InputField';

export type NewPasswordFormProps = {
  onSubmit: (data: FieldValues) => void;
  isLoading: boolean;
  error?: string;
};

export const NewPasswordForm: FC<NewPasswordFormProps> = ({ onSubmit, isLoading, error }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm();

  useLayoutEffect(() => {
    document.title = 'Create password';
  }, []);

  const [serverError, setServerError] = useState<string | undefined>(error);

  const passwordValue = watch('password');

  useEffect(() => {
    setServerError(error);
  }, [error]);

  const password = register('password', {
    validate: validatePassword,
  });
  const confirmPassword = register('confirmPassword', {
    validate: (value) => value === passwordValue || (t('Passwords do not match') as string),
  });

  const disabled = isLoading || !isDirty;

  return (
    <div className="">
      <h1 className="text-main font-heptaSlab text-2030 font-bold mb-[5rem] text-center">
        Create password
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="">
        <InputField
          classNames={{
            container: 'mb-[1.5rem]',
            input: 'rounded-[3.2rem] text-1324 p-[1.2rem] placeholder:text-lavenderGray',
          }}
          placeholder={t('new-password') as string}
          type="password"
          error={errors.password?.message as string}
          {...password}
          onChange={(e) => {
            password.onChange(e);
            setServerError(undefined);
          }}
        />
        <InputField
          classNames={{
            container: 'mb-[1.5rem]',
            input: 'rounded-[3.2rem] text-1324 p-[1.2rem] placeholder:text-lavenderGray',
          }}
          placeholder={t('confirm-password') as string}
          type="password"
          error={errors.confirmPassword?.message as string}
          {...confirmPassword}
          onChange={(e) => {
            confirmPassword.onChange(e);
            setServerError(undefined);
          }}
        />
        <button
          type="submit"
          disabled={disabled}
          className={clsx(
            'bg-main text-white w-full h-[4.8rem] rounded-[2.4rem] text-1313 font-ambit mb-[1rem] uppercase tracking-[3px]',
            {
              'opacity-50': disabled,
            },
          )}
        >
          {isLoading ? <Spinner /> : t('save')}
        </button>
        {serverError && <div className="text-error">{serverError}</div>}
      </form>
    </div>
  );
};

import createReducer, { makeAction } from '../../utilities/createReducer';
import {
  Action,
  ActionCleanup,
  ActionConversationJoined,
  ActionConversationLeft,
  ActionSetClient,
  ActionSetIdentity,
  ActionSetMessageCounter,
  ActionTwilioConnection,
  ChatActions,
  ChatStateContextType,
  Status,
  StatusString,
} from './types';

export const initState: ChatStateContextType = {
  statusString: StatusString.Connecting,
  selectedConversation: null,
  chatLoading: false,
  identity: '',
  status: Status.Default,
  conversations: [],
  chatClient: null,
  messageCounter: 0,
};

const reducer = createReducer<ChatStateContextType, Action, ChatActions>(initState, {
  [ChatActions.SetClient]: (chatClient) => ({ chatClient }),
  [ChatActions.TwilioConnection]: (state) => {
    if (state === 'connecting') {
      return { chatLoading: true, statusString: StatusString.Connecting, status: Status.Default };
    }
    if (state === 'connected') {
      return { chatLoading: false, statusString: StatusString.Connected, status: Status.Success };
    }
    if (state === 'disconnecting') {
      return {
        chatLoading: true,
        statusString: StatusString.Disconnecting,
        status: Status.Default,
      };
    }
    if (state === 'disconnected') {
      return {
        chatLoading: false,
        statusString: StatusString.Disconnected,
        status: Status.Warning,
      };
    }
    if (state === 'denied') {
      return { chatLoading: false, statusString: StatusString.Failed, status: Status.Error };
    }
    return {};
  },
  [ChatActions.ConversationJoined]: (conversation, state) => ({
    selectedConversation:
      state.conversations.length === 0 ? conversation : state.selectedConversation,
    conversations: [...state.conversations, conversation],
  }),

  [ChatActions.ConversationLeft]: (conversation, state) => ({
    conversations: state.conversations.filter((c) => c.sid !== conversation.sid),
  }),
  [ChatActions.SetMessageCounter]: (messageCounter) => ({ messageCounter }),
  [ChatActions.SetIdentity]: (identity) => ({ identity }),
  [ChatActions.Cleanup]: () => initState,
});

export function chatContextReducer(state: ChatStateContextType, action: Action) {
  return reducer(state, action);
}

export const dispatchActions = {
  twilioConnection: makeAction<ActionTwilioConnection>(ChatActions.TwilioConnection),
  conversationJoined: makeAction<ActionConversationJoined>(ChatActions.ConversationJoined),
  conversationLeft: makeAction<ActionConversationLeft>(ChatActions.ConversationLeft),
  setClient: makeAction<ActionSetClient>(ChatActions.SetClient),
  setMessageCounter: makeAction<ActionSetMessageCounter>(ChatActions.SetMessageCounter),
  setIdentity: makeAction<ActionSetIdentity>(ChatActions.SetIdentity),
  cleanup: makeAction<ActionCleanup>(ChatActions.Cleanup),
};

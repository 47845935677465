/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Layout } from '../components/Layout/Layout';
import { routesPath } from '../constants/routes';
import { Dashboard } from '../page/Dashboard/Dashboard';
import { LoginPage } from '../page/Login/Login';
import { ResetPassword } from '../page/ResetPassword/ResetPassword';
import { ChatPage } from '../page/Chat/Chat';
import { SignUp } from '../page/SignUp/SignUp';
import { DevicePage } from '../page/Device/Device';
import { DeviceFormPage } from '../page/DeviceForm/DeviceForm';
import { PrivateRoute, RedirectToDashboard, RouteIfLoggedIn } from './PrivatRoute';
import { RpmPage } from '../page/Rpm/Rpm';
import InviteSolera from '../page/InviteSolera/InviteSolera';
import InviteSoleraN from '../page/InviteSoleraN/InviteSolera';
import { datadogRum } from '@datadog/browser-rum';

const isProd = 'prod' === process.env.REACT_APP_ENV;
datadogRum.init({
  applicationId: 'afa364c1-92e1-412c-a75f-12adb4faf557',
  clientToken: 'pub0dbd966d3340fd717440a1453fe653e2',
  site: 'datadoghq.com',
  service: 'participant-uiw',
  env: isProd ? 'prod' : 'dev', //specify env to use
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: isProd ? 100 : 0,
  sessionReplaySampleRate: isProd ? 100 : 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

export const Router: FC = () => {
  return (
    <Switch>
      <Route path={routesPath.join} component={SignUp} />
      <RouteIfLoggedIn path={routesPath.login} component={LoginPage} />
      <RouteIfLoggedIn path={routesPath.resetPassword} component={ResetPassword} />
      <PrivateRoute path={routesPath.dashboard}>
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute path={routesPath.groupChat}>
        <Layout removedContentPadding>
          <ChatPage />
        </Layout>
      </PrivateRoute>
      <PrivateRoute path={routesPath.device}>
        <Layout removedContentPadding>
          <DevicePage />
        </Layout>
      </PrivateRoute>
      <PrivateRoute path={routesPath.deviceForm}>
        <Layout removedContentPadding>
          <DeviceFormPage />
        </Layout>
      </PrivateRoute>
      <PrivateRoute path={routesPath.add_device}>
        <Layout removedContentPadding>
          <RpmPage />
        </Layout>
      </PrivateRoute>
      <Route path={routesPath.invite_solera} component={InviteSoleraN} />
      <Route path={routesPath.invite_soleran} component={InviteSolera} />
      <RedirectToDashboard />
    </Switch>
  );
};

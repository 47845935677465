import { FC } from 'react';
import { CheckEligibility } from '../../components/CheckEligibility/CheckEligibility';
import { JoinLayout } from '../../components/JoinLayout/JoinLayout';
import { SignUpForm } from '../../components/SignUpForm/SignUpForm';
import { Option } from '../../types';
import { SignUpFinish } from '../../components/SignUpFinish/SignUpFinish';

type SignUpViewProps = {
  init: boolean;
  loading: boolean;
  form: any;
  next: () => void;
  onSelect: (option: Option) => void;
  companiesOptions: Option[];
  step: string;
  setField: (
    field: 'firstName' | 'lastName' | 'email' | 'groupId' | 'memberId' | 'startDate' | 'companyId',
    value: string,
  ) => void;
  signup: (formData: any) => void;
};

export const SignUpView: FC<SignUpViewProps> = ({
  init,
  loading,
  form,
  next,
  onSelect,
  companiesOptions,
  step,
  setField,
  signup,
}) => {
  return (
    <JoinLayout>
      {init && step === 'check-eligibility' && (
        <CheckEligibility
          isLoading={loading}
          isDisabled={!form.companyId}
          onNext={next}
          onSelect={onSelect}
          companiesOptions={companiesOptions}
        />
      )}
      {init && step === 'sign-up' && (
        <SignUpForm
          isLoading={loading}
          setFormFields={setField}
          onSubmit={(formData) => signup(formData)}
          form={form}
          companiesOptions={companiesOptions}
        />
      )}
      {init && step === 'finish' && <SignUpFinish email={form.email} />}
    </JoinLayout>
  );
};

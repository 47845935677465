import { useQuery } from '../../hooks/useQuery';
import { GET_HANG_LINK } from './constants';
import { GraphQLTypes, InputType, Selector, ValueTypes } from '../../zeus';

const Url: ValueTypes['HangoutUrl'] = Selector('HangoutUrl')({
  url: true,
});

export type HangoutUrlDatatype = InputType<GraphQLTypes['HangoutUrl'], typeof Url>;
export const useQueryHangLink = () => {
  const { isFetching, data } = useQuery(
    [GET_HANG_LINK],
    { getHangLink: Url },
    {
      operationName: GET_HANG_LINK,
    },
  );
  const HangoutUrlDatatype = (data?.getHangLink || {}) as HangoutUrlDatatype;
  return { urlLoading: isFetching, url: HangoutUrlDatatype };
};

import { FC, useMemo, useState, useEffect, useCallback, useLayoutEffect } from 'react';

import {
  ProgramModuleType,
  useQueryParticipantProgramModules,
} from '../../graphql/query/getParticipantProgramModules';
import {
  transformModuleData,
  transformUserProgressData,
} from '../../utilities/transformModuleData';
import * as H from 'history';
import { useQueryModuleProgress } from '../../graphql/query/getModuleProgress';
import { DashboardView } from './Dashboard.view';
import { PersonalSuccess } from '../../components/PersonalSuccess/PersonalSuccess';
import { useQueryMe } from '../../graphql/query/getMe';
import { useMutationMakeProgress } from '../../graphql/mutation/makeProgress';
import { useAuthState } from '../../context/auth/authContext';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { Layout } from '../../components/Layout/Layout';
import moment from 'moment';
import { CurriculumHtmlSection } from '../../components/CurriculumHtmlSection/CurriculumHtmlSection';
import { CurriculumPDFSection } from '../../components/CurriculumPDFSection/CurriculumPDFSection';
import { datadogRum } from '@datadog/browser-rum';

const makeChangeSearchParam = (history: H.History) => (key: string, value?: string) => {
  const searchParams = new URLSearchParams(location.search);
  if (value) {
    searchParams.set(key, value);
  } else {
    searchParams.delete(key);
  }

  history.replace({
    search: searchParams.toString(),
  });
};

export const Dashboard: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const urlForm = searchParams.get('form');
  const currentModuleId = searchParams.get('module');
  const [startedAt, setStartedAt] = useState('');
  const [startDate, setStartDate] = useState('');
  const [startZoomDate, setStartZoomDate] = useState('');
  const [zoomLink, setZoomLink] = useState('');
  const [showProgram, setShowProgram] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [mrn, setMrn] = useState('');

  const changeSearchParam = makeChangeSearchParam(history);

  const { username } = useAuthState();

  const [currentModuleIndex, setCurrentModuleIndex] = useState(0);
  const [currentModule, setCurrentModule] = useState<ProgramModuleType | null>(null);
  const [docTitle, setDocTitle] = useState('Dashboard');

  const { me, meLoading, refetchMe } = useQueryMe();
  function deleteCookie(name: string) {
    document.cookie =
      name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.cibahealth.com; path=/; secure';
  }

  useEffect(() => {
    if (me.id) {
      datadogRum.setUser({
        id: me.id,
        name: `${me.firstName} ${me.lastName}`,
        email: me.email,
        chatIdentity: me.chatIdentity,
        groupId: me.groupId,
      });
    }
  }, [me.id]);

  useEffect(() => {
    const checkAuthCookies = () => {
      return document.cookie.indexOf('login=') === -1;
    };
    if (!checkAuthCookies()) {
      deleteCookie('login');
      deleteCookie('pass');
      setIsAuthenticated(false);
    }
  }, [location.pathname]);

  const { participantProgramModulesLoading, participantProgramModules } =
    useQueryParticipantProgramModules({
      participantId: me.id,
    });
  const { moduleProgressLoading, moduleProgress, refetchModuleProgress } = useQueryModuleProgress({
    participantId: me.id,
    programModuleId: currentModule?.id,
  });

  const { mutate: makeProgress, isLoading: mutProgressLoading } = useMutationMakeProgress({
    onSuccess() {
      refetchModuleProgress();
    },
  });

  const isLoading =
    participantProgramModulesLoading || moduleProgressLoading || mutProgressLoading || meLoading;

  const module = useMemo(() => transformModuleData(currentModule), [currentModule]);
  const progress = useMemo(() => transformUserProgressData(moduleProgress), [moduleProgress]);
  const personalSuccessModule = useMemo(() => {
    const formId = searchParams.get('form');
    const mod = _.find(participantProgramModules, { id: currentModuleId });
    return mod?.sections.find((m) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (m.data.__typename === 'PersonalSuccessType' && m.data.formId === formId) {
        return m;
      }
    });
    // return mod?.sections.find((m) => m.data.__typename === 'PersonalSuccessType');
  }, [participantProgramModules, currentModuleId, searchParams]);

  const curriculumHTMLModule = useMemo(() => {
    const htmlId = searchParams.get('html');
    const mod = _.find(participantProgramModules, { id: currentModuleId });
    return mod?.sections.find((m) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (m.data.__typename === 'CurriculumType' && m.id === htmlId) {
        return m;
      }
    });
    // return mod?.sections.find((m) => m.data.__typename === 'PersonalSuccessType');
  }, [participantProgramModules, currentModuleId, searchParams]);

  const curriculumPDFModule = useMemo(() => {
    const pdfId = searchParams.get('pdf');
    const mod = _.find(participantProgramModules, { id: currentModuleId });
    return mod?.sections.find((m) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (
        m.data.__typename === 'CurriculumType' &&
        m.id === pdfId &&
        m.data.url?.endsWith('.pdf')
      ) {
        return m;
      }
    });
    // return mod?.sections.find((m) => m.data.__typename === 'PersonalSuccessType');
  }, [participantProgramModules, currentModuleId, searchParams]);

  const recipesHTMLModule = useMemo(() => {
    const recipeId = searchParams.get('recipe');
    const mod = _.find(participantProgramModules, { id: currentModuleId });
    return mod?.sections.find((m) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (m.data.__typename === 'RecipeType' && m.id === recipeId) {
        return m;
      }
    });
    // return mod?.sections.find((m) => m.data.__typename === 'PersonalSuccessType');
  }, [participantProgramModules, currentModuleId, searchParams]);

  useLayoutEffect(() => {
    switch (me.soleraProgramId) {
      case 'IBC':
        setDocTitle('Intensive Behavioral Counseling Program');
        break;
      case 'NDPP':
        setDocTitle('National Diabetes Prevention Program');
        break;
      case 'HWM':
        setDocTitle('Healthy Weight Management Program');
        break;
      default:
        setDocTitle('Dashboard');
    }
    document.title = docTitle;
  }, [me, docTitle]);

  const personalSuccessProgress = useMemo(() => {
    // HOTFIX
    return moduleProgress?.sections?.find((m) => m?.sectionId === personalSuccessModule?.id);
    // return moduleProgress?.sections?.find((m) => m?.sectionType === 'weight_type');
  }, [moduleProgress, personalSuccessModule]);

  const changeWeek = useCallback(
    (newIndex) => {
      setCurrentModuleIndex(newIndex);
      // setCurrentModuleIndex(0);
      setCurrentModule(participantProgramModules[newIndex]);
      changeSearchParam('module', participantProgramModules[newIndex].id);
    },
    [participantProgramModules],
  );

  const openPersonalSuccessForm = useCallback(
    async (formId: string) => {
      try {
        const meData = await refetchMe();

        if (!meData?.data?.me?.medicalRecord) {
          return;
        }
        setMrn(meData?.data?.me.medicalRecord);
        changeSearchParam('form', formId);
      } catch (e) {
        console.log(e);
      }
    },
    [me],
  );

  const openCurriculumHtmlForm = useCallback(
    async (url: string) => {
      try {
        changeSearchParam('html', url);
      } catch (e) {
        console.log(e);
      }
    },
    [me],
  );

  const openCurriculumPDFForm = useCallback(
    async (url: string) => {
      try {
        changeSearchParam('pdf', url);
      } catch (e) {
        console.log(e);
      }
    },
    [me],
  );

  const openRecipesHtmlForm = useCallback(
    async (url: string) => {
      try {
        changeSearchParam('recipe', url);
      } catch (e) {
        console.log(e);
      }
    },
    [me],
  );

  const finishForm = useCallback(
    (sectionId: string) => async (responseId?: string) => {
      await makeProgress({
        programModuleId: currentModule?.id,
        sectionId,
        data: {
          responseId,
        },
      });
      if (!responseId) return;
      changeSearchParam('form');
    },
    [currentModule],
  );

  const sendData = useCallback(
    (sectionId: string, sectionValue?: string, isStartedPersonalSuccess?: boolean) => async () => {
      if (isStartedPersonalSuccess) {
        return;
      }
      await makeProgress({
        programModuleId: currentModule?.id,
        sectionId,
        data: {
          value: `${sectionValue}`,
        },
      });
    },
    [currentModule],
  );

  const sendProgress = useCallback(
    (sectionId: string, isStartedPersonalSuccess?: boolean) => async () => {
      if (isStartedPersonalSuccess) {
        return;
      }
      await makeProgress({
        programModuleId: currentModule?.id,
        sectionId,
      });
    },
    [currentModule],
  );
  const module0 = participantProgramModules[0];
  // Redirect if program not started
  useEffect(() => {
    setStartedAt(module0?.startedAt);
    const sections = module0?.sections;
    const zoomSection = sections?.find((obj) => obj.type === 'coaching_call');
    setStartDate(module0?.startedAt);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setStartZoomDate(zoomSection?.data.startedAt);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setZoomLink(zoomSection?.data.url);
    if (moment().diff(module0?.startedAt, 'second') < 0) {
      //hotfix
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('module', module0.id);
      history.replace({
        search: searchParams.toString(),
      });
      setShowProgram(true);
    }
  }, [participantProgramModules]);

  useEffect(() => {
    if (showProgram) {
      localStorage.setItem('showProgram', 'true');
    } else {
      localStorage.setItem('showProgram', 'false');
    }
  }, [showProgram]);

  useEffect(() => {
    setMrn(me?.medicalRecord || '');
  }, [me]);

  useEffect(() => {
    if (participantProgramModules && !currentModuleId) {
      let currentModule;
      participantProgramModules.forEach((module, index) => {
        if (module.current) {
          currentModule = module;
          setCurrentModuleIndex(index);
        }
      });
      const mod = currentModule || participantProgramModules[participantProgramModules.length - 1];
      if (!mod) return;
      setCurrentModule(mod);
      if (showProgram) {
        changeSearchParam('module', module0?.id);
      } else {
        changeSearchParam('module', mod?.id);
      }
    }

    if (participantProgramModules && currentModuleId) {
      const mod = _.find(participantProgramModules, { id: currentModuleId });
      const index = _.findIndex(participantProgramModules, { id: currentModuleId });
      if (!mod) return;
      setCurrentModuleIndex(index);
      setCurrentModule(mod);
      if (index) {
        localStorage.setItem('index', String(index));
      }
      if (showProgram) {
        changeSearchParam('module', module0?.id);
      }
    }
  }, [JSON.stringify(participantProgramModules), currentModuleId, showProgram]);
  return (
    <Layout>
      {/*{showProgram && (
        <StartProgramView
          startDate={startDate}
          startZoomDate={startZoomDate}
          zoomLink={zoomLink}
          link={window.location.origin + window.location.pathname}
        />
      )}*/}
      {searchParams.get('form') && personalSuccessModule?.id && mrn ? (
        <PersonalSuccess
          mrn={mrn || ''}
          moduleName={personalSuccessModule?.title || ''}
          onFormReady={sendProgress(
            personalSuccessModule?.id || '',
            !!personalSuccessProgress?.sectionId,
          )}
          code={searchParams.get('form') || ''}
          onClose={() => changeSearchParam('form')}
          onFinish={finishForm(personalSuccessModule?.id || '')}
          username={username}
        />
      ) : searchParams.get('html') ? (
        <CurriculumHtmlSection
          onClose={() => changeSearchParam('html')}
          onOpen={() => console.log('success')}
          username={username}
          data={curriculumHTMLModule?.data || {}}
        />
      ) : searchParams.get('pdf') ? (
        <CurriculumPDFSection
          onClose={() => changeSearchParam('pdf')}
          onOpen={() => console.log('success')}
          username={username}
          data={curriculumPDFModule?.data || {}}
        />
      ) : searchParams.get('recipe') ? (
        <CurriculumHtmlSection
          onClose={() => changeSearchParam('recipe')}
          onOpen={() => console.log('success')}
          username={username}
          data={recipesHTMLModule?.data || {}}
        />
      ) : (
        <DashboardView
          isLoading={isLoading}
          onStartCall={(sec) => sendProgress(sec?.id)()}
          currentModuleIndex={currentModuleIndex}
          module={module}
          modulesLength={participantProgramModules.length}
          onNextWeek={() => changeWeek(currentModuleIndex + 1)}
          onPrevWeek={() => changeWeek(currentModuleIndex - 1)}
          onOpenPersonalSuccessForm={openPersonalSuccessForm}
          personalSuccessForm={urlForm || ''}
          progress={progress}
          username={username}
          weekTitle={currentModule?.shortTitle || ''}
          onSendData={(sec, val) => sendData(sec?.id, val)()}
          currentModuleId={currentModuleId || '1'}
          openCurriculumHtmlForm={openCurriculumHtmlForm}
          openCurriculumPDFForm={openCurriculumPDFForm}
          openRecipesHtmlForm={openRecipesHtmlForm}
          hasCohortStarted={showProgram}
          isWeight={me.isWeight as boolean}
        />
      )}
    </Layout>
  );
};

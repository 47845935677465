import React, { FC } from 'react';
import { Widget } from '@typeform/embed-react';
import clsx from 'clsx';
import { Plate } from '../Plate/Plate';
import { useTranslation } from 'react-i18next';
import { Title } from '../HeaderTitle/HeaderTitle';
import { ReactComponent as ChevronIcon } from '../../assets/chevron.svg';

type PersonalSuccessProps = {
  code: string;
  mrn: string;
  moduleName: string;
  onFormReady: () => void;
  onClose: () => void;
  onFinish: (responseId: string) => void;
  username: string;
};

export const PersonalSuccess: FC<PersonalSuccessProps> = ({
  code,
  mrn,
  moduleName,
  onFormReady,
  onClose,
  onFinish,
  username,
}) => {
  const { t } = useTranslation();
  return (
    <div data-testid="PersonalSuccess" className={clsx('')}>
      {username && <Title>{t('hello-username', { username })}</Title>}
      <Plate>
        <div className="flex items-center text-1822 pl-[3rem] pt-[3rem] pb-[2rem]">
          <button onClick={onClose} className=" items-center flex mr-12">
            <ChevronIcon className="mr-4 w-3" />
            <div className="text-manatee text-1820">{t('Back')}</div>
          </button>
        </div>
        <Widget
          id={code}
          autoFocus={true}
          className="h-[80vh] pl-[2rem] pr-[2rem]"
          onClose={onClose}
          hidden={{ mrn }}
          onReady={onFormReady}
          onSubmit={(event: any) => onFinish(event.response_id)}
        />
      </Plate>
    </div>
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { Button } from '../Button/Button';
import { Modal } from '../Modal/Modal';

type ConfirmationModalType = {
  onClose: () => void;
  onConfirm: () => void;
  confirmButtonName?: string;
  cancelButtonName?: string;
};

export const ConfirmationModal: FC<ConfirmationModalType> = ({
  onClose,
  onConfirm,
  children,
  cancelButtonName = 'cancel',
  confirmButtonName = 'confirm',
}) => {
  const { t } = useTranslation();
  return (
    <Modal onCancel={onClose}>
      <div className="flex flex-col justify-between w-[30rem] sm:w-[42rem] h-[25rem]">
        <div className="flex flex-col justify-between">
          <div onClick={onClose} className="close  cursor-pointer self-end">
            <CloseIcon />
          </div>
          <div className="text-[1.5rem] w-full flex flex-col justify-center items-center mt-8">
            {children}
          </div>
        </div>
        <div>
          <div className="bg-galery h-px mb-10"></div>
          <div className="flex justify-between">
            <div className="w-[20rem] mr-4">
              <Button outlined onClick={onClose}>
                {t(cancelButtonName)}
              </Button>
            </div>
            <div className="w-[20rem]">
              <Button onClick={onConfirm}>{t(confirmButtonName)}</Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

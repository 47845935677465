import { FC } from 'react';

export const RpmSkeleton: FC = () => {
  return (
    <div className="loadingBg rounded-[1rem] flex items-betwen gap-10 h-24 bg-catskillWhite text-gray-800 justify-center cursor-pointer w-full border pt-[1rem] pb-[1rem] pl-[3rem] pr-[3rem] max-w-7xl">
      <div className="flex flex-row items-center tems-center">
        <div className="font-semibold text-[16px] rounded-lg bg-gray-200 h-10 my-auto w-20 mr-10"></div>
        <div className="font-semibold text-[16px] rounded-lg bg-gray-200 h-10 my-auto w-48"></div>
      </div>
      <div className="flex flex-col items-center ml-auto">
        <div className="font-semibold text-[16px] rounded-lg bg-gray-200 h-10 my-auto w-48"></div>
      </div>
    </div>
  );
};

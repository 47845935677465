import { FC } from 'react';
import { format, parse } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import clsx from 'clsx';

type DateRangeLabelProps = {
  from: Date | string;
  to: Date | string;
};

export const DateRangeLabel: FC<DateRangeLabelProps> = ({ from, to }) => {
  const formatStr = 'MM.dd.yyyy';
  const dateFrom = typeof from === 'string' ? parse(from, formatStr, new Date()) : from;
  const dateTo = typeof to === 'string' ? parse(to, formatStr, new Date()) : to;

  dateTo.setSeconds(dateTo.getSeconds() - 1);

  return (
    <div data-testid="DateRangeLabel" className={clsx('w-full flex')}>
      <CalendarIcon className="mr-4" />
      <div className="flex">
        <div className="text-main text-1423 font-ambit">
          {format(utcToZonedTime(dateFrom, 'UTC'), formatStr)}
        </div>
        <div className="text-main mx-2">{'-'}</div>
        <div className="text-main text-1423 font-ambit">
          {format(utcToZonedTime(dateTo, 'UTC'), formatStr)}
        </div>
      </div>
    </div>
  );
};

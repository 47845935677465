import { AmplifyUserSession } from '../libs/cognito.types';

export const saveSession = (session?: AmplifyUserSession) => {
  if (!session) return;
  const accessToken = session?.accessToken.jwtToken;
  const idToken = session?.idToken.jwtToken;
  const refreshToken = session?.refreshToken.jwtToken;
  const expirationTime = session.accessToken.payload.exp;

  window.localStorage.setItem('idToken', `${idToken}`);
  window.localStorage.setItem('accessToken', `${accessToken}`);
  window.localStorage.setItem('refreshToken', `${refreshToken}`);
  window.localStorage.setItem('expired', `${expirationTime}`);
};

export const removeSession = () => {
  window.localStorage.removeItem('idToken');
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('refreshToken');
  window.localStorage.removeItem('expired');
};

import { useMutation } from '../../hooks/useMutation';
import { SEND_HAND_OUT } from './constants';
import { $ } from '../../zeus';
import { CreateMutationResponse, GQLArgs } from '../../types';

export const useMutationSendHandOut = (args?: GQLArgs<CreateMutationResponse<'sendHandOut'>>) => {
  return useMutation(
    [SEND_HAND_OUT],
    {
      sendHandOut: [
        {
          data: $`data`,
        },
        {
          '...on InvalidTimezone': {
            __typename: true,
            message: true,
          },
          '...on HangoutUrlType': {
            __typename: true,
            url: true,
          },
        },
      ],
    },
    {
      errorTypes: [
        {
          entityName: 'sendHandOut',
          __typename: 'InvalidTimezone',
        },
      ],
      operationName: SEND_HAND_OUT,
    },
    args,
  );
};

import { FC, useLayoutEffect } from 'react';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { LoginForm } from '../../components/LoginForm/LoginForm';
import { NewPasswordForm } from '../../components/NewPasswordForm/NewPasswordForm';
import { FieldValues } from 'react-hook-form';
import { Spinner } from '../../components/Spinner/Spinner';

type LoginPageViewProps = {
  newPasswordRequired: boolean;
  showSpinner?: boolean;
  signInClicked: (values: FieldValues) => void;
  isLoading: boolean;
  error: string | undefined;
  newPasswordClicked: (values: FieldValues) => void;
};

export const LoginPageView: FC<LoginPageViewProps> = ({
  newPasswordRequired,
  signInClicked,
  showSpinner,
  isLoading,
  error,
  newPasswordClicked,
}) => {
  return (
    <div className="flex justify-center bg-white md:bg-womanMeeting bg-cover md:bg-[top_left_-30rem] lg:bg-center h-screen items-center">
      <div className="w-full md:w-[34.6rem] h-screen md:h-fit rounded-[2.4rem] bg-catskillWhite shadow-lg">
        <div className="flex justify-center w-full h-screen md:h-fit bg-butterfly bg-cover bg-center">
          <div className="w-[34.6rem] flex flex-col justify-center h-screen md:h-fit p-[3.2rem]">
            <div className="logo absolute md:static w-full top-16 left-0">
              <div className="logo flex justify-center md:mb-[5.6rem]">
                <Logo className="fill-outerSpace" />
              </div>
            </div>
            {showSpinner && (
              <div className="flex justify-center mt-10 mb-4">
                <Spinner opposite />
              </div>
            )}

            {!newPasswordRequired && !showSpinner && (
              <LoginForm onSubmit={signInClicked} isLoading={isLoading} error={error} />
            )}
            {newPasswordRequired && !showSpinner && (
              <NewPasswordForm onSubmit={newPasswordClicked} isLoading={isLoading} error={error} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

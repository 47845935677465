import { FC } from 'react';
import clsx from 'clsx';
import { Button } from '../Button/Button';
import { ReactComponent as CurriculumIcon } from '../../assets/curriculum.svg';
import { ReactComponent as CookBookIcon } from '../../assets/cookbook.svg';
import { useTranslation } from 'react-i18next';
import { TransformModuleDataResult } from '../../types';
import { CurriculumSectionSkeleton } from './CurriculumSection.skeleton';
import { useWaitForData } from '../../utilities/useWaitForData';
import { Spinner } from '../Spinner/Spinner';
import { useQuerySectionMaterialsFile } from '../../graphql/query/getSectionMaterialsFile';

type CurriculumSectionProps = {
  isSmallMobile?: boolean;
  isLoading?: boolean;
  sendProgress: () => void;
  data?: TransformModuleDataResult['curriculum'][0] | TransformModuleDataResult['recipe'][0];
  currentModuleId: string;
  onOpen: (url: string) => void;
};

export const CurriculumSection: FC<CurriculumSectionProps> = ({
  isSmallMobile,
  data,
  isLoading: loading,
  currentModuleId,
  sendProgress,
  onOpen,
}) => {
  const { t } = useTranslation();

  const { fetchMaterialsFile, sectionMaterialsFileLoading } = useQuerySectionMaterialsFile();
  const getSectionMaterialsFile = async () => {
    const res = await fetchMaterialsFile({
      programModuleId: currentModuleId,
      sectionId: data?.id,
    });

    if (res.getSectionMaterialsFile) {
      sendProgress();
      window.open(res?.getSectionMaterialsFile || '', '_blank');
    }
  };

  const isLoading = useWaitForData([data?.title, data?.data], loading);

  const sendOpen = () => {
    onOpen(data?.id || '');
    sendProgress();
  };

  if (!data && !isLoading) return null;

  if (isLoading) return <CurriculumSectionSkeleton />;

  return (
    <div
      data-testid="CurriculumSection"
      className={clsx('xl:fit flex flex-col justify-between pt-[2rem] border-b border-b-alto')}
    >
      <div className="">
        <div className="flex justify-between items-center mb-[1rem]">
          <div className="flex items-center">
            <div className="w-[5rem]">
              {data?.type === 'curriculum' ? <CurriculumIcon /> : <CookBookIcon />}
            </div>
            <div className="ml-4 text-outerSpace text-1822">{data?.title}</div>
          </div>
        </div>
        <p className="text-manatee font-montserrat text-1522">{data?.description}</p>
      </div>
      <div className="flex justify-center self-end">
        <div className="max-w-[31rem] py-[2.4rem]">
          <Button
            disabled={sectionMaterialsFileLoading}
            onClick={sendOpen}
            width={isSmallMobile ? '15rem' : '15rem'}
          >
            {sectionMaterialsFileLoading ? <Spinner /> : t('Open')}
          </Button>
        </div>
      </div>
    </div>
  );
};

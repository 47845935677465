import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { ReactComponent as UpIcon } from '../../assets/weightArrowUp.svg';
import { ReactComponent as DownIcon } from '../../assets/weightArrowDown.svg';
import { ReactComponent as SameIcon } from '../../assets/weightArrowSame.svg';
import { WeightRecordProps } from './Types';

export const WeightRecord: React.FC<WeightRecordProps> = ({
  index,
  weight,
  prevWeight,
  createdAt,
  size = 'sm',
  unit,
}) => {
  const formattedDate = createdAt ? moment(createdAt).format('MMM DD, YYYY') : '';
  const [compareResult, setCompareResult] = useState<string | null>(null);

  useEffect(() => {
    if (weight !== undefined && prevWeight !== undefined) {
      // Check if weight is defined
      if (weight > prevWeight) {
        setCompareResult('up');
      } else if (weight < prevWeight) {
        setCompareResult('down');
      } else {
        setCompareResult('same');
      }
    }
  }, [weight, prevWeight]);

  const textSizeClass = size === 'sm' ? 'text-1830 font-medium' : 'text-2430';

  let icon = null;
  let fontColorClass = '';

  switch (compareResult) {
    case 'up':
      icon = <UpIcon className="ml-6" />;
      fontColorClass = 'text-red-700';
      break;
    case 'down':
      icon = <DownIcon className="ml-6" />;
      fontColorClass = 'text-green-700';
      break;
    case 'same':
      icon = <SameIcon className="ml-6" />;
      fontColorClass = 'text-gray-700';
      break;
    default:
      icon = null;
      fontColorClass = '';
      break;
  }

  return (
    <div
      key={index}
      className={`flex justify-between items-center ${textSizeClass} text-main font-lato  mt-2 py-2`}
    >
      <div className={`flex items-center ${fontColorClass}`}>
        {weight !== undefined && (
          <>
            {' '}
            <span>
              {weight?.toFixed(1)} {unit}
            </span>
            {icon}
          </>
        )}
      </div>
      {formattedDate && (
        <div className="ml-9 text-1416 font-normal text-manatee">{formattedDate}</div>
      )}
    </div>
  );
};

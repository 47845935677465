import { useMutation } from '../../hooks/useMutation';
import { ADD_CHAT_ACTIVITY } from './constants';
import { $ } from '../../zeus';
import { CreateMutationResponse, GQLArgs } from '../../types';

export const useMutationAddChat = (args?: GQLArgs<CreateMutationResponse<'addChatActivity'>>) => {
  return useMutation(
    [ADD_CHAT_ACTIVITY],
    {
      addChatActivity: [
        {
          participantId: $`participantId`,
        },
        true,
      ],
    },
    {
      operationName: ADD_CHAT_ACTIVITY,
    },
    args,
  );
};

import { ModuleProgressDataType } from '../graphql/query/getModuleProgress';
import { ProgramModuleType } from '../graphql/query/getParticipantProgramModules';
import {
  CoachingCallProgressMetadataType,
  CoachingCallType,
  CurriculumProgressMetadataType,
  CurriculumType,
  DietitianCallProgressMetadataType,
  DietitianCallType,
  ModuleSectionResult,
  PersonalSuccessProgressMetadataType,
  PersonalSuccessType,
  ProgramModuleCleaner,
  ProgressSectionType,
  SectionType,
  TransformModuleDataResult,
  TransformProgressDataResult,
  UserProgress,
  UserProgressCleaner,
  VideoType,
  WeightType,
  WeightTypeProgressMetadataType,
  VideoTypeProgressMetadataType,
  RecipeType,
  RecipeTypeProgressMetadataType,
  ActivityType,
  ActivityTypeProgressMetadataType,
} from '../types';

const getSection = (module: ProgramModuleType, type: SectionType) => {
  return module.sections.filter(
    (section) => section.data.__typename === type,
  ) as ModuleSectionResult[];
};
const getProgressSection = (progress: ModuleProgressDataType, type: ProgressSectionType) => {
  return progress.sections?.filter(
    (section) => section.metadata.__typename === type,
  ) as UserProgress[];
};

export const transformModuleData = (
  module: ProgramModuleType | null,
): TransformModuleDataResult | null => {
  if (!module) {
    return null;
  }
  const personalSuccess = getSection(
    module,
    SectionType.PersonalSuccessType,
  ) as ProgramModuleCleaner<PersonalSuccessType>[];

  const webinar = getSection(
    module,
    SectionType.CoachingCallType,
  ) as ProgramModuleCleaner<CoachingCallType>[];

  const curriculum = getSection(
    module,
    SectionType.CurriculumType,
  ) as ProgramModuleCleaner<CurriculumType>[];

  const dietitian = getSection(
    module,
    SectionType.DietitianCallType,
  ) as ProgramModuleCleaner<DietitianCallType>[];

  const weight = getSection(module, SectionType.WeightType) as ProgramModuleCleaner<WeightType>[];

  const video = getSection(module, SectionType.VideoType) as ProgramModuleCleaner<VideoType>[];

  const recipe = getSection(module, SectionType.RecipeType) as ProgramModuleCleaner<RecipeType>[];

  const activity = getSection(
    module,
    SectionType.ActivityType,
  ) as ProgramModuleCleaner<ActivityType>[];

  const moduleDescription = {
    name: module.title,
    description: module?.description,
    current: module?.current,
    start: module?.startedAt,
    end: module?.endedAt,
  };
  return {
    moduleDescription,
    personalSuccess,
    webinar,
    curriculum,
    dietitian,
    weight,
    video,
    recipe,
    activity,
  };
};

export const transformUserProgressData = (
  progress: ModuleProgressDataType,
): TransformProgressDataResult => {
  const personalSuccess = getProgressSection(
    progress,
    ProgressSectionType.PersonalSuccessProgressMetadataType,
  ) as UserProgressCleaner<PersonalSuccessProgressMetadataType>[];
  const webinar = getProgressSection(
    progress,
    ProgressSectionType.CoachingCallProgressMetadataType,
  ) as UserProgressCleaner<CoachingCallProgressMetadataType>[];
  const curriculum = getProgressSection(
    progress,
    ProgressSectionType.CurriculumProgressMetadataType,
  ) as UserProgressCleaner<CurriculumProgressMetadataType>[];
  const dietitian = getProgressSection(
    progress,
    ProgressSectionType.DietitianCallProgressMetadataType,
  ) as UserProgressCleaner<DietitianCallProgressMetadataType>[];
  const weight = getProgressSection(
    progress,
    ProgressSectionType.WeightTypeProgressMetadataType,
  ) as UserProgressCleaner<WeightTypeProgressMetadataType>[];
  const video = getProgressSection(
    progress,
    ProgressSectionType.VideoTypeProgressMetadataType,
  ) as UserProgressCleaner<VideoTypeProgressMetadataType>[];
  const recipe = getProgressSection(
    progress,
    ProgressSectionType.RecipeTypeProgressMetadataType,
  ) as UserProgressCleaner<RecipeTypeProgressMetadataType>[];
  const activity = getProgressSection(
    progress,
    ProgressSectionType.ActivityTypeProgressMetadataType,
  ) as UserProgressCleaner<ActivityTypeProgressMetadataType>[];

  return { personalSuccess, webinar, curriculum, dietitian, weight, video, recipe, activity };
};

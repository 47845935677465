import React, { FC } from 'react';
import { ReactComponent as Logo } from '../../assets/logo-blue.svg';

export const SoleraLayout: FC = ({ children }) => {
  return (
    <div className="flex flex-col items-center bg-white min-h-screen px-[1rem] py-[5rem]">
      <Logo className="logo w-[19rem] fill-white" />
      <>{children}</>
    </div>
  );
};

import React, { useEffect } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { routesPath } from '../constants/routes';
import { useAuthState } from '../context/auth/authContext';
import { AuthStatus } from '../context/auth/types';
export function RouteIfLoggedIn(props: any) {
  const { isLoggedIn } = useAuthState();
  if (isLoggedIn) return <Redirect strict exact from={'/'} to={routesPath.dashboard} />;
  return <Route {...props} />;
}

export function RedirectToDashboard() {
  const { isLoggedIn } = useAuthState();
  if (isLoggedIn) return <Redirect strict exact from={'/'} to={routesPath.dashboard} />;
  return <Redirect strict exact from={'/'} to={routesPath.login} />;
}

export const PrivateRoute = ({ children, ...rest }: any) => {
  const { isLoggedIn, isLoading, authStatus } = useAuthState();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (authStatus === AuthStatus.Init || isLoading) return children;
        return authStatus === AuthStatus.SignedOut && !isLoggedIn ? (
          <Redirect
            to={{
              pathname: routesPath.login,
              state: { from: location },
            }}
          />
        ) : (
          children
        );
      }}
    />
  );
};

import { FC, useEffect, useState } from 'react';
import { API_BASE_URL } from '../../env';
import { InviteLayout } from '../../components/InviteLayout/InviteLayout';
import { Trans } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { routesPath } from '../../constants/routes';
import { useScreenView } from '../../utilities/isScreenView';
import Confetti from './Confetti';

const InviteSolera: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const isSmallMobile = useScreenView({ width: 670 });
  const [text, setText] = useState('Validating...');
  const [button, setButton] = useState(false);
  const [confetti, setConfetti] = useState(false);
  const fetchResult = async (key: string | null, mail: string | null) => {
    // 3cacaedsf440583e5d2b3
    let url = `${API_BASE_URL}` + 'api/solera/look_up_key?key=' + key;

    url = mail ? url + '&email=' + mail : url;

    await fetch(url, {
      headers: {
        'X-Auth-Key': 'X-Auth-Key',
        'Access-Control-Allow-Headers': '*',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          setConfetti(true);
          switch (res.program) {
            case 'IBC':
              setText(
                'Thank you for joining Intensive Behavioral Counseling Program. Please check your email.',
              );
              break;
            case 'NDPP':
              setText(
                'Thank you for joining National Diabetes Prevention Program. Please check your email.',
              );
              break;
            case 'HWM':
              setText(
                'Thank you for joining Healthy Weight Management Program. Please check your email.',
              );
              break;
            default:
              setText('Thank you for joining Program. Please check your email.');
          }
        } else if (res.detail === 'User already exists') {
          setText('A user with this email already exists.');
          setButton(true);
        } else if (res.detail === 'Program group not found') {
          setText('Program group not found.');
          setButton(true);
        } else {
          setText('Something went wrong.');
        }
      })
      .catch((err) => {
        setText('Something went wrong.');
        console.error(err);
      });
  };

  useEffect(() => {
    const allParams: Record<string, string> = {};
    const queryParams = new URLSearchParams(location.search);
    queryParams.forEach((value, key) => {
      const keyLower = key.toLowerCase();
      allParams[keyLower] = value;
    });

    const lookupKey = allParams['lookupkey'];
    const email = allParams['email'];

    fetchResult(lookupKey, email);
  }, [location.search]);

  return (
    <InviteLayout>
      <div
        id="CheckEligibility"
        className={`flex h-screen flex-col mx-[2rem] lg:ml-[10rem] justify-between max-w-[52.8rem]`}
      >
        {confetti && <Confetti />}
        <div className="flex flex-col min-h-[24rem] mt-[5rem] lg:mt-[50%] font-ambit">
          <h5 className="text-main text-4xl font-bold mb-[3.1rem] font-ambit">{text}</h5>
          {button && (
            <Button
              onClick={() => history.push(routesPath.login)}
              width={isSmallMobile ? '100%' : '210px'}
            >
              Back to login
            </Button>
          )}

          <p className="text-1630 mt-auto">
            <Trans i18nKey="having-trouble-contact-us" values={{}}>
              Having trouble?
              <a
                href="https://cibahealth.com/contact-us"
                className="text-linkBlue underline"
                target={'_blank'}
              >
                Contact us
              </a>
            </Trans>
          </p>
        </div>
      </div>
    </InviteLayout>
  );
};

export default InviteSolera;

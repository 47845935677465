import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '../../components/HeaderTitle/HeaderTitle';
import { Plate } from '../../components/Plate/Plate';
import { useScreenView } from '../../utilities/isScreenView';
import { ReactComponent as FirstIcon } from '../../assets/devices/first.svg';
import clsx from 'clsx';
import { useQueryMe } from '../../graphql/query/getMe';
import { useQueryHangLink } from '../../graphql/query/getLink';

type DevicePageViewProps = {
  isLoading: boolean;
};

export const DevicePageView: FC<DevicePageViewProps> = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [headsUpToken] = useState('');
  const { t } = useTranslation();
  const isMobile = useScreenView({
    size: 'xl',
  });
  const me = useQueryMe()?.me;
  // console.log(headsUpToken);
  const url: any = 'device-form';
  let blank: any = '_self';
  let text = 'connect-device';
  if (me.headsUpToken) {
    blank = '_blank';
    text = 'open-your-device';
  }
  const { url: hangLinkData } = useQueryHangLink();
  const action = async () => {
    if (me.headsUpToken) {
      const u = hangLinkData?.url;
      window.open(u, blank);
    } else {
      window.open(url, blank);
    }
  };
  const Wrapper = isMobile ? 'div' : Plate;
  const form_link =
    'https://docs.google.com/forms/d/e/1FAIpQLSdVMmkhLt5hLLEmlpwugABKc6ECB70SS0zC5358aGZh5n5pzg/viewform?usp=pp_url&entry.1535915699=' +
    me?.email;
  return (
    <div className="xl:px-9 xl:pb-8 h-full flex flex-col grow">
      <Title>
        <div className="flex">
          <div className="mr-4">{t('device')}</div>
        </div>
      </Title>
      <Wrapper>
        <>
          <div className="flex relative flex-col xl:flex-row h-full">
            <section
              className={clsx({
                'bg-gradient-to-r  from-[#504D6F] to-[#8B899F] px-9 pb-8 pt-8 rounded-l-[10px]':
                  !isMobile,
                'bg-gradient-to-r  from-[#504D6F] to-[#8B899F] px-9 pb-8 pt-8 rounded-xl m-8':
                  isMobile,
              })}
            >
              <div className="page-head flex justify-between mt-2 mb-2 mx-9 xl:mx-12 h-[8rem]">
                <div className="leading-[2rem] font-semibold font-montserrat">
                  <p
                    className={clsx({
                      'font-semibold text-[#FFFFFF] text-[24px]': !isMobile,
                      'text-[#FFFFFF] text-[18px]': isMobile,
                    })}
                  >
                    {t('device-title')}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2 grid-flow-row-dense">
                <div className=" items-center  ">
                  <FirstIcon className=" h-266 object-center md:h-full " />
                </div>
                {!isMobile && (
                  <>
                    <div>
                      <div className="p-8">
                        <h4 className="font-semibold text-[#FFFFFF] text-[18px] p-4 ">
                          {t('device-text-1-header')}
                        </h4>
                        <p className="text-[#FFFFFF] text-[15px] p-4">{t('device-text-1')}</p>
                      </div>
                    </div>
                    <div>
                      <div className="">
                        <h4 className="font-semibold text-[#FFFFFF] text-[18px] p-4">
                          {t('device-text-2-header')}
                        </h4>
                        <p className="text-[#FFFFFF] text-[15px] p-4">{t('device-text-2')}</p>
                      </div>
                    </div>
                    <div className="flex items-center mr-8">
                      {/* eslint-disable-next-line @typescript-eslint/no-var-requires */}
                      <img src={require('../../assets/devices/second.svg').default} />
                      {/*<SecondIcon2 className="mr-1" />*/}
                    </div>
                    <div className="flex items-center mr-8">
                      {/* eslint-disable-next-line @typescript-eslint/no-var-requires */}
                      <img src={require('../../assets/devices/third.svg').default} />
                    </div>
                    <div>
                      <div className="">
                        <h4 className="font-semibold text-[#FFFFFF]  text-[18px] p-4">
                          {t('device-text-3-header')}
                        </h4>
                        <p className="text-[#FFFFFF] text-[15px] p-4">{t('device-text-3')}</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="flex justify-center">
                <button
                  onClick={() => window.open(form_link, '_blank')}
                  className="rounded-[2.4rem] flex items-center hover:bg-[#FFFFFF] justify-center bg-[#FFFFFF] text-white cursor-pointer h-[4.2rem] w-[41.56rem]"
                >
                  <div className="font-semibold text-[#16123F] text-[14px]">
                    {t('order-device')}
                  </div>
                </button>
              </div>
              {isMobile && (
                <div className="flex justify-center my-8">
                  <button
                    onClick={() => action()}
                    className="rounded-[2.4rem] flex items-center hover:bg-independence justify-center bg-main text-white cursor-pointer h-[4.2rem] w-[41.56rem]"
                  >
                    <div className="font-semibold text-[#FFFFFF] text-[14px]">
                      {t('connect-device')}
                      {isFormOpen ? 'Close Form' : 'Open Form'}
                    </div>
                  </button>
                </div>
              )}
            </section>
            {!isMobile && (
              <aside className="bg-catskillWhite flex flex-col px-[2.4rem] w-full xl:w-[84.6rem] h-full rounded-r-[10px]">
                <p className="font-semibold text-[#000000] text-[18px]  p-4 ">
                  {t('connect-device')}
                </p>
                <p className="text-[#000000] text-[15px]  p-4">{t('connect-device-text')}</p>
                <button
                  onClick={() => action()}
                  className="rounded-[2.4rem] flex items-center hover:bg-independence justify-center bg-main text-white cursor-pointer h-[4.2rem] my-8"
                >
                  <div className="font-semibold text-[#FFFFFF] text-[14px]">{t(text)}</div>
                </button>
              </aside>
            )}
          </div>
        </>
      </Wrapper>
    </div>
  );
};

import { parse, format, isValid, parseJSON, addMinutes, formatDistance } from 'date-fns';
import humanizeDuration from 'humanize-duration';

export const convertDateToString = (date?: Date) => {
  return date && isValid(date) ? format(date, 'yyyy-MM-dd') : '';
};

export const convertStringToDate = (string: string) => {
  const date = parse(string, 'yyyy-MM-dd', new Date());

  return isValid(date) ? date : new Date();
};

export const humanDate = (date: Date | string) => {
  const d = typeof date === 'string' ? convertStringToDate(date) : date;
  return format(d, 'dd MMM yyyy');
};

export const humanTime = (date: Date | string, isMilitary?: boolean) => {
  const d = typeof date === 'string' ? convertStringToDate(date) : date;
  return format(d, isMilitary ? 'HH:mm' : 'hh:mm a');
};

export const secondsToHumanDuration = (s: number) =>
  formatDistance(0, s * 1000, { includeSeconds: true });

export const convertDatetimeToString = (date?: Date) => {
  return date && isValid(date) ? format(date, 'yyyy/MM/dd HH:mm:ss') : '';
};

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'm',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'm',
    },
  },
});

export const getHumanTimeFromSeconds = (seconds: number, withSeconds?: boolean) => {
  const units = ['w', 'd', 'h', 'm', 's'] as any;
  if (!withSeconds) {
    units.pop();
  }
  return shortEnglishHumanizer(seconds * 1000, {
    round: true,
    units,
    spacer: '',
  })
    .split(',')
    .join('');
};

// if we got string in bad format - revert to parsing at least something
const convertWithFallback = (string: string) => {
  const date = parseJSON(string);

  return isValid(date) ? addMinutes(date, date.getTimezoneOffset()) : new Date();
};

export const convertStringToDatetime = (string: string) => {
  const date = parseJSON(string.slice(0, -6));

  return isValid(date) ? date : convertWithFallback(string);
};

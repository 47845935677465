import { FieldValues, useForm } from 'react-hook-form';
import { FC, useCallback, useState } from 'react';
import { InputField } from '../InputField/InputField';
import clsx from 'clsx';
import { Spinner } from '../Spinner/Spinner';
import { useTranslation } from 'react-i18next';
import { Option } from '../../types';
import Select from 'react-select';
import { useScreenView } from '../../utilities/isScreenView';

export type DeviceFormProps = {
  onSubmit: (data: FieldValues) => void;
  isLoading: boolean;
  error?: string;
  countries: any;
};

export const DeviceForm: FC<DeviceFormProps> = ({ onSubmit, isLoading, error, countries }) => {
  const { t } = useTranslation();
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
    trigger,
  } = useForm();
  const [serverError, setServerError] = useState<string | undefined>(error);
  const password_hang = register('password_hang');
  const time_zone = register('time_zone');
  const disabled = isLoading || !isDirty;
  const onSelect = (option: Option) => {
    setValue('time_zone', option.value as string);
    trigger('time_zone');
  };
  const inputFiledClass = {
    label: 'font-ambit',
    input: 'text-[1.5rem] lg:text-1324 p-[1.2rem] font-ambit',
  };

  const onSelectChange = useCallback((newValue, actionMeta) => {
    setValue('time_zone', newValue.label as string);
    trigger('time_zone');
  }, []);
  const isMobile = useScreenView({
    size: 'xl',
  });
  return (
    <>
      <div className="bg-white rounded-[.8rem] shadow-md h-full m-10">
        <section>
          <div className="page-head justify-between mt-2 mb-2 mx-9 xl:mx-12 h-[8rem]">
            <div className="leading-[2rem] font-semibold font-montserrat py-8">
              <p
                className={clsx({
                  'font-semibold text-[#000000] text-[24px]': !isMobile,
                  'text-[#000000] text-[18px]': isMobile,
                })}
              >
                {t('device-form-title')}
              </p>
            </div>
            <div className="py-8">
              <div className="object-center flex">
                <form noValidate onSubmit={handleSubmit(onSubmit)} className="">
                  <Select
                    options={countries}
                    onChange={onSelectChange}
                    className="text-gray-900 text-1324 rounded-[2.4rem] mb-8"
                    placeholder={t('select_t_zone') as string}
                  />

                  <InputField
                    className="text-gray-900 text-1324 rounded-[2.4rem] h-[4.2rem] w-[41.56rem]"
                    disabled={isLoading}
                    placeholder={t('password') as string}
                    type="password"
                    error={errors.password?.message as string}
                    {...password_hang}
                    onChange={(e) => {
                      password_hang.onChange(e);
                      setServerError(undefined);
                    }}
                  />
                  <button
                    type="submit"
                    disabled={disabled}
                    className="rounded-[2.4rem] flex items-center hover:bg-independence justify-center bg-main text-white cursor-pointer h-[4.2rem] w-[41.56rem]"
                  >
                    {isLoading ? <Spinner /> : t('connect-device')}
                  </button>
                  {serverError && <div className="text-error">{serverError}</div>}
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

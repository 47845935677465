import { useEffect, useMemo, useState } from 'react';
import { Conversation, Message, Paginator, Participant } from '@twilio/conversations';
import { ProviderDataType } from '../../graphql/query/getCareTeam';
import { ParticipantsDataType } from '../../graphql/query/getParticipants';

type MessageType = 'dateseparator' | 'text' | 'media';

export type MessageItem = {
  author?: string | null;
  isMe?: boolean;
  body?: string | null;
  fullname?: string | null;
  type: MessageType;
  timestamp: Date | null;
};

const loadChatData = (selectedConversation: Conversation) => {
  return Promise.all([selectedConversation.getMessages(), selectedConversation.getParticipants()]);
};

export const useCurrentChat = (
  selectedConversation: Conversation | null,
  identity: string,
  admins: ProviderDataType[],
  participants: ParticipantsDataType['items'],
) => {
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [paginator, setPaginator] = useState<Paginator<Message> | null>(null);
  const [originalMessages, setOriginalMessages] = useState<Message[]>([]);
  const [users, setUsers] = useState<Participant[]>([]);
  const [files, setFiles] = useState([]);
  const [links, setLinks] = useState([]);
  const [media, setMedia] = useState([]);

  const messages: MessageItem[] = useMemo(() => {
    let lastDate = '';

    const newMessages: MessageItem[] = [];

    for (let i = 0; i < originalMessages.length; i++) {
      const message = originalMessages[i];
      const date = new Date(message.dateCreated || '');
      const dateStr = date.toLocaleDateString();
      const isSameDate = lastDate === dateStr;
      lastDate = dateStr;

      if (!isSameDate) {
        newMessages.push({
          timestamp: message.dateCreated,
          type: 'dateseparator',
        });
      }

      const maybeAdmin = admins?.find((admin) => admin.chatIdentity === message.author);
      const maybeAnotherPart = participants?.find((part) => part.chatIdentity === message.author);

      let fullname = message.author;

      if (maybeAdmin?.fullName) {
        fullname = maybeAdmin?.fullName;
      }

      if (maybeAnotherPart) {
        fullname = `${maybeAnotherPart?.firstName} ${maybeAnotherPart?.lastName}`;
      }
      const msg: MessageItem = {
        author: message.author,
        fullname,
        isMe: message.author === identity,
        body: message.body,
        type: message.type as MessageType,
        timestamp: message.dateCreated,
      };
      newMessages.push(msg);
    }

    return newMessages;
  }, [originalMessages, identity, admins, participants]);

  const careTeam = useMemo(() => {
    const careTeam = admins
      .filter((a) => users?.find((u) => u.identity === a.chatIdentity))
      .map((admin) => ({
        ...admin,
        chatUser: users.find((u) => u.identity === admin.chatIdentity)?.getUser(),
      }));

    return careTeam;
  }, [admins, users]);

  const loadMore = async () => {
    let int;
    if (selectedConversation) {
      int = setTimeout(() => {
        setLoadingMore(true);
      }, 1000);
      try {
        const newPaginator = await paginator?.prevPage();
        if (newPaginator) {
          setPaginator(newPaginator);
        }
        setOriginalMessages((prev) => [...(newPaginator?.items || []), ...prev]);
      } catch (error) {
        console.log(error);
      } finally {
        clearTimeout(int);
        setLoadingMore(false);
      }
    }
  };

  useEffect(() => {
    if (selectedConversation) {
      setLoading(true);
      loadChatData(selectedConversation)
        .then(([paginator, participants]) => {
          setOriginalMessages((prev) => [...prev, ...paginator.items]);
          setUsers(participants);
          setPaginator(paginator);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });

      const messageAddedListener = (message: Message) => {
        setOriginalMessages((prev) => [...prev, message]);
      };

      selectedConversation.on('messageAdded', messageAddedListener);

      return () => {
        setOriginalMessages([]);
        setUsers([]);
        setPaginator(null);
        selectedConversation.off('messageAdded', messageAddedListener);
      };
    }
  }, [selectedConversation]);

  return { loadMore, messages, careTeam, files, links, media, loading, paginator, loadingMore };
};

const getValidationError = (password: string): string | null => {
  if (password?.length < 8) return 'Min 8 characters';
  if (password?.length > 32) return 'Max 32 characters';
  if (!password.match('(.*[a-z].*)')) return 'At least one lowercase character';
  if (!password.match('(.*[A-Z].*)')) return 'At least one uppercase character';
  if (!password.match('(.*\\d.*)')) return 'At least one digit';
  if (!password.replaceAll(' ', '').match('\\W')) return 'At lease one special character';

  return null;
};

export const validateNewPassword = (password: string) => getValidationError(password) || true;

import { useMutation } from '../../hooks/useMutation';
import { MAKE_PROGRESS } from './constants';
import { $ } from '../../zeus';
import { CreateMutationResponse, GQLArgs } from '../../types';

export const useMutationMakeProgress = (args?: GQLArgs<CreateMutationResponse<'makeProgress'>>) => {
  return useMutation(
    [MAKE_PROGRESS],
    {
      makeProgress: [
        {
          programModuleId: $`programModuleId`,
          sectionId: $`sectionId`,
          data: $`data`,
        },
        {
          __typename: true,
          '...on DoesNotBelongToCourse': {
            __typename: true,
            message: true,
          },
          '...on ModuleProgressType': {
            __typename: true,
            sections: {
              __typename: true,
              sectionId: true,
              sectionType: true,
            },
          },
        },
      ],
    },
    {
      errorTypes: [
        {
          entityName: 'makeProgress',
          __typename: 'DoesNotBelongToCourse',
        },
        {
          entityName: 'makeProgress',
          __typename: 'ModuleHasNotStarted',
        },
      ],
      operationName: MAKE_PROGRESS,
    },
    args,
  );
};

import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { routesPath } from '../../constants/routes';

import { useAuthActions, useAuthState } from '../../context/auth/authContext';
import { useChatActions } from '../../context/chat/chatContext';
import { TopAvatarView } from './TopAvatar.view';

export const TopAvatar: FC<{ isLoading?: boolean }> = ({ isLoading }) => {
  const { chatLogout } = useChatActions();
  const { signOut } = useAuthActions();
  const history = useHistory();

  const { userData, username } = useAuthState();
  if (!userData?.email) {
    return null;
  }

  const logoutNative = () => {
    chatLogout();
    signOut();
    history.replace(routesPath.login);
  };

  return <TopAvatarView isLoading={isLoading} logoutNative={logoutNative} username={username} />;
};

import { useQuery } from '../../hooks/useQuery';
import { GET_PARTICIPANTS } from './constants';
import { $, GraphQLTypes, InputType, Selector } from '../../zeus';
import { useQueryMe } from './getMe';

type QueryParticipantsVariables = {
  ids?: string[];
};

const participantsTypeSelector = Selector('ParticipantTypeConnection')({
  __typename: true,
  items: {
    firstName: true,
    lastName: true,
    id: true,
    chatIdentity: true,
  },
});

export type ParticipantsDataType = InputType<
  GraphQLTypes['ParticipantTypeConnection'],
  typeof participantsTypeSelector
>;

export const useQueryParticipants = () => {
  const { me } = useQueryMe();
  const enabled = !!me.programGroupId; //!!(variables && variables.ids);

  const { isFetching, data, refetch } = useQuery(
    [GET_PARTICIPANTS],
    {
      getParticipants: [
        {
          page: 1,
          perPage: 100,
          filters: {
            groupId: $`groupId`,
          },
        },
        participantsTypeSelector,
      ],
    },
    {
      operationName: GET_PARTICIPANTS,
      variables: {
        groupId: me.programGroupId,
      },
    },
    { enabled },
  );

  const participants = (data?.getParticipants || {}) as ParticipantsDataType;

  return {
    isLoading: isFetching,
    data: participants,
    participants,
    refetch,
    refetchParticipants: refetch,
    participantsLoading: isFetching,
  };
};

import { BrowserRouter } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import { Router } from './routes/router';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { SignUpProvider } from './page/SignUp/context';
import { AuthProvider } from './context/auth/authContext';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import { ChatProvider } from './context/chat/chatContext';

const App = () => (
  <SignUpProvider>
    <AuthProvider>
      <ErrorBoundary>
        <BrowserRouter>
          <ChatProvider>
            <ToastContainer />
            <Router />
          </ChatProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </AuthProvider>
  </SignUpProvider>
);

export default App;

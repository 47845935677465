export const generateAvatar = (fullName?: string) => {
  if (!fullName) return 'J D';

  const split = /\s/g.test(fullName) ? fullName.split(' ') : fullName.split('.');

  return split
    .map((s) => s[0]?.toLocaleUpperCase())
    .filter((s) => !!s)
    .filter((s, index) => index <= 1)
    .join(' ');
};

import { useEffect } from 'react';
import { useChatActions, useChatState } from '../../context/chat/chatContext';
import { useQueryCareTeam } from '../../graphql/query/getCareTeam';
import { useQueryParticipants } from '../../graphql/query/getParticipants';
import { useCurrentChat } from './Chat.hooks';
import { ChatPageView } from './Chat.view';
import { useQueryMe } from '../../graphql/query/getMe';
import { datadogRum } from '@datadog/browser-rum';

export const ChatPage = () => {
  const { participants } = useQueryParticipants();
  const { careTeam: careTeamList } = useQueryCareTeam();
  const { selectedConversation, identity } = useChatState();
  const { readAllMessages } = useChatActions();
  const { me } = useQueryMe();

  const { paginator, messages, loadMore, loading, careTeam, loadingMore } = useCurrentChat(
    selectedConversation,
    identity,
    careTeamList,
    participants.items,
  );

  useEffect(() => {
    if (me.id) {
      datadogRum.setUser({
        id: me.id,
        name: `${me.firstName} ${me.lastName}`,
        email: me.email,
        chatIdentity: me.chatIdentity,
        groupId: me.groupId,
      });
    }
  }, [me.id]);

  useEffect(() => {
    readAllMessages();
  }, [selectedConversation]);

  return (
    <ChatPageView
      onSendMessage={(msg) => selectedConversation?.sendMessage(msg)}
      loadMore={loadMore}
      isLoading={loading}
      careTeam={careTeam}
      participants={participants.items}
      loadingMore={loadingMore}
      hasMore={paginator?.hasPrevPage || false}
      messages={messages}
      clientId={me.id}
    />
  );
};

import { FC } from 'react';

export const ChatAttachmentsSectionSkeleton: FC = () => {
  return (
    <div className="bg-catskillWhite flex flex-col animate-pulse pt-[1.6rem]">
      <div className="flex justify-between mb-[1.6rem]">
        <div className="bg-lavenderGray w-[12.5rem] h-[3rem] rounded-[.8rem]"></div>
        <div className="bg-lavenderGray w-[3rem] h-[3rem] rounded-[.8rem]"></div>
      </div>
      <div className="flex mb-[1.6rem]">
        <div className="bg-lavenderGray w-[6.4rem] h-[2.6rem] rounded-[.8rem] mr-4"></div>
        <div className="bg-lavenderGray w-[6.4rem] h-[2.6rem] rounded-[.8rem] mr-4"></div>
        <div className="bg-lavenderGray w-[6.4rem] h-[2.6rem] rounded-[.8rem]"></div>
      </div>

      <div className="grid grid-cols-3 gap-6 py-4 max-h-[23rem] overflow-y-auto">
        <div className="bg-lavenderGray w-[6.4rem] h-[6.4rem] rounded-[.8rem]"></div>
        <div className="bg-lavenderGray w-[6.4rem] h-[6.4rem] rounded-[.8rem]"></div>
        <div className="bg-lavenderGray w-[6.4rem] h-[6.4rem] rounded-[.8rem]"></div>
        <div className="bg-lavenderGray w-[6.4rem] h-[6.4rem] rounded-[.8rem]"></div>
        <div className="bg-lavenderGray w-[6.4rem] h-[6.4rem] rounded-[.8rem]"></div>
        <div className="bg-lavenderGray w-[6.4rem] h-[6.4rem] rounded-[.8rem]"></div>
        <div className="bg-lavenderGray w-[6.4rem] h-[6.4rem] rounded-[.8rem]"></div>
        <div className="bg-lavenderGray w-[6.4rem] h-[6.4rem] rounded-[.8rem]"></div>
        <div className="bg-lavenderGray w-[6.4rem] h-[6.4rem] rounded-[.8rem]"></div>
      </div>
    </div>
  );
};

import { useAuthState } from '../../context/auth/authContext';
import { useQuery } from '../../hooks/useQuery';
import { GraphQLTypes, InputType, Selector } from '../../zeus';
import { GET_ME } from './constants';
import { useAuthActions } from '../../context/auth/authContext';
import { useEffect } from 'react';

const participantType = Selector('ParticipantType')({
  __typename: true,
  id: true,
  email: true,
  programGroupId: true,
  firstName: true,
  lastName: true,
  chatIdentity: true,
  groupId: true,
  memberId: true,
  status: true,
  cognitoSub: true,
  medicalRecord: true,
  headsUpToken: true,
  soleraId: true,
  soleraProgramId: true,
  isWeight: true,
  /*withingId: true,
  ouraId: true,
  fitbitId: true,*/
});

export type ParticipantType = InputType<GraphQLTypes['ParticipantType'], typeof participantType>;

export const useQueryMe = () => {
  const { setMe } = useAuthActions();
  const { userData, isLoggedIn } = useAuthState();
  const { isFetching, data, refetch } = useQuery(
    [GET_ME],
    {
      me: participantType,
    },
    { operationName: GET_ME },
    { enabled: !!isLoggedIn },
  );

  const me = (data?.me || {}) as ParticipantType;

  useEffect(() => {
    if (isLoggedIn && !isFetching && !userData?.id) {
      setMe(me);
    }
  }, [isLoggedIn, isFetching, userData, me]);

  return { refetchMe: refetch, meLoading: isFetching, isLoading: isFetching, me, data: me };
};

import { useQuery } from '../../hooks/useQuery';
import { GET_PROVIDERS } from './constants';
import { GraphQLTypes, InputType, Selector } from '../../zeus';

const providerTypeSelector = Selector('ProviderType')({
  __typename: true,
  avatarUrl: true,
  chatIdentity: true,
  firstName: true,
  lastName: true,
  email: true,
  fullName: true,
  id: true,
});

export type ProviderDataType = InputType<GraphQLTypes['ProviderType'], typeof providerTypeSelector>;

export const useQueryCareTeam = () => {
  const { isFetching, data, refetch } = useQuery(
    [GET_PROVIDERS],
    {
      getProviders: [{}, providerTypeSelector],
    },
    { operationName: GET_PROVIDERS },
  );

  const careTeam = (data?.getProviders || []) as ProviderDataType[];

  return {
    isLoading: isFetching,
    data: careTeam,
    careTeam,
    refetch,
    refetchCareTeam: refetch,
    careTeamLoading: isFetching,
  };
};

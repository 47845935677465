import clsx from 'clsx';
import { FC } from 'react';

import { IconProps } from './types';

export const DeviceSvg: FC<IconProps> = ({ isActive }) => {
  const fill = clsx({ 'fill-white': isActive, 'fill-independence': !isActive });
  const stroke = clsx({ 'stroke-white': isActive, 'stroke-independence': !isActive });
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4303 9.37894L12.4302 9.37885L12.422 9.38704L12.1121 9.69814C12.112 9.69821 12.112 9.69828 12.1119 9.69835C12.0777 9.73248 12.0372 9.74727 12 9.74727C11.9628 9.74727 11.9223 9.73249 11.8881 9.69838C11.888 9.6983 11.888 9.69822 11.8879 9.69814L11.578 9.38704L11.5781 9.38691L11.5684 9.37762C11.181 9.00918 10.6723 8.80132 10.1404 8.80132C9.60843 8.80132 9.09975 9.00918 8.7124 9.37762L8.71238 9.3776L8.7083 9.38157C8.5256 9.55935 8.38058 9.77287 8.28082 10.0085C8.18107 10.244 8.12829 10.4975 8.12504 10.7537L8.12488 10.7537L8.12504 10.7664C8.12829 11.0226 8.18107 11.2761 8.28082 11.5116C8.38025 11.7464 8.52463 11.9593 8.70647 12.1367L11.6479 15.0538L12 15.403L12.3521 15.0538L15.2935 12.1367C15.4754 11.9593 15.6198 11.7464 15.7192 11.5116C15.8189 11.2761 15.8717 11.0226 15.875 10.7664L15.8751 10.7664L15.875 10.7537C15.8717 10.4975 15.8189 10.244 15.7192 10.0085C15.6194 9.77287 15.4744 9.55935 15.2917 9.38157L15.2917 9.38156L15.289 9.37894C14.9022 9.00823 14.3927 8.79883 13.8596 8.79883C13.3265 8.79883 12.817 9.00823 12.4303 9.37894Z"
        className={stroke}
      />
      <path
        d="M7.6141 4.45703H16.3864C17.2232 4.45703 18.0257 4.78944 18.6174 5.38114C19.2091 5.97284 19.5415 6.77536 19.5415 7.61215V16.3844C19.5415 17.2212 19.2091 18.0237 18.6174 18.6154C18.0257 19.2071 17.2232 19.5396 16.3864 19.5396H7.6141C6.77731 19.5396 5.9748 19.2071 5.3831 18.6154C4.7914 18.0237 4.45898 17.2212 4.45898 16.3844V7.61215C4.45898 6.77536 4.7914 5.97284 5.3831 5.38114C5.9748 4.78944 6.77731 4.45703 7.6141 4.45703Z"
        className={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M8.25098 21.3477C8.25098 21.3477 8.81645 22.8477 12.001 22.8477C15.1855 22.8477 15.751 21.3477 15.751 21.3477"
        className={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8.25098 2.65039C8.25098 2.65039 8.81645 1.15039 12.001 1.15039C15.1855 1.15039 15.751 2.65039 15.751 2.65039"
        className={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

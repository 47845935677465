import { FC } from 'react';
import { useMutationSignUp } from '../../graphql/mutation/signup';
import { useQueryCompanies } from '../../graphql/query/getCompanies';
import { convertCompaniesToOptions } from './../../utilities/optionsConvertors';
import { useSignUpActions, useSignUpState } from './context';
import { Option } from '../../types';
import { SignUpView } from './SignUp.view';

export const SignUp: FC = () => {
  const { step, init, form } = useSignUpState();
  const { setField, next } = useSignUpActions();
  const { companies, isLoading: companiesLoading } = useQueryCompanies();
  const { mutate: signup, isLoading: signupLoading } = useMutationSignUp({
    onSuccess: () => {
      next();
    },
  });
  console.log({
    companiesLoading,
    signupLoading,
  });

  const companiesOptions = convertCompaniesToOptions(companies);

  const loading = companiesLoading || signupLoading;

  const onSelect = (option: Option) => {
    setField('companyId', option.value as string);
  };

  return (
    <SignUpView
      companiesOptions={companiesOptions}
      form={form}
      init={init}
      loading={loading}
      next={next}
      onSelect={onSelect}
      setField={setField}
      signup={signup}
      step={step}
    />
  );
};

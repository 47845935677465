import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ChatDateLine } from '../../components/ChatDateLine/ChatDateLine';
import { ChatOpponentFileMessage } from '../../components/ChatOpponentFileMessage/ChatOpponentFileMessage';
import { ChatOpponentMessage } from '../../components/ChatOpponentMessage/ChatOpponentMessage';
import { ChatParticipantsList } from '../../components/ChatParticipantsList/ChatParticipantsList';
import { Title } from '../../components/HeaderTitle/HeaderTitle';
import { Plate } from '../../components/Plate/Plate';
import { ChatMyMessage } from '../../components/ChatMyMessage/ChatMyMessage';
import { ChatAttachmentsSection } from './../../components/ChatAttachmentsSection/ChatAttachmentsSection';
import { FC, useEffect, useRef, useState } from 'react';
import { Message, Participant, User } from '@twilio/conversations';
import { ReactComponent as ArrowUpIcon } from '../../assets/arrow-up-circle.svg';
import { MessageItem } from './Chat.hooks';
import { ProviderDataType } from '../../graphql/query/getCareTeam';
import { ChatMessagesSkeleton } from './ChatMessages.skeleton';
import { useWaitForData } from '../../utilities/useWaitForData';
import { ChatAttachmentsSectionSkeleton } from '../../components/ChatAttachmentsSection/ChatAttachmentsSection.skeleton';
import { useScreenView } from '../../utilities/isScreenView';
import { ParticipantsDataType } from '../../graphql/query/getParticipants';
import { useMutationAddChat } from '../../graphql/mutation/addChatActivity';
import { ReactComponent as ErrorMessage } from '../../assets/no-messages.svg';

type ChatPageViewProps = {
  messages: Partial<MessageItem>[];
  hasMore: boolean;
  loadingMore: boolean;
  participants: ParticipantsDataType['items'];
  careTeam: (ProviderDataType & { chatUser: Promise<User> | undefined })[];
  loadMore: () => void;
  isLoading?: boolean;
  onSendMessage: (message: string) => void;
  clientId: string;
};

export const ChatPageView: FC<ChatPageViewProps> = ({
  messages,
  careTeam,
  isLoading: loading,
  participants,
  loadingMore,
  hasMore,
  onSendMessage,
  loadMore,
  clientId,
}) => {
  const { t } = useTranslation();
  const isMobile = useScreenView({
    size: 'xl',
  });
  const [message, setMessage] = useState('');
  const [isDisabledMessages, setDisabledMessages] = useState(false);
  const [isMobileChatMenuOpen, setIsMobileChatMenuOpen] = useState(false);
  const [yPos, setYPos] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(document.createElement('div'));

  const showProgram = localStorage.getItem('showProgram');

  const isLoading = useWaitForData([messages.length], loading);

  const { mutate: makeProgressChat, isLoading: mutProgressLoading } = useMutationAddChat({});

  const sendMessage = () => {
    if (message) {
      onSendMessage(message);
      setMessage('');
      makeProgressChat({ participantId: clientId });
    }
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (yPos) {
      const wrapperElement = wrapperRef.current;
      if (wrapperElement) {
        wrapperElement.scrollTop = yPos;
        setYPos(wrapperElement.scrollHeight);
        return;
      }
    }
    const wrapperElement = wrapperRef.current;

    const fn = () => {
      setDisabledMessages(
        wrapperElement?.scrollHeight - wrapperElement.scrollTop >
          wrapperElement.getBoundingClientRect().bottom,
      );
    };
    if (wrapperElement) {
      wrapperElement.addEventListener('scroll', fn);
      if (!isDisabledMessages) {
        wrapperElement.scrollTop = wrapperElement?.scrollHeight;
      }
    }
    return () => wrapperElement?.removeEventListener('scroll', fn);
  }, [isLoading, messages]);

  const scrollHandler = () => {
    const wrapperElement = wrapperRef.current;
    if (wrapperElement) {
      setYPos(wrapperElement.scrollTop);
      loadMore();
    }
  };

  const ellipsis = (
    <div className="h-full flex flex-col justify-around p-1">
      <div className="h-[.4rem] w-[.4rem] bg-independence rounded-full"></div>
      <div className="h-[.4rem] w-[.4rem] bg-independence rounded-full"></div>
      <div className="h-[.4rem] w-[.4rem] bg-independence rounded-full"></div>
    </div>
  );
  const usersLength = (participants?.length || 0) + (careTeam?.length || 0);

  const Wrapper = isMobile ? 'div' : Plate;

  if ('true' === showProgram) {
    return (
      <div className="xl:px-9 xl:pb-8 h-full flex flex-col grow">
        <Wrapper>
          <div className="flex flex-col items-center mt-[3rem]">
            <ErrorMessage className="mt-[5rem] mb-[1rem]" />
            <p className="font-semibold text-center text-1830">No messages yet</p>
            <p className="text-center text-1324">
              First message could appear soon after the program starts.
            </p>
          </div>
        </Wrapper>
      </div>
    );
  }

  return (
    <div className="xl:px-9 xl:pb-8 h-full flex flex-col grow">
      <Title>
        <div className="flex">
          <div className="mr-4">{t('Group Chat')}</div>
          {usersLength && (
            <div className="text-1220 text-manatee font-ambit">
              ({usersLength} {t('participants')})
            </div>
          )}
        </div>
      </Title>
      <Wrapper>
        <div className="flex relative flex-col xl:flex-row xl:pt-[2.4rem] xl:pl-[2.4rem] xl:pr-[2.1rem] xl:pb-[3.6rem] h-full">
          <div className="hidden xl:block absolute left-[7.4rem] top-0 bg-white w-[13.4rem] h-[3.3rem] rounded-[1.6rem]"></div>
          {isLoading && <ChatMessagesSkeleton />}
          <div className="xl:hidden bg-white h-[6.4rem] w-full flex justify-between items-center p-[1.6rem]">
            <div className="text-1822 font-bold">{t('Group Chat')}</div>
            <div
              onClick={() => setIsMobileChatMenuOpen(!isMobileChatMenuOpen)}
              className="cursor-pointer  h-[3.2rem] w-[3.2rem] bg-catskillWhite rounded-full flex justify-center items-center"
            >
              {isMobileChatMenuOpen ? (
                <div className="text-[3rem] font-thin rotate-45">+</div>
              ) : (
                ellipsis
              )}
            </div>
          </div>
          {!isLoading && !isMobileChatMenuOpen && (
            <section
              style={{
                height: isMobile ? 'calc(100vh - 16rem)' : 'calc(100vh - 9rem)',
              }}
              className="relative bg-catskillWhite w-full mr-[2.7rem] flex flex-col justify-between rounded-[.8rem]"
            >
              {loadingMore && (
                <div className="flex justify-center items-center top-0 bottom-0 left-0 right-0 absolute z-[100] opacity-60">
                  <div className="text-manatee text-1822">Loading...</div>
                </div>
              )}
              {messages.length === 0 && (
                <div className="text-manatee text-1622 text-center mt-8">
                  {t('no-messages-yet')}
                </div>
              )}
              <div
                ref={wrapperRef}
                id="scrollable-chat-messages"
                style={{
                  height: isMobile ? 'calc(100vh - 20rem)' : 'calc(100vh - 16rem)',
                }}
                className={classNames('overflow-auto flex flex-col-reverse p-[2.4rem] relative', {
                  'opacity-20': loadingMore,
                })}
              >
                <InfiniteScroll
                  hasMore={hasMore}
                  dataLength={messages.length}
                  className="flex flex-col"
                  scrollableTarget="scrollable-chat-messages"
                  style={{ display: 'flex', flexDirection: 'column-reverse' }}
                  inverse={true}
                  loader={<div>Loading...</div>}
                  next={scrollHandler}
                >
                  {messages.map((message, index) => {
                    if (message.type === 'dateseparator') {
                      return (
                        <ChatDateLine
                          key={message.timestamp?.toISOString() + '' + index}
                          datetime={new Date(message.timestamp || '')}
                        />
                      );
                    }
                    if (message.isMe) {
                      return (
                        <ChatMyMessage
                          key={message.body + '' + message.timestamp?.toISOString() + index}
                          text={message.body as string}
                          datetime={new Date(message.timestamp || '')}
                        />
                      );
                    }
                    return (
                      <ChatOpponentMessage
                        key={message.body + '' + message.timestamp?.toISOString() + index}
                        datetime={new Date(message.timestamp || '')}
                        username={message.fullname as string}
                        text={message.body as string}
                        careTeam={careTeam}
                      />
                    );
                  })}
                </InfiniteScroll>
                {/* <ChatMyMessage text="All good, thanks." datetime={new Date()} />
                  <ChatOpponentMessage
                    datetime={new Date()}
                    username="Mikel Arteta"
                    text="Hello, i'm good, but i have a question. We have only 2 webinars left, yea?"
                  />
                  <ChatDateLine datetime={new Date()} />
                  <ChatOpponentMessage
                    datetime={new Date()}
                    username="John Liberman"
                    text="Yes, we are on the finishing line, only 2 weeks left."
                    replyUsername="Mikel Arteta"
                    replyText="Hello, i'm good, but i have a question. We have only 2 webinars left, yea"
                  />
                  <ChatDateLine datetime={new Date()} />
                  <ChatOpponentFileMessage
                    datetime={new Date()}
                    username="John Liberman"
                    filename="Prescription.docx"
                    filetype="docx"
                  />
                  <ChatMyMessage text="All good, thanks." datetime={new Date()} /> */}
              </div>
              <div className="bg-[#F9FAFC] h-[7.2rem] flex items-center px-[2rem] rounded-b-[.8rem] absolute w-full bottom-0">
                <div className="border-alto bg-white min-h-[4rem] w-full rounded-[3.2rem] pl-[1.5rem] pr-[1rem] text-1324 border flex items-center">
                  <textarea
                    rows={1}
                    autoComplete={'off'}
                    disabled={'true' === showProgram}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && e.shiftKey === false) {
                        e.preventDefault();
                        e.stopPropagation();
                        sendMessage();
                      }
                    }}
                    value={message}
                    placeholder="Type here..."
                    className="outline-0 ring-0 resize-none w-full h-full text-1622 border-0 pt-[.8rem] font-ambit placeholder:text-manatee"
                    spellCheck={false}
                  ></textarea>
                  <ArrowUpIcon onClick={sendMessage} className="cursor-pointer" />
                </div>
              </div>
            </section>
          )}
          {(!isMobile || isMobileChatMenuOpen) && (
            <aside className="bg-catskillWhite flex flex-col px-[2.4rem] w-full xl:w-[38.6rem] h-full rounded-[.8rem]">
              <ChatParticipantsList isLoading={isLoading} careTeam={careTeam} />
              {isLoading && <ChatAttachmentsSectionSkeleton />}
              {/* <ChatAttachmentsSection /> */}
            </aside>
          )}
        </div>
      </Wrapper>
    </div>
  );
};

import { omit } from 'lodash';
import { createContext, FC, useContext, useEffect, useState } from 'react';

export type Step = 'check-eligibility' | 'sign-up' | 'finish';

export type SignUpStateContextType = {
  init: boolean;
  step: Step;
  form: {
    firstName?: string;
    lastName?: string;
    email?: string;
    groupId?: string;
    memberId?: string;
    startDate?: string;
    companyId?: string;
  };
};

type SignUpActionsContextType = {
  setStep: (step: Step) => void;
  setField: (field: keyof SignUpStateContextType['form'], value: string) => void;
  next: () => void;
};

const initState: SignUpStateContextType = {
  init: false,
  step: 'check-eligibility',
  form: {},
};

const SignUpStateContext = createContext<SignUpStateContextType | undefined>(undefined);
const SignUpActionsContext = createContext<SignUpActionsContextType | undefined>(undefined);
SignUpStateContext.displayName = 'SignUpStateContext';

const steps: Step[] = ['check-eligibility', 'sign-up', 'finish'];

export const SignUpProvider: FC = ({ children }) => {
  const [state, setState] = useState<SignUpStateContextType>(initState);

  const setSignUpState = (newState: SignUpStateContextType) => {
    setState(newState);
  };

  const setStep = (step: Step) => {
    const newState = { ...state, step };
    setSignUpState(newState);
  };

  const setField = (name: keyof SignUpStateContextType['form'], value: string) => {
    const newState = { ...state, form: { ...state.form, [name]: value } };
    setSignUpState(newState);
  };

  const next = () => {
    const currentStepIndex = steps.indexOf(state.step);
    const nextStep = steps[currentStepIndex + 1];

    if (nextStep) {
      setStep(nextStep);
    }
  };

  const actions = {
    setStep,
    setField,
    next,
  };

  useEffect(() => {
    setState({ ...initState, init: true });
  }, []);

  return (
    <SignUpStateContext.Provider value={state}>
      <SignUpActionsContext.Provider value={actions}>{children}</SignUpActionsContext.Provider>
    </SignUpStateContext.Provider>
  );
};

export function useSignUpState() {
  const context = useContext(SignUpStateContext);
  if (context === undefined) {
    throw new Error('useSignUpState must be used within a SignUpProvider');
  }

  return context;
}

export function useSignUpActions() {
  const context = useContext(SignUpActionsContext);
  if (context === undefined) {
    throw new Error('useSignUpActions must be used within a SignUpProvider');
  }
  return context;
}

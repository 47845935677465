import { Client, ConnectionState, Conversation } from '@twilio/conversations';

export enum StatusString {
  Connected = 'You are connected.',
  Disconnecting = 'Disconnecting from Twilio…',
  Disconnected = 'Disconnected.',
  Failed = 'Failed to connect.',
  Connecting = 'Connecting to Twilio…',
}
export enum Status {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Default = 'default',
}

export enum ChatActions {
  TwilioConnection = 'TwilioConnection',
  SetClient = 'SetClient',
  ConversationJoined = 'ConversationJoined',
  ConversationLeft = 'ConversationLeft',
  SetMessageCounter = 'SetMessageCounter',
  SetIdentity = 'SetIdentity',
  Cleanup = 'Cleanup',
}

export type ChatStateContextType = {
  selectedConversation: Conversation | null;
  chatLoading: boolean;
  statusString?: StatusString;
  status: Status;
  conversations: Conversation[];
  chatClient: Client | null;
  identity: string;
  messageCounter: number;
};

export type ChatActionsContextType = {
  readAllMessages: () => void;
  chatLogout: () => void;
};

export type ActionTwilioConnection = {
  type: ChatActions.TwilioConnection;
  payload?: ConnectionState;
};

export type ActionConversationJoined = {
  type: ChatActions.ConversationJoined;
  payload?: Conversation;
};

export type ActionConversationLeft = {
  type: ChatActions.ConversationLeft;
  payload?: Conversation;
};

export type ActionSetClient = {
  type: ChatActions.SetClient;
  payload?: Client;
};

export type ActionSetMessageCounter = {
  type: ChatActions.SetMessageCounter;
  payload?: number;
};
export type ActionSetIdentity = {
  type: ChatActions.SetIdentity;
  payload?: string;
};
export type ActionCleanup = {
  type: ChatActions.Cleanup;
};

export type Action =
  | ActionTwilioConnection
  | ActionConversationJoined
  | ActionConversationLeft
  | ActionSetMessageCounter
  | ActionCleanup
  | ActionSetIdentity
  | ActionSetClient;

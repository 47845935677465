import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '../../components/HeaderTitle/HeaderTitle';
import { Plate } from '../../components/Plate/Plate';
import { useScreenView } from '../../utilities/isScreenView';
import { DeviceForm } from '../../components/Devices/DeviceForm';
import { FieldValues } from 'react-hook-form';
import { useQueryTimezones } from '../../graphql/query/getTimeZone';

type DevicePageViewProps = {
  isLoading: boolean;
  signInClicked: (data: FieldValues) => void;
  error: string | undefined;
  time_zones: any;
};

export const DeviceFormPageView: FC<DevicePageViewProps> = ({
  isLoading,
  signInClicked,
  error,
  time_zones,
}) => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const { t } = useTranslation();
  const isMobile = useScreenView({
    size: 'xl',
  });
  const Wrapper = isMobile ? 'div' : Plate;
  const { timeZonesLoading, timeZones } = useQueryTimezones();
  const isLoadingpage = timeZonesLoading;
  return (
    <div className="xl:px-9 xl:pb-8 h-full flex flex-col grow">
      <Title>
        <div className="flex">
          <div className="mr-4">{t('device')}</div>
        </div>
      </Title>
      <Wrapper>
        <>
          <div className="flex relative flex-col xl:flex-row h-full">
            <DeviceForm
              onSubmit={signInClicked}
              isLoading={isLoading}
              error={error}
              countries={timeZones}
            />
          </div>
        </>
      </Wrapper>
    </div>
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { Button } from '../Button/Button';
import { Modal } from '../Modal/Modal';

type VideoModalType = {
  onClose: () => void;
  onConfirm?: () => void;
  confirmButtonName?: string;
  cancelButtonName?: string;
  watchedVideo: boolean;
};

export const VideoModal: FC<VideoModalType> = ({
  onClose,
  children,
  onConfirm,
  cancelButtonName = 'cancel',
  confirmButtonName = 'confirm',
  watchedVideo,
}) => {
  const { t } = useTranslation();
  return (
    <Modal onCancel={onClose}>
      <div className="flex flex-col justify-between w-[29rem] sm:w-[65rem] h-[45rem] sm:h-[75rem]">
        <div className="flex flex-col justify-between">
          <div onClick={onClose} className="close  cursor-pointer self-end">
            <CloseIcon />
          </div>
          <div className="text-[1.5rem] w-full flex flex-col justify-center items-center mt-8">
            {children}
          </div>
        </div>
        <div>
          <div className="bg-galery h-px mb-10"></div>
          <div className="flex justify-end">
            <div className="w-[20rem]">
              <Button onClick={onConfirm} disabled={!watchedVideo}>
                {t(confirmButtonName)}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

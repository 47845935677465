import React, { FC } from 'react';
import { ReactComponent as Time } from '../../../assets/time.svg';
import { ReactComponent as Date } from '../../../assets/date.svg';
import { Trans } from 'react-i18next';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

type Step3Props = {
  changeSearchParam: (key: string, value?: string) => void;
  calendar: string;
};
const Step3: FC<Step3Props> = ({ changeSearchParam, calendar }) => {
  const formattedDate = localStorage.getItem('formattedDate') || '';
  const weekDay = localStorage.getItem('weekDay') || '';
  const time = localStorage.getItem('time') || '';
  const formattedDateGroup = localStorage.getItem('formattedDateGroup') || '';
  const weekDayGroup = localStorage.getItem('weekDayGroup') || '';
  const timeZoneAbbreviation = localStorage.getItem('timeZoneAbbreviation') || '';

  const handleLogin = () => {
    localStorage.removeItem('formattedDate');
    localStorage.removeItem('weekDay');
    localStorage.removeItem('time');
    localStorage.removeItem('formattedDateGroup');
    localStorage.removeItem('weekDayGroup');
    localStorage.removeItem('timeZoneAbbreviation');
  };

  return (
    <>
      <div className="flex flex-col sm:flex-row gap-[2rem] w-full justify-center text-deepPurple">
        <div className="flex-1 border-deepPurple500 shadow-[-5px_0_0_0_rgba(99,102,241,1)] border rounded-xl p-2.5">
          <div className="p-2.5">
            <div className="inline-block bg-deepPurple500 font-bold text-1416 sm:text-1214 text-white  py-[4px] px-[12px] rounded-[15px]">
              Program Starts
            </div>
          </div>
          <div className="flex p-2.5">
            <div className="flex flex-col gap-[1rem] items-start font-normal text-1618 sm:text-1315 p-2.5">
              <Date />
              <div className="font-ambit text-1618">
                {formattedDateGroup},&nbsp;{weekDayGroup}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 border-magenta shadow-[-5px_0_0_0_rgba(188,61,166,1)] border rounded-xl p-2.5">
          <div className="flex p-2.5">
            <div className="inline-block bg-magenta font-bold text-1416 sm:text-1214 text-white py-[4px] px-[12px] rounded-[15px]">
              Intro Call
            </div>
          </div>
          <div className="p-2.5">
            <div className="flex items-start font-normal text-1618 sm:text-1315 p-2.5">
              <div className="flex flex-col gap-[1rem] items-start font-normal text-1618 sm:text-1315 p-2.5 pr-0">
                <Date />
                <div className="font-ambit text-1618 w-[140px]">
                  {weekDay},&nbsp;{formattedDate}
                </div>
              </div>
              <div className="flex flex-col gap-[1rem] items-start font-normal text-1618 sm:text-1315 p-2.5">
                <Time className="ml-[-4px]" />
                <div className="font-ambit text-1618 uppercase">
                  {time},&nbsp;({timeZoneAbbreviation})
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type={'submit'}
        className={clsx(
          'font-ambit text-deepPurple900 border-deepPurple900 border bg-white rounded-[2rem] mt-[2rem] py-[1rem] w-full sm:w-[32rem]',
        )}
        onClick={() => window.open(calendar, '_blank')}
      >
        + Google Calendar
      </button>
      <Link
        to={'/login'}
        type={'submit'}
        className={clsx(
          'font-ambit bg-deepPurple900 text-center text-white rounded-[2rem] mt-[1rem] mb-[4rem] py-[1rem] w-full sm:w-[32rem]',
        )}
        onClick={() => handleLogin()}
      >
        Go to Login
      </Link>
      <div className="flex flex-col items-center mb-[5rem]">
        <p className="text-1624 text-black mt-auto">
          <Trans i18nKey="having-trouble-contact-us" values={{}}>
            Having trouble?
            <a
              href="https://cibahealth.com/contact-us"
              className="text-linkBlue underline"
              target={'_blank'}
            >
              Contact us
            </a>
          </Trans>
        </p>
        <p className="text-1624 text-black mt-auto">
          <Trans i18nKey="having-questions-refer-here" values={{}}>
            Having questions?
            <a
              href="https://cibahealth.com/solera/"
              className="text-linkBlue underline"
              target={'_blank'}
            >
              Refer Here
            </a>
          </Trans>
        </p>
      </div>
    </>
  );
};
export default Step3;

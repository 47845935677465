import { clsx } from 'clsx';
import { forwardRef, ChangeEvent } from 'react';

type InputFieldProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
  error?: string;
  required?: boolean;
  classNames?: {
    container?: string;
    label?: string;
    input?: string;
  };
  disabledInput?: boolean;
};
export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  ({ id, label, error, required, classNames, disabledInput, value, onChange, ...rest }, ref) => {
    const cn = {
      container: 'mb-4',
      input: 'text-1324 p-[1.2rem]',
      ...classNames,
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e);
      }
    };

    return (
      <div className={clsx('relative', cn.container)}>
        {label && (
          <label className={`block mb-4 text-1420 uppercase ${cn.label || ''}`} htmlFor={id}>
            {label} {required && <span className="text-error">*</span>}
          </label>
        )}
        <input
          ref={ref}
          id={id}
          value={value}
          onChange={handleChange}
          className={clsx('border-alto h-[4.8rem] py-[1.2rem] w-full rounded', cn.input, {
            'border-error outline-0 ring-0': error,
            'bg-slate-100': rest.disabled,
          })}
          disabled={!!disabledInput}
          {...rest}
        />
        {error && <div className="text-error text-[1.2rem]">{error}</div>}
      </div>
    );
  },
);

import { FC, useMemo, useState, forwardRef, useEffect } from 'react';
import { AutoComplete } from 'antd';
import { Option } from '../../types';
import './Autocomplete.scss';
import clsx from 'clsx';

type AutocompleteOption = {
  value: string;
};

type AutoCompleteProps = {
  label?: string;
  defaultValue?: string;
  classNames?: {
    container?: string;
    label?: string;
    input?: string;
  };
  required?: boolean;
  id?: string;
  options: Option[];
  minChars?: number;
  placeholder?: string;
  error?: string;
  onSelect: (option: Option) => void;
};
export const Autocomplete: FC<AutoCompleteProps> = forwardRef<HTMLInputElement, AutoCompleteProps>(
  (
    {
      id,
      label,
      classNames,
      defaultValue,
      options,
      onSelect,
      required,
      minChars = 3,
      placeholder,
      error,
    },
    ref,
  ) => {
    const [currentOptions, setOptions] = useState<AutocompleteOption[]>([]);
    const val = options.find((option) => option.value === defaultValue)?.label;
    const [value, setValue] = useState<string>(val || '');

    const optionsAC = useMemo(() => options.map(({ label }) => ({ value: label })), [options]);

    const onSearch = (searchText: string) => {
      setOptions(searchText.length < minChars ? [] : optionsAC);
    };

    const _onSelect = (data: string, option: AutocompleteOption) => {
      const selectedOption = options.find(({ label }) => label === option.value);
      setValue(data);
      selectedOption && onSelect(selectedOption);
    };

    const onChange = (data: string) => {
      setValue(data);
    };

    useEffect(() => {
      if (defaultValue) {
        const val = options.find((option) => option.value === defaultValue)?.label;
        setValue(val || '');
      }
    }, [options, defaultValue]);
    return (
      <div className={clsx('relative', classNames?.container)}>
        {label && (
          <label className={clsx('block pb-4 ', classNames?.label)} htmlFor={id}>
            <span className="opacity-60 text-1420 uppercase">{label}</span>{' '}
            {required && <span className="text-error">*</span>}
          </label>
        )}

        <AutoComplete
          id={id}
          style={{ width: '100%' }}
          options={currentOptions}
          value={value}
          filterOption={(inputValue, option) =>
            option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          onChange={onChange}
          onSelect={_onSelect}
          onSearch={onSearch}
          placeholder={placeholder}
        >
          <input
            ref={ref}
            className={clsx('border-alto h-[4.8rem] py-[1.2rem] w-full rounded', classNames?.input)}
            type="text"
          />
        </AutoComplete>
        {error && <div className="text-error text-1224">{error}</div>}
      </div>
    );
  },
);

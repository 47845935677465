import { useMutation } from '../../hooks/useMutation';
import { RESEND_CONFIRMATION_LINK_BY_TOKEN } from './constants';
import { $ } from '../../zeus';
import { GQLArgs } from '../../types';

type MutationResponse = Record<'resendConfirmationLinkByToken', boolean>;

export const useMutationResendConfirmationLinkByToken = (args?: GQLArgs<MutationResponse>) => {
  return useMutation(
    [RESEND_CONFIRMATION_LINK_BY_TOKEN],
    {
      resendConfirmationLinkByToken: [
        {
          token: $`token`,
        },
        true,
      ],
    },
    {
      operationName: RESEND_CONFIRMATION_LINK_BY_TOKEN,
    },
    args,
  );
};

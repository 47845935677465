/* eslint-disable */

import {
  ValueTypes,
  GraphQLTypes,
  InputType,
  Chain,
  OperationOptions,
  chainOptions,
} from './index';
import { useQuery, useMutation } from 'react-query';
import type { UseQueryOptions, UseMutationOptions } from 'react-query';
import { API_BASE_URL } from '../env';
export const getDefaultHeaders = () => ({
  'Content-Type': 'application/json',
  authorization: 'Bearer ' + localStorage.getItem('idToken'),
});

export function useTypedQuery<
  O extends 'Query',
  TData extends ValueTypes[O],
  TResult = InputType<GraphQLTypes[O], TData>,
>(
  queryKey: string | unknown[],
  query: TData | ValueTypes[O],
  options?: Omit<UseQueryOptions<TResult>, 'queryKey' | 'queryFn'>,
  zeusOptions?: OperationOptions,
  host = `${API_BASE_URL}graphql` || '',
  hostOptions: chainOptions[1] = {},
) {
  return useQuery<TResult>(
    queryKey,
    () =>
      Chain(host, { ...hostOptions, headers: { ...hostOptions?.headers, ...getDefaultHeaders() } })(
        'query',
      )(query, zeusOptions) as Promise<TResult>,
    options,
  );
}
export function useTypedLazyQuery<
  O extends 'Query',
  TData extends ValueTypes[O],
  TResult = InputType<GraphQLTypes[O], TData>,
>(
  query: TData | ValueTypes[O],
  options?: { onSuccess: (d: TResult) => void; onError: (e: any) => void },
  zeusOptions?: OperationOptions,
  host = `${API_BASE_URL}graphql` || '',
  hostOptions: chainOptions[1] = {},
) {
  return (variables: OperationOptions['variables']) =>
    (
      Chain(host, { ...hostOptions, headers: { ...hostOptions?.headers, ...getDefaultHeaders() } })(
        'query',
      )(query, { operationName: zeusOptions?.operationName, variables }) as Promise<TResult>
    )
      .then((data) => {
        options?.onSuccess?.(data);
        return data;
      })
      .catch((error) => {
        options?.onError?.(error);
        return error;
      });
}

export function useTypedMutation<
  O extends 'Mutation',
  TData extends ValueTypes[O],
  TVars extends Record<string, any>,
  TResult = InputType<GraphQLTypes[O], TData>,
  TError = Error,
>(
  mutationKey: string | unknown[],
  mutation: TData | ValueTypes[O],
  options?: Omit<UseMutationOptions<TResult, TError, TVars>, 'mutationKey' | 'mutationFn'>,
  zeusOptions?: OperationOptions,
  host = `${API_BASE_URL}graphql` || '',
  hostOptions: chainOptions[1] = {},
) {
  return useMutation<TResult, TError, TVars>(
    (variables) => {
      return Chain(host, {
        ...hostOptions,
        headers: { ...hostOptions?.headers, ...getDefaultHeaders() },
      })('mutation')(mutation, {
        ...zeusOptions,
        variables,
      }) as Promise<TResult>;
    },
    { mutationKey, ...options },
  );
}

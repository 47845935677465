// eslint-disable-next-line operator-linebreak
export const {
  REACT_APP_ENV,
  REACT_APP_API_BASE_URL,
  NODE_ENV,
  PUBLIC_URL,
  REACT_APP_USERPOOL_ID,
  REACT_APP_DISABLE_AUTH,
  REACT_APP_CLIENT_ID,
  REACT_APP_CHAT_API_BASE_URL,
  STORYBOOK_USERPOOL_ID,
  STORYBOOK_CLIENT_ID,
} = process.env;
export const API_BASE_URL = REACT_APP_API_BASE_URL;
export const CHAT_API_BASE_URL = REACT_APP_CHAT_API_BASE_URL;

export const DISABLE_AUTH = REACT_APP_DISABLE_AUTH;
export const USERPOOL_ID = REACT_APP_USERPOOL_ID || process.env.STORYBOOK_USERPOOL_ID || '';
export const CLIENT_ID = REACT_APP_CLIENT_ID || process.env.STORYBOOK_CLIENT_ID || '';

/* eslint-disable no-console */
import { FC } from 'react';
import clsx from 'clsx';
import { Autocomplete } from '../Autocomplete/Autocomplete';
import { Option } from '../../types';
import { Trans, useTranslation } from 'react-i18next';
import { Spinner } from '../Spinner/Spinner';

type CheckEligibilityProps = {
  companiesOptions: Option[];
  isLoading?: boolean;
  isDisabled?: boolean;
  onSelect: (option: Option) => void;
  onNext: () => void;
};
export const CheckEligibility: FC<CheckEligibilityProps> = ({
  companiesOptions,
  isLoading,
  isDisabled,
  onSelect,
  onNext,
}) => {
  const { t } = useTranslation();
  return (
    <div
      id="CheckEligibility"
      className={`flex h-screen flex-col mx-[2rem] lg:ml-[10rem] justify-between max-w-[52.8rem]`}
    >
      <div className="mt-[5rem] lg:mt-[50%] font-ambit">
        <h3 className="text-main text-2428 font-bold mb-[3.1rem] font-ambit">
          {t('join-page--check-eligible--page-title')}
        </h3>
        <div className="form bg-seashell p-[1.6rem] rounded font-ambit">
          <p className="opacity-60 text-1420 mb-4 font-ambit">
            {t('join-page--check-eligible--form-title')}
          </p>
          <style>
            {`
            .ant-select-selection-placeholder, .ant-select-item-option-content {
              font-family: 'Ambit';
            }`}
          </style>

          <Autocomplete
            onSelect={onSelect}
            options={companiesOptions}
            placeholder={t('enter-health-insurance-name') as string}
          />

          <button
            disabled={isDisabled}
            onClick={() => onNext()}
            className={clsx(
              'bg-main text-white w-[16.8rem] h-[4rem] rounded-[2.4rem] text-1413 mt-[1.3rem] font-ambit',
              { 'opacity-50': isDisabled && !isLoading },
            )}
          >
            {isLoading ? <Spinner /> : t('check-if-eligible')}
          </button>
        </div>
      </div>
      <section className="mb-[3rem]">
        <h4 className="text-1822 text-main font-bold font-ambit">{t('have-any-questions?')}</h4>
        <p className="text-1622 font-ambit">
          <Trans i18nKey="contact-us">
            Feel free to
            <a
              target={'_blank'}
              className="text-linkBlue underline font-ambit"
              href="https://cibahealth.com/contact-us"
            >
              Contact
            </a>
            our care team.
          </Trans>
        </p>
      </section>
    </div>
  );
};

import { useQuery } from '../../hooks/useQuery';
import { GET_MODULE_PROGRESS } from './constants';
import { $, GraphQLTypes, InputType, Selector } from '../../zeus';

type QueryModuleProgressVariables = {
  participantId?: string;
  programModuleId?: string;
};

const common = {
  __typename: true,
  startDate: true,
  endDate: true,
};

const sectionProgressSelector = Selector('ProgressDataType')({
  __typename: true,
  sectionId: true,
  sectionType: true,
  completed: true,
  metadata: {
    '...on CoachingCallProgressMetadataType': common,
    '...on CurriculumProgressMetadataType': common,
    '...on DietitianCallProgressMetadataType': common,
    '...on WeightTypeProgressMetadataType': {
      ...common,
      value: true,
    },
    '...on ActivityTypeProgressMetadataType': {
      ...common,
      value: true,
    },
    '...on VideoTypeProgressMetadataType': common,
    '...on PersonalSuccessProgressMetadataType': {
      ...common,
      responseId: true,
    },
    '...on RecipeTypeProgressMetadataType': common,
  },
});

export type SectionProgressDataType = InputType<
  GraphQLTypes['ProgressDataType'],
  typeof sectionProgressSelector
>;

const moduleProgressSelector = Selector('ModuleProgressType')({
  __typename: true,
  sections: sectionProgressSelector,
});

export type ModuleProgressDataType = InputType<
  GraphQLTypes['ModuleProgressType'],
  typeof moduleProgressSelector
>;

export const useQueryModuleProgress = (variables?: QueryModuleProgressVariables) => {
  const enabled = !!(variables && variables.participantId && variables.programModuleId);

  const { isFetching, data, refetch } = useQuery(
    [GET_MODULE_PROGRESS, JSON.stringify(variables)],
    {
      getModuleProgress: [
        {
          participantId: $`participantId`,
          programModuleId: $`programModuleId`,
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        moduleProgressSelector,
      ],
    },
    { operationName: GET_MODULE_PROGRESS, variables },
    { enabled },
  );

  const moduleProgress = (data?.getModuleProgress || {}) as ModuleProgressDataType;

  return {
    isLoading: isFetching,
    data: moduleProgress,
    moduleProgress,
    refetch,
    refetchModuleProgress: refetch,
    moduleProgressLoading: isFetching,
  };
};

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../assets/logo-white.svg';

export const InviteLayout: FC = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col lg:flex-row">
      <div className="flex flex-col justify-between lg:pl-[5.5rem] right-0 w-full lg:w-[46%] bg-participant bg-black bg-no-repeat bg-cover lg:bg-center sm:bg-[center_top_-25rem] md:bg-[center_top_-35rem]  lg:h-screen">
        <div className="mt-[2rem] lg:mt-[4.3rem]">
          <Logo className="logo w-[19rem] lg:w-fit fill-white" />
        </div>

        <h2 className="mt-[10rem] mb-[2.5rem] lg:mb-[13.5rem] lg:max-w-[65rem] text-center lg:text-start text-white text-[2.4rem] lg:text-4855 font-bold font-ambit">
          {t('invite-page--layout-text')}
        </h2>
      </div>
      <div className="w-full lg:w-[54%] lg:h-screen">{children}</div>
    </div>
  );
};

import { FC } from 'react';
import clsx from 'clsx';
import './YourProgress.scss';
import { ReactComponent as GreenCheckmark } from '../../assets/green-checkmark.svg';
import { Collapse } from '../Collapse/Collapse';
import { useScreenView } from '../../utilities/isScreenView';
import { TransformProgressDataResult, TransformModuleDataResult } from '../../types';
import { useTranslation } from 'react-i18next';
import { YourProgressSkeleton } from './YourProgress.skeleton';
import { useWaitForData } from '../../utilities/useWaitForData';
import { HealthPillar } from '../HealthPillar/HealthPillar';
import { ReactComponent as ErrorProgress } from '../../assets/no-progress.svg';

type YourProgressProps = {
  module: TransformModuleDataResult | null;
  progress: TransformProgressDataResult;
  isLoading?: boolean;
  hasCohortStarted?: boolean;
};

const List: FC<YourProgressProps & { isMobile?: boolean }> = ({
  progress,
  module,
  isMobile,
  hasCohortStarted,
}) => {
  const { t } = useTranslation();
  return (
    <ul
      className={clsx({
        'flex flex-col sm:flex-col-reverse md:flex-col py-4 mb-8': !isMobile,
        'border-b border-b-alto': isMobile,
      })}
    >
      {module?.webinar?.map((web, i) => (
        <li
          key={web?.title}
          className={clsx('flex items-center my-[1.2rem]', {
            'sm:ml-[20%]': isMobile,
          })}
        >
          {progress?.webinar?.[i]?.completed ? (
            <GreenCheckmark className="mr-[1.6rem] min-h-[2.4rem] min-w-[2.4rem]" />
          ) : (
            <div className="mr-[1.6rem] min-h-[2.4rem] min-w-[2.4rem] bg-alto opacity-40 rounded-full" />
          )}
          <div className="text-1420 text-outerSpace"> {t(web?.title)}</div>
        </li>
      ))}
      {module?.curriculum.map((curriculum, i) => (
        <li
          key={curriculum?.title}
          className={clsx('flex items-center my-[1.2rem]', {
            'sm:ml-[40%]': isMobile,
          })}
        >
          {progress?.curriculum?.some(
            (item) => item.completed && item.sectionId === curriculum?.id,
          ) ? (
            <GreenCheckmark className="mr-[1.6rem] min-h-[2.4rem] min-w-[2.4rem]" />
          ) : (
            <div className="mr-[1.6rem] min-h-[2.4rem] min-w-[2.4rem] bg-alto opacity-40 rounded-full" />
          )}
          <div className="text-1420 text-outerSpace">{t(curriculum?.title)}</div>
        </li>
      ))}
      {!hasCohortStarted &&
        module?.personalSuccess?.map((success, i) => (
          <li
            key={success?.title}
            className={clsx('flex items-center my-[1.2rem]', {
              'sm:ml-[40%]': isMobile,
            })}
          >
            {progress?.personalSuccess?.some(
              (item) => item.completed && item.sectionId === success?.id,
            ) ? (
              <GreenCheckmark className="mr-[1.6rem] min-h-[2.4rem] min-w-[2.4rem]" />
            ) : (
              <div className="mr-[1.6rem] min-h-[2.4rem] min-w-[2.4rem] bg-alto opacity-40 rounded-full" />
            )}
            <div className="text-1420 text-outerSpace">{t(success?.title)}</div>
          </li>
        ))}
      {!hasCohortStarted &&
        module?.activity?.map((activity, i) => (
          <li
            key={activity?.title}
            className={clsx('flex items-center my-[1.2rem]', {
              'sm:ml-[40%]': isMobile,
            })}
          >
            {progress?.activity?.[i]?.completed ? (
              <GreenCheckmark className="mr-[1.6rem] min-h-[2.4rem] min-w-[2.4rem]" />
            ) : (
              <div className="mr-[1.6rem] min-h-[2.4rem] min-w-[2.4rem] bg-alto opacity-40 rounded-full" />
            )}
            <div className="text-1420 text-outerSpace">{t(activity?.title)}</div>
          </li>
        ))}
      {!hasCohortStarted &&
        module?.dietitian.map((dietitian, i) => (
          <li
            key={dietitian?.title}
            className={clsx('flex items-center my-[1.2rem]', {
              'sm:ml-[60%]': isMobile,
            })}
          >
            {progress?.dietitian?.[i]?.completed ? (
              <GreenCheckmark className="mr-[1.6rem] min-h-[2.4rem] min-w-[2.4rem]" />
            ) : (
              <div className="mr-[1.6rem] min-h-[2.4rem] min-w-[2.4rem] bg-alto opacity-40 rounded-full" />
            )}
            <div className="text-1420 text-outerSpace">{t(dietitian?.title)}</div>
          </li>
        ))}
      {!hasCohortStarted &&
        module?.weight.map((weight, i) => (
          <li
            key={weight?.title}
            className={clsx('flex items-center my-[1.2rem]', {
              'sm:ml-[40%]': isMobile,
            })}
          >
            {progress?.weight?.some((item) => item.completed && item.sectionId === weight?.id) ? (
              <GreenCheckmark className="mr-[1.6rem] min-h-[2.4rem] min-w-[2.4rem]" />
            ) : (
              <div className="mr-[1.6rem] min-h-[2.4rem] min-w-[2.4rem] bg-alto opacity-40 rounded-full" />
            )}
            <div className="text-1420 text-outerSpace">{t(weight?.title)}</div>
          </li>
        ))}
      {module?.video?.map((video, i) => (
        <li
          key={video?.title}
          className={clsx('flex items-center my-[1.2rem]', {
            'sm:ml-[40%]': isMobile,
          })}
        >
          {progress?.video?.[i]?.completed ? (
            <GreenCheckmark className="mr-[1.6rem] min-h-[2.4rem] min-w-[2.4rem]" />
          ) : (
            <div className="mr-[1.6rem] min-h-[2.4rem] min-w-[2.4rem] bg-alto opacity-40 rounded-full" />
          )}
          <div className="text-1420 text-outerSpace">{t(video?.title)}</div>
        </li>
      ))}
      {module?.recipe?.map((recipe, i) => (
        <li
          key={recipe?.title}
          className={clsx('flex items-center my-[1.2rem]', {
            'sm:ml-[40%]': isMobile,
          })}
        >
          {progress?.recipe?.some((item) => item.completed && item.sectionId === recipe?.id) ? (
            <GreenCheckmark className="mr-[1.6rem] min-h-[2.4rem] min-w-[2.4rem]" />
          ) : (
            <div className="mr-[1.6rem] min-h-[2.4rem] min-w-[2.4rem] bg-alto opacity-40 rounded-full" />
          )}
          <div className="text-1420 text-outerSpace">{t(recipe?.title)}</div>
        </li>
      ))}
    </ul>
  );
};

export const YourProgress: FC<YourProgressProps> = ({
  progress,
  module,
  isLoading: loading,
  hasCohortStarted,
}) => {
  const { t } = useTranslation();
  const isMobile = useScreenView({ width: 970 });

  const isLoading = useWaitForData(
    [
      module?.webinar,
      module?.curriculum,
      module?.dietitian,
      module?.personalSuccess,
      module?.video,
    ],
    loading,
  );
  /*if (hasCohortStarted) {
    return (
      <div className="flex flex-col items-center">
        <ErrorProgress className="mt-[3rem] mb-[1rem]" />
        <p className="font-semibold text-center text-1830">No progress yet</p>
        <p className="text-center text-1324">
          Once the program has started, its progress will show up here.
        </p>
      </div>
    );
  }*/
  return (
    <div className="YourProgress">
      {isMobile && (
        <Collapse
          header={
            <div className="text-1822 text-outerSpace py-8 border-b border-b-alto">
              {t('Your progress')}
            </div>
          }
        >
          {isLoading && <YourProgressSkeleton />}
          {!isLoading && <List module={module} progress={progress} isMobile />}
          <HealthPillar isLoading={isLoading} />
        </Collapse>
      )}
      {!isMobile && (
        <>
          {isLoading && <YourProgressSkeleton />}
          {!isLoading && (
            <>
              <div className="text-1822 text-outerSpace mt-6 mb-8">{t('Your progress')}</div>
              <List module={module} progress={progress} hasCohortStarted={hasCohortStarted} />
            </>
          )}
          <HealthPillar isLoading={isLoading} />
        </>
      )}
    </div>
  );
};
